import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  DataList,
  initialErrorState,
  saveErrorInState,
  dismissError as commonDismissError
} from '../common'
import {
  fetchGroups,
  createGroup,
  modifyGroup,
  disableGroups,
  addUsersToGroup
} from './groupAPI'

const initialState = _.merge({
  sort: {
    column: 'id',
    order: 'desc'
  },
  selectedIds: [],
  openedModal: null
}, DataList.initialState, initialErrorState)

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    selectColumn: DataList.selectColumn,
    selectPage: DataList.selectPage,
    selectId: (state, action) => {
      state.selectedIds = Array.from(new Set(state.selectedIds).add(action.payload))
    },
    unselectId: (state, action) => {
      const set = new Set(state.selectedIds)
      set.delete(action.payload)
      state.selectedIds = Array.from(set)
    },
    openModal: (state, action) => {
      state.openedModal = action.payload
    },
    closeModal: (state) => {
      state.openedModal = null
    },
    dismissError: commonDismissError
  },
  extraReducers: builder => builder
    .addCase(fetchGroups.pending, DataList.fetchPending)
    .addCase(fetchGroups.fulfilled, DataList.fetchFulfilled)
    .addCase(fetchGroups.rejected, saveErrorInState(DataList.fetchRejected))
    .addCase(createGroup.pending, state => {
      state.fetching = true
    })
    .addCase(createGroup.fulfilled, state => {
      state.fetching = false
    })
    .addCase(createGroup.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(modifyGroup.pending, state => {
      state.fetching = true
    })
    .addCase(modifyGroup.fulfilled, state => {
      state.fetching = false
    })
    .addCase(modifyGroup.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(disableGroups.pending, state => {
      state.fetching = true
    })
    .addCase(disableGroups.fulfilled, state => {
      state.fetching = false
      state.selectedIds = []
    })
    .addCase(disableGroups.rejected, saveErrorInState(state => {
      state.fetching = false
      state.selectedIds = []
    }))
    .addCase(addUsersToGroup.pending, state => {
      state.fetching = true
    })
    .addCase(addUsersToGroup.fulfilled, state => {
      state.fetching = false
    })
    .addCase(addUsersToGroup.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
})

export const selectGroup = state => state.group
export const selectGroupErrors = state => state.group.errors

export const {
  selectColumn,
  selectPage,
  selectId,
  unselectId,
  openModal,
  closeModal,
  dismissError
} = groupSlice.actions
