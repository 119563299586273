import React from 'react'
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Alert
} from 'react-bootstrap'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

export const PageCopyForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  // onSubmit,
  fetching,
  title,
  submitButtonText,
  onClickCancel,
  page,
  alert
}) => (
  <>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    {alert?.show && alert?.alertBody &&
      <Alert
        className='copy-page-error-alert'
        variant='danger'
      >
        {alert.alertBody}
      </Alert>}
    <Form noValidate onSubmit={handleSubmit}>
      <Modal.Body>
        <p>以下の紙面を複製します。よろしいですか？</p>
        <p>コピー元紙面名称：{values.name}</p>
        <Row>
          <Form.Group as={Col}>
            <Form.Label column>コピー後紙面ドラフト数</Form.Label>
            <Form.Control
              id='draftNumberInput'
              name='draftNumber'
              type='number'
              value={values.draftNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.draftNumber && errors.draftNumber}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.draftNumber}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClickCancel}>キャンセル</Button>
        <Button type='submit' disabled={fetching}>{submitButtonText} </Button>
      </Modal.Footer>
    </Form>
  </>
)

PageCopyForm.schema = Yup.object().shape({
  name: Yup.string().required(),
  draftNumber: Yup.number().required().min(1)
})
