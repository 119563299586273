import React from 'react'
import {
  Form,
  Row,
  Col,
  Modal,
  Button
} from 'react-bootstrap'
import * as Yup from 'yup'
import {
  isAreaArticleType, isSizedArticleType,
  nullishValueToValue,
  toPosDisplayValue
} from '../article'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

const AreaArticleAreaInput = ({
  values,
  layoutable
}) => (
  <Row>
    <Form.Group as={Col} sm={3}>
      <Form.Label>天から</Form.Label>
      <Col>
        {nullishValueToValue(values.article?.areas?.[0]?.y, 'ナリ')}
        段目
      </Col>
    </Form.Group>
    <Form.Group as={Col} sm={3}>
      <Form.Label as={Col}>{!values.article?.areas?.[0]?.fromLeft ? '右' : '左'}から</Form.Label>
      <Col>
        {toPosDisplayValue(layoutable, values?.article?.areas?.[0]) || 'ナリ'}
        行目
      </Col>
    </Form.Group>
    <Form.Group as={Col} sm={3}>
      <Form.Label>天地</Form.Label>
      <Col>
        {values.article?.areas?.[0]?.height}
        段
      </Col>
    </Form.Group>
    <Form.Group as={Col} sm={3}>
      <Form.Label as={Col}>左右</Form.Label>
      <Col>
        {values.article?.areas?.[0]?.width}
        行幅
      </Col>
    </Form.Group>
  </Row>
)

export const ArticleViewForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isValid,
  setFieldValue,
  enumValues,
  onClickCancel,
  title,
  layoutable
}) => {
  if (!values) { return }
  const formProps = { values, errors, touched, handleChange, handleBlur, isValid, setFieldValue, layoutable }
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='mb-3'>
          <Form.Group as={Col}>
            <Form.Label column>記事種別</Form.Label>
            <Col>
              {enumValues.articleTypes[values.articleType]}
            </Col>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label column>記事優先度</Form.Label>
            <Col>
              {enumValues.priorities[values.priority]}
            </Col>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label column>中段線有無</Form.Label>
            <Col>
              中段線{values.columnSpaceExistence ? 'あり' : 'なし'}
            </Col>
          </Form.Group>
          {isAreaArticleType(values.articleType) &&
            <Form.Group as={Col}>
              <Form.Label column>仕切り罫</Form.Label>
              <Col>
                {enumValues.dividerLineModes[values.dividerLineMode]}
              </Col>
            </Form.Group>}
        </Row>
        {isSizedArticleType(values.articleType) &&
          <Row>
            <Form.Group as={Col}>
              <Form.Label column>記事開始位置指定</Form.Label>
              <Row>
                <Col sm={4}>
                  天から
                  {nullishValueToValue(values.article?.areas?.[0]?.y, 'ナリ')}
                  段目
                </Col>
                <Col sm={4}>
                  右から
                  {nullishValueToValue(values.article?.areas?.[0]?.x, 'ナリ')}
                  行目
                </Col>
              </Row>
            </Form.Group>
          </Row>}
        {isSizedArticleType(values.articleType) &&
          <Row>
            <Form.Group as={Col}>
              <Form.Label column>記事サイズ指定</Form.Label>
              <Row>
                <Col sm={4}>
                  高さ
                  {values.article?.areas?.[0]?.height || 'ナリ'}
                  段
                </Col>
                <Col sm={4}>
                  横幅
                  {values.article?.areas?.[0]?.width || 'ナリ'}
                  行
                </Col>
                <Col sm={4}>
                  行数
                  {values.rows || 'ナリ'}
                  行
                </Col>
              </Row>
            </Form.Group>
          </Row>}
        {(values.articleType !== 'area') &&
          <Row className='mb-3'>
            <Form.Group as={Col}>
              <Form.Label column>記事ファイル</Form.Label>
              <Col>
                {values.filename}
              </Col>
            </Form.Group>
          </Row>}
        {isAreaArticleType(values.articleType) &&
          <>
            {values.articleType === 'area' &&
              <>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>名称</Form.Label>
                    <Col>
                      {values.name}
                    </Col>
                  </Form.Group>
                </Row>
                <AreaArticleAreaInput {...formProps} />
              </>}
          </>}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClickCancel}>
          確認
        </Button>
      </Modal.Footer>
    </>
  )
}
