import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { logout } from './authAPI'
import {
  initialErrorState,
  saveErrorInState,
  dismissError as commonDismissError
} from '../common'

const initialState = _.merge(
  {
    fetching: false
  },
  initialErrorState
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    dismissError: commonDismissError
  },
  extraReducers: builder => {
    builder
      .addCase(logout.pending, (state, action) => {
        state.fetching = true
      })
      .addCase(logout.fulfilled, (state, action) => {
        const { redirectUri } = action.payload
        state.fetching = false
        state.currentUser = null
        state.groupType = null
        state.redirectUri = redirectUri
      })
      .addCase(logout.rejected, saveErrorInState(state => {
        state.fetching = false
      }))
  }
})

export const selectAuth = state => state.auth

export const {
  dismissError
} = authSlice.actions
