export const getColumnString = (columns) => {
  if (!columns) {
    return null
  }
  const intColumns = parseInt(columns)
  const halfString = columns.endsWith('.5') ? '半' : ''
  return `${intColumns}段${halfString}`
}

export const getAreaStrings = (area) => {
  const columnString = getColumnString(area.height)
  const rowString = `${parseInt(area.width)}行幅`
  const areaYString = area.y !== null ? `${parseInt(area.y) + 1}段目` : ''
  const areaXString = area.x !== null ? `${parseInt(area.x) + 1}行目` : ''
  return { areaYString, areaXString, columnString, rowString }
}
