import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import {
  csrfToken,
  throwErrorForErrorResponse,
  errorHandler
} from '../common'
import { closeModal } from './homeSlice'

export const fetchMessage = createAsyncThunk(
  'home/fetchMessage',
  async (_, { getState, rejectWithValue }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(throwErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
  }
)

export const createPage = createAsyncThunk(
  'home/createPage',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { navigate, formData } = params
    delete params.navigate
    return fetch(
      `${baseUri}pages`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: formData
      })
      .then(throwErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .then(payload => {
        const { pageId } = payload
        dispatch(closeModal())
        navigate(`${baseUri}pages/${pageId}/articles`)
        return Promise.resolve({})
      })
      .catch(errorHandler(rejectWithValue))
  }
)

export const createSubLayout = createAsyncThunk(
  'home/createSubLayout',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { navigate, formData } = params
    delete params.navigate
    return fetch(
      `${baseUri}sub_layouts`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: formData
      })
      .then(throwErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .then(payload => {
        const { subLayoutId } = payload
        dispatch(closeModal())
        navigate(`${baseUri}sub_layouts/${subLayoutId}/articles`)
        return Promise.resolve({})
      })
      .catch(errorHandler(rejectWithValue))
  }
)
