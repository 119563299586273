import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import {
  createFetchAsyncThunk,
  csrfToken,
  toSnake,
  throwErrorForErrorResponse,
  throwTextErrorForErrorResponse,
  errorHandler
} from '../common'
import { closeModal } from './mediaSlice'

export const fetchMedia = createFetchAsyncThunk(
  'media/fetchMedia',
  'media'
)

export const fetchMediaEngineStats = createAsyncThunk(
  'media/fetchMediaEngineStats',
  async (params, { getState, rejectWithValue }) => {
    const { baseUri } = getState().common
    const { mediaId } = params

    return fetch(
      `${baseUri}media/${mediaId}/engine_stats`, {
        headers: {
          Accept: 'application/json'
        }
      })
      .then(throwTextErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
  }
)

export const createMedia = createAsyncThunk(
  'media/createMedia',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common

    return fetch(
      `${baseUri}media`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().media
        dispatch(fetchMedia({ page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue)).finally(() => {
        dispatch(closeModal())
      })
  }
)

export const modifyMedia = createAsyncThunk(
  'media/modifyMedia',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { id } = params

    return fetch(
      `${baseUri}media/${id}`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().media
        dispatch(fetchMedia({ page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue)).finally(() => {
        dispatch(closeModal())
      })
  }
)

export const deleteMedia = createAsyncThunk(
  'media/deleteMedia',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { id } = params

    return fetch(
      `${baseUri}media/${id}`, {
        method: 'delete',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().media
        dispatch(fetchMedia({ page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue)).finally(() => {
        dispatch(closeModal())
      })
  }
)
