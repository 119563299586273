import { createSlice } from '@reduxjs/toolkit'
// import _ from 'lodash'
import {
  DataList,
  initialErrorState,
  saveErrorInState,
  dismissError as commonDismissError,
  closeAlert as commonCloseAlert,
  initialAlertState,
  setAlertState,
  setErrorAlert
} from '../common'
import {
  fetchArticles,
  fetchLayoutableStatus,
  bulkCreateArticles,
  createArticle,
  deleteFigure,
  modifyArticle,
  modifyPage,
  modifyHeading,
  modifyTemplateArticle,
  createFigure,
  modifyFigure,
  modifyArea,
  deleteArticles,
  startLayout,
  switchArticleOrders
} from './articleAPI'

const initialState = {
  ...{
    sort: {
      column: 'id',
      order: 'desc'
    },
    selectedIds: [],
    selectedCell: null,
    selectedArticle: null,
    layoutableType: null,
    layoutable: null,
    openedModal: null
  },
  ...DataList.initialState,
  ...initialErrorState,
  ...initialAlertState
}

export const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    selectColumn: DataList.selectColumn,
    selectPage: DataList.selectPage,
    selectId: (state, action) => {
      state.selectedIds = Array.from(new Set(state.selectedIds).add(action.payload))
    },
    unselectId: (state, action) => {
      const set = new Set(state.selectedIds)
      set.delete(action.payload)
      state.selectedIds = Array.from(set)
    },
    selectCell: (state, action) => {
      state.selectedCell = action.payload
      if (!state.selectedCell || !state.layoutable) {
        state.selectedIds = []
        return
      }
      const { articleId } = state.selectedCell
      state.selectedIds = [articleId]
    },
    selectTargetArticle: (state, action) => {
      state.selectedArticle = action.payload
      if (!state.selectedArticle || !state.layoutable) {
        state.selectedIds = []
        return
      }
      const { id: articleId } = state.selectedArticle
      state.selectedIds = [articleId]
    },
    openModal: (state, action) => {
      state.openedModal = action.payload
    },
    closeModal: (state) => {
      state.openedModal = null
    },
    closeAlert: commonCloseAlert,
    dismissError: commonDismissError
  },
  extraReducers: builder => builder
    .addCase(fetchArticles.pending, DataList.fetchPending)
    .addCase(fetchArticles.fulfilled, (state, action) => {
      const { layoutableType, layoutable } = action.payload
      state.layoutableType = layoutableType
      state.layoutable = layoutable
      DataList.fetchFulfilled(state, action)
    })
    .addCase(fetchArticles.rejected, saveErrorInState(DataList.fetchRejected))
    .addCase(fetchLayoutableStatus.pending, state => {
      state.fetching = true
    })
    .addCase(fetchLayoutableStatus.fulfilled, (state, action) => {
      state.fetching = false
      const { status } = action.payload
      if (state.layoutable) {
        state.layoutable = {
          ...state.layoutable,
          status
        }
      }
    })
    .addCase(fetchLayoutableStatus.rejected, state => {
      state.fetching = false
    })
    .addCase(createArticle.pending, state => {
      state.fetching = true
    })
    .addCase(createArticle.fulfilled, state => {
      state.fetching = false
    })
    .addCase(createArticle.rejected, setErrorAlert(state => {
      state.fetching = false
    }))
    .addCase(bulkCreateArticles.pending, state => {
      state.fetching = true
    })
    .addCase(bulkCreateArticles.fulfilled, state => {
      state.fetching = false
    })
    .addCase(bulkCreateArticles.rejected, setErrorAlert(state => {
      state.fetching = false
    }))
    .addCase(modifyArticle.pending, state => {
      state.fetching = true
    })
    .addCase(modifyArticle.fulfilled, setAlertState(state => {
      state.fetching = false
    }))
    .addCase(modifyArticle.rejected, setErrorAlert(state => {
      state.fetching = false
    }))
    .addCase(deleteArticles.pending, state => {
      state.fetching = true
    })
    .addCase(deleteArticles.fulfilled, state => {
      state.fetching = false
    })
    .addCase(deleteArticles.rejected, setErrorAlert(state => {
      state.fetching = false
    }))
    .addCase(switchArticleOrders.pending, state => {
      state.fetching = true
    })
    .addCase(switchArticleOrders.fulfilled, state => {
      state.fetching = false
    })
    .addCase(switchArticleOrders.rejected, setErrorAlert(state => {
      state.fetching = false
    }))
    .addCase(modifyPage.pending, state => {
      state.fetching = true
    })
    .addCase(modifyPage.fulfilled, setAlertState(state => {
      state.fetching = false
    }))
    .addCase(modifyPage.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(createFigure.pending, state => {
      state.fetching = true
    })
    .addCase(createFigure.fulfilled, state => {
      state.fetching = false
    })
    .addCase(createFigure.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(modifyFigure.pending, state => {
      state.fetching = true
    })
    .addCase(modifyFigure.fulfilled, setAlertState(state => {
      state.fetching = false
    }))
    .addCase(modifyFigure.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(deleteFigure.pending, state => {
      state.fetching = true
    })
    .addCase(deleteFigure.fulfilled, setAlertState(state => {
      state.fetching = true
    }))
    .addCase(deleteFigure.rejected, saveErrorInState(state => {
      state.fetching = true
    }))
    .addCase(modifyArea.pending, state => {
      state.fetching = true
    })
    .addCase(modifyArea.fulfilled, setAlertState(state => {
      state.fetching = false
    }))
    .addCase(modifyArea.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(modifyHeading.pending, state => {
      state.fetching = true
    })
    .addCase(modifyHeading.fulfilled, setAlertState(state => {
      state.fetching = false
    }))
    .addCase(modifyHeading.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(modifyTemplateArticle.pending, state => {
      state.fetching = true
    })
    .addCase(modifyTemplateArticle.fulfilled, setAlertState(state => {
      state.fetching = false
    }))
    .addCase(modifyTemplateArticle.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(startLayout.pending, state => {
      state.fetching = true
    })
    .addCase(startLayout.fulfilled, (state, action) => {
      state.fetching = false
      const { status } = action.payload
      if (state.layoutable) {
        state.layoutable = {
          ...state.layoutable,
          status
        }
      }
      commonCloseAlert(state)
    })
    .addCase(startLayout.rejected, setErrorAlert(state => {
      state.fetching = false
    }))
})

export const selectArticle = state => state.article

export const {
  selectColumn,
  selectPage,
  selectId,
  selectCell,
  selectTargetArticle,
  unselectId,
  openModal,
  closeModal,
  closeAlert,
  dismissError
} = articleSlice.actions
