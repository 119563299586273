import React, { useEffect } from 'react'
import {
  Form,
  Row,
  Col,
  Modal,
  Button
} from 'react-bootstrap'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
import { toSnake } from '../common'
import { readAllFiles } from './ArticleParse'
Yup.setLocale(ja.suggestive)

export const ArticleImagesAddForm = ({
  layoutable,
  values,
  errors,
  touched,
  // handleChange,
  handleSubmit,
  isValid,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  onClickCancel,
  title,
  existingFiles,
  holderArticle,
  submitButtonText,
  layoutableLockVersion
}) => {
  if (!values) { return }
  const acceptedTypes = [
    'image/png',
    'image/gif',
    'image/jpeg',
    'application/postscript', // .eps
    'image/vnd.adobe.photoshop' // .psd
  ]
  // const [acceptedImageList, setAcceptedImageList] = useState([])
  // const [rejectedImageList, setRejectedImageList] = useState([])

  const handleFileChange = async (e) => {
    const files = e.target.files

    // console.log('changed called', {files, values, errors})
    if (!files) return

    const fileList = Array.from(e.target.files)
    // console.log('files', { files, fileList })
    const rejectedFiles = fileList?.filter(file => {
      const filename = file.name.normalize('NFC')
      return ((existingFiles?.length > 0 && existingFiles.includes(filename)) ||
              !acceptedTypes.includes(file.type))
    })
    const rejectedNames = rejectedFiles?.map(f => f.name.normalize('NFC'))
    const rejectedNameSet = new Set(rejectedNames || [])
    const acceptedFiles = fileList?.filter(file => {
      const filename = file.name.normalize('NFC')
      return !rejectedNameSet.has(filename)
    })
    const acceptedNames = acceptedFiles?.map(f => f.name.normalize('NFC'))

    setFieldValue('acceptedImageList', (acceptedNames || []))
    setFieldValue('rejectedImageList', (rejectedNames || []))

    // console.log('accepted and rejected', { existingFiles, rejectedFiles, rejectedNames, acceptedFiles })

    if (!acceptedFiles || acceptedFiles?.length === 0) {
      setFieldValue('formData', undefined, false)
      setFieldValue('images', undefined, false)
      setFieldTouched('images', true, false)
      // setFieldError('images', 'アップロード対象のファイルがありませんでした。')
      return
    }
    // console.log('found accepted files')
    // setFieldValue('images', acceptedFiles, false)
    const { files: loadedFiles, articles } = await readAllFiles({ layoutable, holderArticle, allFiles: acceptedFiles })
    const formData = new window.FormData()
    formData.append('articles', JSON.stringify(toSnake(articles)))
    formData.append('lock_version', layoutableLockVersion)
    for (const { name, blob } of loadedFiles) {
      formData.append('files[]', blob, name)
    }
    setFieldValue('formData', formData)
  }

  useEffect(() => {
    // console.log('changed', {values, errors, isValidating, status})
    if (values.acceptedImageList.length === 0) {
      setFieldError('images', 'アップロード対象のファイルがありませんでした。')
    }
  }, [values, errors])

  const acceptedItems = values.acceptedImageList?.map((file, idx) => (
    <li key={`file-${idx}`}>
      {file}
    </li>
  ))
  const rejectedItems = values.rejectedImageList?.map((file, idx) => {
    const isExistingFile = (existingFiles?.length > 0 && existingFiles.includes(file))
    return (
      <li key={`file-${idx}`}>
        {file}
        {isExistingFile &&
          <ul><li className='text-danger'>このファイルはすでにアップロード済みです。</li></ul>}
        {!isExistingFile &&
          <ul><li className='text-danger'>このファイルはサポートされている画像タイプではありません。</li></ul>}
      </li>
    )
  })
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Row className='mb-3'>
            <Form.Group as={Col}>
              <Form.Label column>画像ファイル</Form.Label>
              <Col>
                <Form.Control
                  id='images'
                  name='images'
                  type='file'
                  accept={acceptedTypes}
                  multiple
                  value={values.images}
                  onChange={handleFileChange}
                  onBlur={e => e.preventDefault()}
                  isInvalid={touched.images && errors.images}
                />
                <Form.Control.Feedback type='invalid' className='invalid-images'>
                  {errors.images}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Row>
          {(values.acceptedImageList.length > 0 || values.rejectedImageList.length > 0) &&
            <Row>
              <Col>
                <aside>
                  {values.acceptedImageList.length > 0 &&
                    <>
                      <div className='h6 text-success'>アップロード対象</div>
                      <ul>{acceptedItems}</ul>
                    </>}
                  {values.rejectedImageList.length > 0 &&
                    <>
                      <div className='h6 text-danger'>アップロード対象外</div>
                      <ul>{rejectedItems}</ul>
                    </>}
                </aside>
              </Col>
            </Row>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={!values.formData || !isValid}>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}
ArticleImagesAddForm.schema = Yup.object().shape({
  images: Yup.string()
})
