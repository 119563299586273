/* eslint-env browser */
import React from 'react'
import { useSelector } from 'react-redux'
import {
  Accordion,
  ListGroup
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  selectCommon
} from '../common'

const ObjectTreeArticleItem = (props) => {
  const { enumValues } = useSelector(selectCommon)

  const h6Style = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    fontSize: '1.0rem',
    fontWeight: 'bold'
  }

  const itemStyle = (isHovered, isSelected) => {
    return {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
      fontSize: '0.9rem',
      ...(isHovered) && {
        border: 'solid 1px'
      },
      ...(!isHovered) && {
        textDecoration: 'underline'
      },
      ...(isSelected) && {
        backgroundColor: '#fbb'
      }
    }
  }

  const createSelector = (category, index = undefined) => {
    return index !== undefined
      ? `.${props.layout.id}.${category}.index${index}`
      : `.${props.layout.id}.${category}`
  }
  const layoutLockCaution = props.article.layoutLocked ? <><FontAwesomeIcon icon='lock' /> </> : undefined
  const missLayoutCaution = props.layout.areas.length === 0
    ? <><FontAwesomeIcon icon='triangle-exclamation' size='xl' /> </>
    : undefined

  const typeString = enumValues.articleTypes[props.article.articleType]

  const title = props.article.headings.length > 0
    ? props.article.headings[0].content.replace(/(\n)/gm, '')
    : `${typeString} - ${props.article.filename || props.article.name || props.article.id}`

  const headings = props.layout.headingAreas.length > 0
    ? (
      <>
        <h6 style={h6Style}>見出し</h6>
        <ListGroup>
          {
            props.layout.headingAreas.map((headingArea, i) => {
              const headingTypeNames = { ...enumValues.headingTypes, heading: '通常見出し' }
              const selector = createSelector('heading', i)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              const headingType = props.article.headings.find(ah => ah.id === headingArea?.id)?.headingType ?? 'heading'
              const title = headingTypeNames[headingType] ?? '不明な見出し'
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  {title}
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  const preamble = ((props.article.preambleContent !== null) && props.layout.areas.length > 0)
    ? (
      <>
        <h6 style={h6Style}>前文</h6>
        <ListGroup>
          {
            props.layout.areas.slice(0, 1).map((_, i) => {
              const selector = createSelector('preamble', undefined)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  前文エリア
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  const textStart = preamble !== undefined ? 1 : 0
  const textEnd = textStart + 1

  const segments = props.layout.areas.length > 0
    ? (
      <>
        <h6 style={h6Style}>本文</h6>
        <ListGroup>
          {
            props.layout.areas.slice(textStart, textEnd).map((_, i) => {
              const category = props.article.articleType
              const selector = createSelector(category, undefined)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  本文エリア
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  const figures = ((props.article.figures.length > 0) && (props.layout.figures.length > 0))
    ? (
      <>
        <h6 style={h6Style}>図表</h6>
        <ListGroup>
          {
            props.article.figures.map((figure, i) => {
              const selector = createSelector('figure', i)
              const isHovered = selector === props.hoveredElementsSelector
              const isSelected = selector === props.selectedElementsSelector
              return (
                <ListGroup.Item
                  key={i} style={itemStyle(isHovered, isSelected)}
                  selector={selector}
                  onClick={props.onClickOfItems}
                  onMouseEnter={props.onMouseEnterOfItems}
                  onMouseLeave={props.onMouseLeaveOfItems}
                >
                  図表エリア {i + 1}
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </>
      )
    : undefined

  return (
    <Accordion>
      <Accordion.Item eventKey='{key}'>
        <Accordion.Header>{layoutLockCaution}{missLayoutCaution}{title}</Accordion.Header>
        <Accordion.Body>
          {headings}
          {preamble}
          {segments}
          {figures}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export const ObjectTree = (props) => {
  return (
    <>
      <div>
        {
          props.articles.map((article, i) => {
            return (
              <ObjectTreeArticleItem
                article={article.article}
                layout={article.layout}
                key={i}
                selectedElementsSelector={props.selectedElementsSelector}
                hoveredElementsSelector={props.hoveredElementsSelector}
                onClickOfItems={props.onClickOfItems}
                onMouseEnterOfItems={props.onMouseEnterOfItems}
                onMouseLeaveOfItems={props.onMouseLeaveOfItems}
              />
            )
          })
        }
      </div>
    </>
  )
}
