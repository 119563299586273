import React from 'react'
import {
  Modal,
  Button
} from 'react-bootstrap'

export const StartLayoutForm = ({
  onSubmit,
  onClickCancel,
  layoutableName,
  fetching
}) => (
  <>
    <Modal.Header>
      <Modal.Title>割り付け実行</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {layoutableName}の割り付けを実行しますか?
    </Modal.Body>
    <Modal.Footer>
      <Button variant='secondary' onClick={onClickCancel}>
        キャンセル
      </Button>
      <Button
        type='submit'
        disabled={fetching}
        onClick={onSubmit}
      >実行する
      </Button>
    </Modal.Footer>
  </>
)
