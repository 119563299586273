import React from 'react'
import {
  Alert,
  Form,
  Modal,
  Button
} from 'react-bootstrap'

export const ConfirmationForm = ({
  handleSubmit,
  onClickCancel,
  disabled,
  title,
  ConfirmationBody,
  submitButtonText,
  ...props
}) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Alert variant='danger'>
            <ConfirmationBody {...props} />
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={disabled}>
            {submitButtonText}
          </Button>
        </Modal.Footer>
      </Form>
    </>
  )
}
