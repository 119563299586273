import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import {
  createFetchAsyncThunk,
  csrfToken,
  toSnake,
  throwErrorForErrorResponse,
  errorHandler
} from '../common'
import { closeModal } from './userSlice'

export const fetchUsers = createFetchAsyncThunk(
  'user/fetchUsers',
  'users'
)

export const modifyUsers = createAsyncThunk(
  'user/modifyUsers',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common

    return fetch(
      `${baseUri}users`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(async () => {
        const { currentPage, sort, searchCondition } = getState().user
        dispatch(fetchUsers({ page: currentPage, sort, search: searchCondition }))
        dispatch(closeModal())
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(
        errorHandler(rejectWithValue)
      ).finally(() => {
        dispatch(closeModal())
      })
  }
)

export const modifyUser = createAsyncThunk(
  'user/modifyUser',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    console.log('modifyUser', { params })
    const { user: { id } } = params

    return fetch(
      `${baseUri}users/${id}`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(async () => {
        const { currentPage, sort, searchCondition } = getState().user
        dispatch(fetchUsers({ page: currentPage, sort, search: searchCondition }))
        dispatch(closeModal())
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => {
        dispatch(closeModal())
      })
  }
)
