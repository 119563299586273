import { snakeCase } from 'lodash'
import createHumps from 'lodash-humps/lib/createHumps'

export const csrfToken = () => {
  const csrfTokenMetaElement = document.getElementsByName(
    'csrf-token'
  )[0]
  // In Rails testing environment, CSRF verification is disabled, and no
  // csrf-token meta element exists in an HTML document.Therefore, we need to
  // check if csrfTokenMetaElement is truty below.
  return csrfTokenMetaElement ? csrfTokenMetaElement.content : ''
}

export const toSnake = createHumps(snakeCase)
