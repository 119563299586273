import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavDropdown } from 'react-bootstrap'
import { ErrorModal } from '../common'
import {
  selectAuth,
  dismissError
} from './authSlice'
import { logout } from './authAPI'

export const AuthDropdown = () => {
  const {
    currentUser,
    redirectUri,
    errors
  } = useSelector(selectAuth)
  if (!currentUser) {
    window.location.href = redirectUri
    return <></>
  }

  const dispatch = useDispatch()
  const handleClickLogout = () => {
    dispatch(logout())
  }
  return (
    <>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <NavDropdown title={currentUser.name}>
        <NavDropdown.Item onClick={handleClickLogout}>ログアウト</NavDropdown.Item>
      </NavDropdown>
    </>
  )
}
