import React from 'react'
import {
  Modal,
  Form,
  Button,
  ButtonGroup,
  Row,
  Col,
  Container
} from 'react-bootstrap'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldArray } from 'formik'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

const allowedUserRoles = new Set(['operator', 'supervisor', 'group_administrator'])

export const AddUserForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  onClickCancel,
  isValid,
  fetching,
  enumValues
}) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>ユーザー追加</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <FieldArray name='users'>
            {({ push, remove }) => (
              <>
                {_.map(values.users, ({
                  name,
                  email,
                  role
                }, i) => (
                  <div key={i}>
                    <Form.Group as={Row} className='mb-3'>
                      <Form.Label column className='col-2'>名前</Form.Label>
                      <Col>
                        <Form.Control
                          type='text'
                          name={`users.${i}.name`}
                          value={values.users[i] && values.users[i].name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.users && touched.users[i] && touched.users[i].name && errors.users && errors.users[i] && errors.users[i].name}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.users && errors.users[i] && errors.users[i].name}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='mb-3'>
                      <Form.Label column className='col-2'>メールアドレス</Form.Label>
                      <Col>
                        <Form.Control
                          type='text'
                          name={`users.${i}.email`}
                          value={values.users[i] && values.users[i].email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.users && touched.users[i] && touched.users[i].email && errors.users && errors.users[i] && errors.users[i].email}
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.users && errors.users[i] && errors.users[i].email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='mb-3'>
                      <Form.Label column className='col-2'>ロール</Form.Label>
                      <Col>
                        <Form.Control
                          as='select'
                          name={`users.${i}.role`}
                          value={values.users[i] && values.users[i].role}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.users && touched.users[i] && touched.users[i].role && errors.users && errors.users[i] && errors.users[i].role}
                        >
                          {Object.keys(enumValues.userRoles).filter(k => allowedUserRoles.has(_.snakeCase(k)))
                            .map(k => (<option key={k} value={_.snakeCase(k)}>{enumValues.userRoles[k]}</option>))}
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          {errors.users && errors.users[i] && errors.users[i].role}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </div>
                ))}
                <Container className='d-flex justify-content-end'>
                  <ButtonGroup>
                    <Button
                      size='sm'
                      onClick={() => {
                        push({
                          name: '',
                          email: '',
                          role: 'operator'
                        })
                      }}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon='plus'
                      />
                    </Button>
                    <Button
                      size='sm'
                      onClick={() => {
                        remove(values.users.length - 1)
                      }}
                      disabled={values.users.length < 2}
                    >
                      <FontAwesomeIcon
                        fixedWidth
                        icon='minus'
                      />
                    </Button>
                  </ButtonGroup>
                </Container>
              </>
            )}
          </FieldArray>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={!isValid || fetching}>追加する</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}

AddUserForm.schema = Yup.object().shape({
  users: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
      role: Yup.string().oneOf(allowedUserRoles)
    })
  ).required().min(1)
})
