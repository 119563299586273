export const initialAlertState = { alert: { show: false, title: null, alertBody: null } }

export const setAlertState = reducer => (state, action) => {
  const { alert } = action.payload
  if (alert !== undefined) {
    state.alert = alert
    state.alert.show = true
    reducer(state, action)
  }
}

export const setErrorAlert = reducer => (state, action) => {
  const error = action.payload
  if (error !== undefined) {
    state.alert = {
      show: true,
      variant: 'danger',
      alertBody: error.message
    }
  }
  reducer(state, action)
}

export const closeAlert = (state, action) => {
  state.alert.show = false
}
