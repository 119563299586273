import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'

export const OverlayLink = ({
  tooltipText,
  keyName,
  placement,
  icon,
  disabled,
  onClick
}) => {
  if (disabled) {
    return (
      <span style={{ opacity: 0.6, cursor: 'not-allowed' }}>
        <FontAwesomeIcon icon={icon} fixedWidth />
      </span>
    )
  }
  const overlayPlacement = placement || 'left'
  return (
    <OverlayTrigger
      key={keyName}
      id={keyName}
      placement={overlayPlacement}
      overlay={
        <Tooltip id={`${keyName}-tooltip`}>
          {tooltipText}
        </Tooltip>
      }
    >
      <a
        name={`${keyName}-link`}
        href='#'
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} fixedWidth />
      </a>
    </OverlayTrigger>
  )
}
