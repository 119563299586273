import React, { useEffect } from 'react'
import {
  Button,
  Tab,
  Nav,
  Row,
  Col
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import {
  showSetting,
  selectSetting,
  createSettingDetail,
  modifySettingDetail,
  deleteSettingDetail
} from '../media'
import {
  ButtonModals,
  CustomForm,
  ErrorModal,
  selectCommon
} from '../common'
import { selectAuth, shouldShowExperimentalFeatures } from '../auth'
import {
  openModal,
  closeModal,
  dismissError,
  selectId,
  unselectId,
  clearSelectedIds,
  selectTab
} from './settingSlice'
import {
  exportSetting
} from './settingAPI'
import {
  ColumnSettingForm,
  MarginOutputSettingForm,
  VerticalHeadingMarginSettingForm,
  CoveringMarginSettingForm,
  HorizontalHeadingMarginSettingForm,
  ParagraphStyleSettingForm,
  CharacterStyleSettingForm,
  CharacterStyleSettingUpsertForm,
  CharacterStyleSettingDeleteForm,
  HeadingObjectStyleSettingForm,
  PreambleCharacterSettingForm,
  PreambleRowMarginSettingForm,
  FigureRowMarginSettingForm,
  LineRowMarginSettingForm,
  PageMarginSettingForm,
  PageMarginSettingUpsertForm,
  PageMarginSettingDeleteForm,
  PageMarginOtherSettingForm,
  PageMarginOtherSettingUpsertForm,
  PageMarginOtherSettingDeleteForm,
  AuxiliaryLineSettingForm
} from './SettingConfigForms'
import moment from 'moment'

const getTabConfigList = ({
  currentUser,
  dispatch,
  enumValues,
  isSettingWritable,
  selectedIds,
  setting,
  alert
}) => {
  const handleCreateCharacterStyleSettingSubmit = ({
    ...props
  }) => {
    const settingName = _.snakeCase('characterStyleSettings')
    const settingId = setting.id
    dispatch(createSettingDetail({ settingId, settingName, ...props }))
  }
  const handleDeleteCharacterStyleSettingSubmit = ({
    selectedIds
  }) => {
    const settingName = _.snakeCase('characterStyleSettings')
    const settingId = setting.id
    dispatch(deleteSettingDetail({ settingId, settingName, id: selectedIds[0] }))
  }
  const handleCreatePageMarginSettingSubmit = ({
    ...props
  }) => {
    const settingName = _.snakeCase('pageMarginSettings')
    const settingId = setting.id
    // const { pageMarginSettingType } = props
    // console.log({ setting, pageMarginSettingType })
    // const largestOrder = setting.pageMarginSettings.kj
    const pageMarginSetting = {
      ...props
    }
    dispatch(createSettingDetail({ settingId, settingName, ...pageMarginSetting }))
  }
  const handleDeletePageMarginSettingSubmit = ({
    selectedIds
  }) => {
    const settingName = _.snakeCase('pageMarginSettings')
    const settingId = setting.id
    dispatch(deleteSettingDetail({ settingId, settingName, id: selectedIds[0] }))
  }
  const handleCreatePageMarginOtherSettingSubmit = ({
    ...props
  }) => {
    const settingName = _.snakeCase('pageMarginOtherSettings')
    const settingId = setting.id
    const pageMarginOtherSetting = {
      ...props
    }
    dispatch(createSettingDetail({ settingId, settingName, ...pageMarginOtherSetting }))
  }
  const handleDeletePageMarginOtherSettingSubmit = ({
    selectedIds
  }) => {
    const settingName = _.snakeCase('pageMarginOtherSettings')
    const settingId = setting.id
    dispatch(deleteSettingDetail({ settingId, settingName, id: selectedIds[0] }))
  }
  const largestPageMarginOtherOrder = setting?.pageMarginOtherSettings?.toSorted((a, b) => (b.order - a.order))?.map(s => s.order)?.at(0) ?? -1
  const largestOrders = Object.keys(enumValues.pageMarginSettingTypes).reduce((acc, type) => {
    const largestOrder = setting?.pageMarginSettings?.filter((s) => s.pageMarginSettingType === type)
      ?.sort((a, b) => (b.order - a.order))?.map((s) => s.order)?.at(0) ?? -1
    acc[type] = largestOrder
    return acc
  }, {})
  const initType = 'region'
  const initOrder = largestOrders[initType] + 1
  return [
    { title: '組版体裁', key: 'columnSetting', form: ColumnSettingForm, schema: ColumnSettingForm.schema },
    { title: '欄外出力設定', key: 'marginOutputSetting', form: MarginOutputSettingForm, schema: MarginOutputSettingForm.schema },
    {
      title: '欄外項目',
      key: 'pageMarginSettings',
      isExperimental: true,
      isArray: true,
      form: PageMarginSettingForm,
      schema: PageMarginSettingForm.schema,
      buttonModalSettings: [
        {
          name: 'createPageMargin',
          button: {
            handleClick: () => dispatch(openModal('createPageMargin')),
            disabled: currentUser.role !== 'group_administrator' || !isSettingWritable,
            icon: 'plus',
            text: '新規'
          },
          form: {
            initialValues: {
              pageMarginSettingType: initType,
              pageMarginOtherSettingId: '',
              order: initOrder,
              name: ''
            },
            handleSubmit: handleCreatePageMarginSettingSubmit,
            Component: PageMarginSettingUpsertForm,
            validationSchema: PageMarginSettingUpsertForm.schema,
            props: {
              title: '欄外項目追加',
              onClickCancel: () => dispatch(closeModal()),
              submitButtonText: '追加する',
              enumValues,
              isSettingWritable,
              alert,
              largestOrders,
              setting
            }
          }
        },
        {
          name: 'deletePageMarginSetting',
          button: {
            handleClick: () => dispatch(openModal('deletePageMarginSetting')),
            disabled: currentUser.role !== 'group_administrator' || !isSettingWritable || selectedIds.length !== 1,
            icon: 'trash',
            variant: 'outline-danger',
            text: '削除'
          },
          form: {
            initialValues: {
              selectedIds
            },
            handleSubmit: handleDeletePageMarginSettingSubmit,
            Component: PageMarginSettingDeleteForm,
            props: {
              title: '欄外項目削除',
              onClickCancel: () => dispatch(closeModal()),
              submitButtonText: '削除する',
              selectedIds,
              pageMarginSettings: setting?.pageMarginSettings,
              enumValues,
              isSettingWritable
            }
          }
        }
      ]
    },
    {
      title: '欄外項目・その他項目名',
      key: 'pageMarginOtherSettings',
      isArray: true,
      isExperimental: true,
      form: PageMarginOtherSettingForm,
      schema: PageMarginOtherSettingForm.schema,
      buttonModalSettings: [
        {
          name: 'createPageMarginOtherSetting',
          button: {
            handleClick: () => dispatch(openModal('createPageMarginOtherSetting')),
            disabled: currentUser.role !== 'group_administrator' || !isSettingWritable,
            icon: 'plus',
            text: '新規'
          },
          form: {
            initialValues: {
              order: largestPageMarginOtherOrder + 1,
              name: ''
            },
            handleSubmit: handleCreatePageMarginOtherSettingSubmit,
            Component: PageMarginOtherSettingUpsertForm,
            validationSchema: PageMarginOtherSettingUpsertForm.schema,
            props: {
              title: '欄外項目・その他項目名追加',
              onClickCancel: () => dispatch(closeModal()),
              submitButtonText: '追加する',
              enumValues,
              isSettingWritable,
              alert
            }
          }
        },
        {
          name: 'deletePageMarginOtherSetting',
          button: {
            handleClick: () => dispatch(openModal('deletePageMarginOtherSetting')),
            disabled: currentUser.role !== 'group_administrator' || !isSettingWritable || selectedIds.length !== 1,
            icon: 'trash',
            variant: 'outline-danger',
            text: '削除'
          },
          form: {
            initialValues: {
              selectedIds
            },
            handleSubmit: handleDeletePageMarginOtherSettingSubmit,
            Component: PageMarginOtherSettingDeleteForm,
            props: {
              title: '欄外項目・その他項目名削除',
              onClickCancel: () => dispatch(closeModal()),
              submitButtonText: '削除する',
              selectedIds,
              pageMarginSettings: setting?.pageMarginSettings,
              enumValues,
              isSettingWritable
            }
          }
        }
      ]
    },
    {
      title: '縦見出し行取り',
      key: 'verticalHeadingMarginSettings',
      isArray: true,
      form: VerticalHeadingMarginSettingForm,
      schema: VerticalHeadingMarginSettingForm.schema
    },
    {
      title: '被せ字取り',
      key: 'coveringMarginSettings',
      isArray: true,
      form: CoveringMarginSettingForm,
      schema: CoveringMarginSettingForm.schema
    },
    {
      title: '横見出し行取り',
      key: 'horizontalHeadingMarginSettings',
      isArray: true,
      form: HorizontalHeadingMarginSettingForm,
      schema: HorizontalHeadingMarginSettingForm.schema
    },
    {
      title: '段落スタイル',
      key: 'paragraphStyleSettings',
      isArray: true,
      form: ParagraphStyleSettingForm,
      schema: ParagraphStyleSettingForm.schema
    },
    {
      title: '見出し上下の空き',
      key: 'headingObjectStyleSettings',
      isArray: true,
      form: HeadingObjectStyleSettingForm,
      schema: HeadingObjectStyleSettingForm.schema
    },
    {
      title: '文字スタイル',
      key: 'characterStyleSettings',
      isArray: true,
      form: CharacterStyleSettingForm,
      schema: CharacterStyleSettingForm.schema,
      buttonModalSettings: [
        {
          name: 'createCharacterStyle',
          button: {
            handleClick: () => dispatch(openModal('createCharacterStyle')),
            disabled: currentUser.role !== 'group_administrator' || !isSettingWritable,
            icon: 'plus',
            text: '新規'
          },
          form: {
            initialValues: {
              characterStyleSettingType: 'sentence',
              description: '',
              name: ''
            },
            handleSubmit: handleCreateCharacterStyleSettingSubmit,
            Component: CharacterStyleSettingUpsertForm,
            validationSchema: CharacterStyleSettingUpsertForm.schema,
            props: {
              title: '文字スタイル追加',
              onClickCancel: () => dispatch(closeModal()),
              submitButtonText: '追加する',
              enumValues,
              isSettingWritable,
              alert
            }
          }
        },
        {
          name: 'deleteCharacterStyle',
          button: {
            handleClick: () => dispatch(openModal('deleteCharacterStyle')),
            disabled: currentUser.role !== 'group_administrator' || !isSettingWritable || selectedIds.length !== 1,
            icon: 'trash',
            variant: 'outline-danger',
            text: '削除'
          },
          form: {
            initialValues: {
              selectedIds
            },
            handleSubmit: handleDeleteCharacterStyleSettingSubmit,
            Component: CharacterStyleSettingDeleteForm,
            props: {
              title: '文字スタイル削除',
              onClickCancel: () => dispatch(closeModal()),
              submitButtonText: '削除する',
              selectedIds,
              characterStyleSettings: setting?.characterStyleSettings,
              enumValues,
              isSettingWritable
            }
          }
        }
      ]
    },
    {
      title: '前文字組',
      key: 'preambleCharacterSettings',
      isArray: true,
      form: PreambleCharacterSettingForm,
      schema: PreambleCharacterSettingForm.schema
    },
    {
      title: '前文行取り',
      key: 'preambleRowMarginSettings',
      isArray: true,
      form: PreambleRowMarginSettingForm,
      schema: PreambleRowMarginSettingForm.schema
    },
    {
      title: '写真基本行取り',
      key: 'figureRowMarginSettings',
      isArray: true,
      form: FigureRowMarginSettingForm,
      schema: FigureRowMarginSettingForm.schema
    },
    {
      title: '仕切り罫線・ヤマ罫線',
      key: 'lineRowMarginSettings',
      isArray: true,
      form: LineRowMarginSettingForm,
      schema: LineRowMarginSettingForm.schema
    },
    {
      title: '補助線',
      key: 'auxiliaryLineSettings',
      isArray: true,
      form: AuxiliaryLineSettingForm,
      schema: AuxiliaryLineSettingForm.schema
    }
  ]
}

const getRightPane = ({
  title, key, isArray, form: ComponentForm, schema, buttonModalSettings
}) => ({ setting, props }) => {
  const dispatch = useDispatch()
  const getHandleSettingDetailSubmit = (settingName, isArray) => ({
    ...props
  }) => {
    let snakeSettingName = _.snakeCase(settingName)
    if (!isArray) {
      snakeSettingName = `${snakeSettingName}s`
    }
    dispatch(modifySettingDetail({ settingId: setting.id, settingName: snakeSettingName, ...props }))
  }
  const { openedModal } = props
  const settingDetail = setting[key]
  if (!ComponentForm) {
    return (
      <Tab.Pane key={`tab-pane-${key}`} eventKey={key}>
        <h3 key={`title-${key}`}>{title}</h3>
        未実装
      </Tab.Pane>
    )
  }
  if (isArray) {
    return (
      <Tab.Pane key={`tab-pane-${key}`} eventKey={key}>
        <h3 key={`title-${key}`}>{title}</h3>
        {settingDetail && ComponentForm &&
         settingDetail.map((val, rowIndex) => {
           const formSetting = {
             initialValues: {
               ...val
             },
             Component: ComponentForm,
             handleSubmit: getHandleSettingDetailSubmit(key, isArray),
             props: {
               dispatch,
               rowIndex,
               setting,
               ...props
             }
           }
           if (schema) {
             formSetting.validationSchema = schema
           }
           const componentKey = `${key}-${val.id}`
           return (
             <CustomForm key={componentKey} form={formSetting} {...props} />
           )
         })}
        {buttonModalSettings &&
          <>
            <br />
            <ButtonModals
              settings={buttonModalSettings}
              openedModal={openedModal}
            />
          </>}
      </Tab.Pane>
    )
  } else {
    const formSetting = (settingDetail && ComponentForm)
      ? {
          initialValues: {
            ...settingDetail
          },
          handleSubmit: getHandleSettingDetailSubmit(key, isArray),
          Component: ComponentForm,
          props: {
            ...props
          }
        }
      : {}
    if (schema) {
      formSetting.validationSchema = schema
    }
    return (
      <Tab.Pane key={`tab-pane-${key}`} eventKey={key}>
        <h3 key={`title-${key}`}>{title}</h3>
        {settingDetail && ComponentForm &&
          <CustomForm form={formSetting} {...props} />}
      </Tab.Pane>
    )
  }
}

const SettingConfigDetails = ({
  setting,
  settingId,
  fetching,
  currentTab,
  dispatch,
  isSettingExportable,
  canShowExperimentalFeatures,
  ...props
}) => {
  if (!setting) return
  // const setting = settingList?.find((s) => s.id.toString() === settingId)
  if (fetching || !setting) {
    return
  }
  const tabConfigList = getTabConfigList({ dispatch, setting, ...props })
  const exportClickHandler = () => {
    const exportDateTime = moment(new Date()).format('YYYYMMDD_hhmm')
    const fileName = `${setting.media.name}_${setting.name}_${exportDateTime}.zip`
    dispatch(exportSetting({ id: settingId, fileName }))
  }
  return (
    <Tab.Container
      id={`setting-tab-${settingId}`} defaultActiveKey={currentTab}
      onSelect={(e) => {
        dispatch(selectTab(e))
        dispatch(clearSelectedIds(e))
      }}
    >
      <Row className='align-items-center'>
        <Col className='mr-auto'>
          <h2 className='m-2 settingName'>紙面ベース設定: {setting.name}</h2>
        </Col>
        {isSettingExportable &&
          <Col sm='auto' className='justify-content-end m-3'>
            <Button
              className='export-button'
              variant='outline-primary'
              size='sm'
              disabled={!isSettingExportable}
              onClick={exportClickHandler}
            >
              <FontAwesomeIcon icon='file-export' fixedWidth />エクスポート
            </Button>
          </Col>}
      </Row>
      <Row className='me-2'>
        <Col sm={3} className='fs-5'>
          <Nav variant='pills' className='flex-column'>
            {tabConfigList.map(({ title, key, isExperimental }) => {
              if (isExperimental && !canShowExperimentalFeatures) {
                return null
              }
              return (
                <Nav.Item key={`tab-nav-${key}`}>
                  <Nav.Link eventKey={key}>{title}</Nav.Link>
                </Nav.Item>
              )
            })}
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            {tabConfigList.map(c => {
              const { isExperimental } = c
              if (isExperimental && !canShowExperimentalFeatures) {
                return null
              }
              const RightPane = getRightPane(c)
              return (<RightPane key={c.key} props={props} setting={setting} />)
            })}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}

export const SettingConfig = () => {
  const {
    fetching,
    currentTab,
    openedModal,
    selectedIds,
    setting,
    alert,
    errors
  } = useSelector(selectSetting)
  const { enumValues } = useSelector(selectCommon)
  // TODO: add create user and allow operator to edit their own settings
  const { currentUser } = useSelector(selectAuth)
  const isSettingWritable = (currentUser.role === 'group_administrator')
  const canShowExperimentalFeatures = shouldShowExperimentalFeatures()
  const isSettingExportable = canShowExperimentalFeatures || currentUser.role === 'group_administrator'
  const dispatch = useDispatch()
  const { settingId } = useParams()
  useEffect(() => {
    dispatch(showSetting({ settingId }))
  }, [])
  return (
    <>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <SettingConfigDetails
        setting={setting} settingId={settingId}
        {...{
          dispatch,
          fetching,
          currentUser,
          enumValues,
          isSettingWritable,
          isSettingExportable,
          canShowExperimentalFeatures,
          currentTab,
          openedModal,
          selectedIds,
          selectId,
          unselectId,
          alert
        }}
      />
    </>
  )
}
