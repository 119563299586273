import React from 'react'
import {
  Nav,
  Navbar
} from 'react-bootstrap'
import {
  Outlet,
  useLocation
} from 'react-router-dom'
import {
  administratorRelatedFeaturesGranted,
  shouldShowExperimentalFeatures
} from '../auth'

export const Layout = ({
  children
}) => {
  const location = useLocation()
  // console.log('layout-props', location)
  const isAdministratorRelatedFeaturesGranted = administratorRelatedFeaturesGranted()
  const showExperimentalFeatures = shouldShowExperimentalFeatures()
  return (
    <>
      <Navbar bg='dark' variant='dark'>
        <Nav fill className='mr-auto' activeKey={location.pathname}>
          <Navbar.Brand href='/' className='ms-2'>
            新聞AI割付
          </Navbar.Brand>
          <Nav.Item>
            <Nav.Link href='/'>トップ</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href='/pages'>紙面一覧</Nav.Link>
          </Nav.Item>
          {showExperimentalFeatures &&
            <Nav.Item>
              <Nav.Link href='/sub_layouts'>小組一覧</Nav.Link>
            </Nav.Item>}
          {isAdministratorRelatedFeaturesGranted &&
            <Nav.Item>
              <Nav.Link href='/settings'>紙面ベース一覧</Nav.Link>
            </Nav.Item>}
          {isAdministratorRelatedFeaturesGranted &&
            <Nav.Item>
              <Nav.Link href='/media'>媒体一覧</Nav.Link>
            </Nav.Item>}
          {isAdministratorRelatedFeaturesGranted &&
            <Nav.Item>
              <Nav.Link href='/groups'>グループ一覧</Nav.Link>
            </Nav.Item>}
          {isAdministratorRelatedFeaturesGranted &&
            <Nav.Item>
              <Nav.Link href='/users'>ユーザー一覧</Nav.Link>
            </Nav.Item>}
        </Nav>
        <Navbar.Collapse className='justify-content-end me-2'>
          <Nav>{children}</Nav>
        </Navbar.Collapse>
      </Navbar>
      <Outlet />
    </>
  )
}
