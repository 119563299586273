import React from 'react'
import {
  Button,
  Form,
  Modal
} from 'react-bootstrap'

export const PageDeleteForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  // onSubmit,
  fetching,
  title,
  submitButtonText,
  onClickCancel,
  page
}) => (
  <>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Form noValidate onSubmit={handleSubmit}>
      <Modal.Body>
        <p>以下の紙面を削除します。よろしいですか？</p>
        <p>紙面名称：{values.name}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClickCancel}>キャンセル</Button>
        <Button type='submit' disabled={fetching}>{submitButtonText} </Button>
      </Modal.Footer>
    </Form>
  </>
)
