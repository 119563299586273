import { createAsyncThunk } from '@reduxjs/toolkit'
import { snakeCase, identity } from 'lodash'
import toCamel from 'lodash-humps'
import {
  throwErrorForErrorResponse,
  errorHandler
} from '../error'

export const objectToQuerystring = (params, keyPrefix = null) => {
  return Object.entries(params).map(([key, value]) => {
    const paramKey = keyPrefix !== null ? `${keyPrefix}[${snakeCase(key)}]` : snakeCase(key)

    if (typeof value === 'object') return objectToQuerystring(value, paramKey)

    const valueConverter = (paramKey === 'sort[column]') ? snakeCase : identity
    return `${paramKey}=${valueConverter(value)}`
  }).join('&')
}

export const createFetchAsyncThunk = (
  actionType,
  requestPath
) => createAsyncThunk(
  actionType,
  async (params, { getState, rejectWithValue }) => {
    const { baseUri } = getState().common

    const paramsString = objectToQuerystring(params)
    const path = typeof (requestPath) === 'function'
      ? requestPath(getState)
      : requestPath

    return fetch(
      `${baseUri}${path}?${paramsString}`, {
        headers: {
          Accept: 'application/json'
        }
      })
      .then(throwErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
  }
)
