import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import {
  Button,
  Container
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Checkbox,
  DataList,
  ButtonModals,
  ErrorModal
} from '../common'
import { administratorRelatedFeaturesGranted, selectAuth } from '../auth'
import {
  selectMedia,
  selectColumn,
  selectPage,
  selectId,
  unselectId,
  openModal,
  closeModal,
  dismissError
} from './mediaSlice'
import {
  fetchMedia,
  createMedia,
  modifyMedia,
  deleteMedia
} from './mediaAPI'
import { MediaUpsertForm, MediaDeleteForm } from '../media'

export const MediaList = () => {
  const {
    data,
    currentPage,
    totalPages,
    sort,
    selectedIds,
    openedModal,
    fetching,
    errors
  } = useSelector(selectMedia)
  const { currentUser } = useSelector(selectAuth)
  const isAdministratorRelatedFeaturesGranted = administratorRelatedFeaturesGranted()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchMedia({ page: currentPage, sort }))
  }, [sort, currentPage])

  const handleColumnClick = columnName => () => dispatch(selectColumn(columnName))

  const handlePaginationClick = page => dispatch(selectPage(page))
  const navigate = useNavigate()

  const columns = [
    {
      id: 'checkboxColumn',
      cell: props => <Checkbox
        value={props.row.original.id}
        checked={selectedIds.includes(parseInt(props.row.original.id))}
        onChange={(value, checked) => { dispatch((checked ? selectId : unselectId)(parseInt(value))) }}
                     />
    },
    { name: 'ID', accessorKey: 'id', sortKey: 'id' },
    {
      name: '名称',
      accessorKey: 'name',
      sortKey: 'name',
      cell: props => {
        const media = props.row.original
        return (
          <>
            {media.name}
            {isAdministratorRelatedFeaturesGranted &&
              <span className='m-2'>
                <Button
                  size='sm'
                  onClick={() => navigate(`/media/${media.id}/engineStats`)}
                >
                  <FontAwesomeIcon icon='table' fixedWidth />月別実行回数
                </Button>
              </span>}
          </>
        )
      }
    }
  ]
  if (currentUser?.role === 'global_administrator') {
    columns.push({
      name: 'グループ',
      cell: props => (
        props.row.original.group.name
      )
    })
  }

  const handleCreateSubmit = ({
    name
  }) => {
    dispatch(createMedia({ name }))
  }
  const handleEditSubmit = ({
    name
  }) => {
    if (selectedIds.length < 1) {
      return
    }

    const id = selectedIds[0]
    dispatch(modifyMedia({
      id,
      name
    }))
  }
  const handleDeleteSubmit = () => dispatch(deleteMedia({ id: selectedIds[0] }))
  const toolSettings = [
    {
      name: 'create',
      button: {
        handleClick: () => dispatch(openModal('create')),
        disabled: currentUser.role !== 'group_administrator',
        icon: 'plus',
        text: '新規'
      },
      form: {
        initialValues: {
          name: ''
        },
        handleSubmit: handleCreateSubmit,
        validationSchema: MediaUpsertForm.schema,
        Component: MediaUpsertForm,
        props: {
          title: '媒体新規作成',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '作成する'
        }
      }
    },
    {
      name: 'edit',
      button: {
        handleClick: () => dispatch(openModal('edit')),
        disabled: !isAdministratorRelatedFeaturesGranted || selectedIds.length !== 1,
        icon: 'pencil',
        text: '編集'
      },
      form: {
        initialValues: () => {
          const selectedMedia = _.find(data, { id: selectedIds[0] })
          if (!selectedMedia) {
            return {}
          }

          return {
            name: selectedMedia.name
          }
        },
        handleSubmit: handleEditSubmit,
        validationSchema: MediaUpsertForm.schema,
        Component: MediaUpsertForm,
        props: {
          title: '媒体編集',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '変更する'
        }
      }
    },
    {
      name: 'delete',
      button: {
        handleClick: () => dispatch(openModal('delete')),
        disabled: !isAdministratorRelatedFeaturesGranted || selectedIds.length !== 1,
        icon: 'trash',
        variant: 'outline-danger',
        text: '削除'
      },
      form: {
        handleSubmit: handleDeleteSubmit,
        Component: MediaDeleteForm,
        props: {
          title: '媒体削除',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '削除する',
          media: _.filter(data, media => selectedIds.includes(media.id))
        }
      }
    }
  ]
  return (
    <Container fluid>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <ButtonModals
        settings={toolSettings}
        openedModal={openedModal}
      />
      <DataList
        columns={columns}
        data={data}
        currentPage={currentPage}
        totalPages={totalPages}
        onColumnClick={handleColumnClick}
        sort={sort}
        onPaginationClick={handlePaginationClick}
      />
    </Container>
  )
}
