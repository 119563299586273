import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createColumnHelper } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import {
  Form,
  Row,
  CloseButton,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Container,
  Stack
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  selectCommon,
  toSnake,
  DataList,
  ButtonModals,
  ErrorModal
} from '../common'
import { selectAuth } from '../auth'
import {
  fetchMedia,
  selectMedia,
  fetchSettings,
  selectSetting
} from '../media'
import {
  fetchPages,
  copyPage,
  createPage,
  modifyPage,
  deletePage
} from './pageAPI'
import {
  selectColumn,
  selectPageItem,
  selectPage,
  selectId,
  unselectId,
  openModal,
  closeModal,
  setSearchCondition,
  dismissError
} from './pageSlice'
import {
  selectUser,
  fetchUsers
} from '../user'
import {
  PageUpsertForm,
  PageCopyForm,
  PageDeleteForm,
  PageSearchForm,
  getPageMarginRelatedLists
} from '../page'
import {
  isLayoutViewableStatus,
  isLayoutEditableStatus
} from '../article'
import moment from 'moment'
moment.locale('ja')

const columnHelper = createColumnHelper()
export const getHandleCreatePageSubmit = (dispatch, navigate, createPageAPI) =>
  ({
    mediaId,
    settingId,
    assigneeId,
    pageType,
    issueDate,
    issueNumber,
    pageNumber,
    pageRevision,
    pageMarginRegion,
    pageMarginOtherSelections,
    columnSetting,
    numberOfCharactersPerRow,
    numberOfRows,
    numberOfColumns,
    bottomAdNumberOfColumns,
    loadedBinaryFile
  }) => {
    const formData = new window.FormData()
    const pageMarginOtherSelectionsWithIds = pageMarginOtherSelections?.map(id => ({ id })) ?? []
    const newPage = {
      mediaId,
      assigneeId,
      pageBaseSettingId: settingId,
      pageType: _.snakeCase(pageType),
      issueDate,
      issueNumber,
      pageNumber,
      pageRevision,
      pageMarginRegion,
      pageMarginOtherSelections: pageMarginOtherSelectionsWithIds,
      columnSetting: _.snakeCase(columnSetting),
      numberOfCharactersPerRow,
      numberOfRows,
      numberOfColumns,
      bottomAdNumberOfColumns
    }
    formData.append('page', JSON.stringify(toSnake(newPage)))
    if (loadedBinaryFile !== undefined) {
      const { blob, name } = loadedBinaryFile
      formData.append('file', blob, name)
    }
    dispatch(createPageAPI({ navigate, formData }))
  }

export const getHandleEditPageSubmit = (dispatch, modifyPageAPI) =>
  ({
    mediaId,
    settingId,
    assigneeId,
    pageType,
    issueDate,
    issueNumber,
    pageNumber,
    pageRevision,
    pageMarginRegion,
    pageMarginOtherSelections,
    columnSetting,
    numberOfCharactersPerRow,
    numberOfRows,
    numberOfColumns,
    bottomAdNumberOfColumns,
    deleteFile,
    layoutableType,
    layoutableId,
    loadedBinaryFile,
    lockVersion
  }) => {
    const formData = new window.FormData()
    const pageMarginOtherSelectionsWithIds = pageMarginOtherSelections?.map(id => ({ id })) ?? []
    const newPage = {
      mediaId,
      pageBaseSettingId: settingId,
      assigneeId,
      pageType: _.snakeCase(pageType),
      issueDate,
      issueNumber,
      pageNumber,
      pageRevision,
      pageMarginRegion,
      pageMarginOtherSelections: pageMarginOtherSelectionsWithIds,
      columnSetting: _.snakeCase(columnSetting),
      numberOfCharactersPerRow,
      numberOfRows,
      numberOfColumns,
      bottomAdNumberOfColumns,
      lockVersion
    }
    console.log({ newPage, pageMarginOtherSelections, pageMarginOtherSelectionsWithIds })
    formData.append('page', JSON.stringify(toSnake(newPage)))
    formData.append('lock_version', lockVersion)
    if (deleteFile) {
      formData.append('delete_file', true)
    } else if (loadedBinaryFile !== undefined) {
      const { blob, name } = loadedBinaryFile
      formData.append('file', blob, name)
    }
    dispatch(modifyPageAPI({ layoutableType, layoutableId, formData }))
  }

export const getCreateFormSetting = ({
  handleCreateSubmit,
  fetching,
  enumValues,
  dispatch,
  closeModal,
  mediaList,
  settingList,
  userList,
  currentUser,
  alert
}) => {
  const mediaId = mediaList?.length > 0 ? mediaList[0].id : ''
  const selectedSettingList = mediaId !== '' ? settingList?.filter(s => s.media.id === mediaId) : []
  const settingId = selectedSettingList?.length > 0 ? selectedSettingList[0].id : ''
  const selectedSetting = selectedSettingList?.[0]
  const selectedColumnSetting = selectedSetting?.columnSetting
  const { pageMarginRegionList } = getPageMarginRelatedLists(selectedSetting)
  const today = moment(new Date()).format('YYYY-MM-DD')
  return ({
    initialValues: _.merge({
      mediaId,
      settingId,
      assigneeId: currentUser?.id,
      selectedSettingList,
      columnSetting: selectedColumnSetting?.typeOf || '',
      numberOfCharactersPerRow: selectedColumnSetting?.numberOfCharactersPerRow || '',
      numberOfRows: selectedColumnSetting?.numberOfRows || '',
      numberOfColumns: selectedColumnSetting?.numberOfColumns || '',
      pageType: 'left_page_spread',
      issueDate: today,
      issueNumber: 1,
      pageNumber: 1,
      pageRevision: 1,
      pageMarginRegion: pageMarginRegionList?.[0] || '',
      pageMarginOtherSelections: [],
      bottomAdNumberOfColumns: 0
    }),
    handleSubmit: handleCreateSubmit,
    validationSchema: PageUpsertForm.schema,
    Component: PageUpsertForm,
    props: {
      title: '紙面新規作成',
      fetching,
      enumValues,
      onClickCancel: () => dispatch(closeModal()),
      submitButtonText: '作成する',
      mediaList,
      settingList,
      userList,
      currentUser,
      alert
    }
  })
}

const SearchConditions = ({
  searchCondition,
  mediaList
}) => {
  const isEmpty = Object.values(searchCondition).every(x => x === '')
  if (isEmpty) {
    return
  }
  const dispatch = useDispatch()
  const mediaIdInt = parseInt(searchCondition?.mediaId)
  const mediaName = mediaList?.find((m) => m.id === mediaIdInt)?.name
  const issueDate = searchCondition?.issueDate ? moment(searchCondition?.issueDate).format('LL') : undefined
  const clearMediaNameSearchCondition = () => {
    dispatch(setSearchCondition({ name: 'mediaId', value: '' }))
  }
  const clearIssueDateSearchCondition = () => {
    dispatch(setSearchCondition({ name: 'issueDate', value: '' }))
  }
  const alertStyle = {
    color: '#0c5460',
    backgroundColor: '#d1ecf1'
  }
  const searchConditionClassNames = 'border border-info rounded p-1 my-0'
  return (
    <Row sm='auto'>
      <Col>
        <Stack direction='horizontal' gap={2} className='border border-secondary rounded d-flex align-items-center my-2 p-2'>
          <div>絞り込み条件</div>
          {mediaName &&
            <div style={alertStyle} className={searchConditionClassNames}>
              <CloseButton
                onClick={clearMediaNameSearchCondition}
              />
              媒体名: {mediaName}
            </div>}
          {issueDate &&
            <div style={alertStyle} className={searchConditionClassNames}>
              <CloseButton
                onClick={clearIssueDateSearchCondition}
              />
              発行日: {issueDate}
            </div>}
        </Stack>
      </Col>
    </Row>
  )
}

export const PageList = () => {
  const dispatch = useDispatch()
  const {
    data,
    currentPage,
    totalPages,
    sort,
    selectedIds,
    searchCondition,
    openedModal,
    fetching: pageFetching,
    errors,
    alert
  } = useSelector(selectPageItem)
  const { currentUser } = useSelector(selectAuth)
  const { enumValues } = useSelector(selectCommon)
  const {
    data: mediaList,
    fetching: mediaFetching
  } = useSelector(selectMedia)
  const {
    data: userList,
    fetching: userFetching
  } = useSelector(selectUser)
  const {
    data: settingList,
    fetching: settingFetching
  } = useSelector(selectSetting)
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(fetchPages({ page: currentPage, sort, search: searchCondition }))
    dispatch(fetchMedia({}))
    dispatch(fetchSettings({}))
    dispatch(fetchUsers({}))
  }, [sort, currentPage, searchCondition])
  const [fetching, setFetching] = useState(true)
  useEffect(() => {
    if (pageFetching || mediaFetching || settingFetching || userFetching) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }, [pageFetching, mediaFetching, settingFetching, userFetching])

  if (fetching) {
    return (<></>)
  }

  const mediaIdsInSettings = new Set(settingList?.map(s => s.media.id) || [])
  const filteredMediaList = mediaList?.filter(m => mediaIdsInSettings.has(m.id))

  const columns = [
    {
      id: 'checkboxColumn',
      cell: props => (
        <Form className='d-flex'>
          <Form.Check.Input
            type='checkbox'
            name='checkedGroups'
            checked={selectedIds.includes(parseInt(props.row.original.id))}
            value={props.row.original.id}
            onChange={event => dispatch((event.target.checked ? selectId : unselectId)(parseInt(event.target.value)))}
            disabled={fetching}
          />
        </Form>
      )
    },
    // { name: 'ID', accessorKey: 'id', sortKey: 'id' },
    { name: '名称', accessorKey: 'name', sortKey: 'name' },
    /* columnHelper.accessor('pageType', {
      name: '紙面種別',
      cell: info => enumValues.pageTypes[_.camelCase(info.getValue())],
      sortKey: 'pageType'
    }), */
    columnHelper.accessor('status', {
      name: 'ステータス',
      cell: info => enumValues.statuses[_.camelCase(info.getValue())],
      sortKey: 'status'
    }),
    /* columnHelper.accessor('columnSetting', {
      name: '組版体裁',
      cell: info => enumValues.columnSettings[_.camelCase(info.getValue())],
      sortKey: 'columnSetting'
    }),
    columnHelper.accessor('settingName', {
      name: '紙面ベース',
      cell: props => props.row.original.setting.name
    }), */
    columnHelper.accessor('layoutRelated', {
      name: '字数/行数/段数',
      cell: props => {
        const { numberOfCharactersPerRow, numberOfRows, numberOfColumns } = props.row.original
        return `${numberOfCharactersPerRow}/${numberOfRows}/${numberOfColumns}`
      }
    }),
    {
      name: '担当者',
      accessorKey: 'assignee',
      cell: info => info.getValue().name
    },
    {
      name: '最終更新者',
      accessorKey: 'updatedBy',
      cell: info => info.getValue().name
    },
    columnHelper.accessor('updatedAt', {
      name: '更新日時',
      cell: info => moment(info.getValue()).format('LLL'),
      sortKey: 'updatedAt'
    }),
    {
      id: 'actionColumn',
      name: '記事詳細',
      cell: props => (
        <Form className='d-flex'>
          <ButtonToolbar>
            <ButtonGroup size='sm'>
              <Button
                className='list-button'
                onClick={() => navigate(`/pages/${props.row.original.id}/articles`)}
              >
                <FontAwesomeIcon icon='list' fixedWidth />出稿表
              </Button>
              <Button
                className='layout-button'
                onClick={() => navigate(`/pages/${props.row.original.id}/layout`)}
                disabled={!isLayoutViewableStatus(props.row.original.status)}
              >
                <FontAwesomeIcon icon='table' fixedWidth />紙面
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Form>
      )
    }
  ]

  const handleColumnClick = columnName => () => {
    dispatch(selectColumn(columnName))
  }

  const handlePaginationClick = page => dispatch(selectPage(page))

  const handleCreateSubmit = getHandleCreatePageSubmit(dispatch, navigate, createPage)

  const handleEditSubmit = getHandleEditPageSubmit(dispatch, modifyPage)

  const handleCopySubmit = ({
    // pageId,
    draftNumber
  }) => {
    if (selectedIds.length < 1) {
      return
    }

    const id = selectedIds[0]
    dispatch(copyPage({ id, draftNumber, navigate }))
  }

  const handleDeleteSubmit = () => {
    if (selectedIds.length < 1) {
      return
    }

    const id = selectedIds[0]
    dispatch(deletePage({ id }))
  }

  const handleSearchSubmit = ({
    mediaId, issueDate
  }) => {
    // console.log('searchCondition', searchCondition)
    console.log('new searchCondition', { searchCondition, mediaId, issueDate })
    const newSearchConditions = [
      { name: 'mediaId', newValue: mediaId },
      { name: 'issueDate', newValue: issueDate }
    ]
    newSearchConditions.forEach(({ name, newValue }) => {
      dispatch(setSearchCondition({ name, value: newValue }))
    })
    dispatch(closeModal())
  }

  const singleSelectedPage = selectedIds.length === 1 ? _.find(data, { id: selectedIds[0] }) : null
  const isSelectedPageReadable = singleSelectedPage &&
        (currentUser.role === 'group_administrator' || currentUser.id === singleSelectedPage.assignee.id)
  const isSelectedPageWritable = isSelectedPageReadable && isLayoutEditableStatus(singleSelectedPage.status)
  const createFormSetting = getCreateFormSetting({
    handleCreateSubmit,
    fetching,
    enumValues,
    dispatch,
    closeModal,
    mediaList: filteredMediaList,
    settingList,
    userList,
    currentUser,
    alert
  })

  const toolSettings = [
    {
      name: 'create',
      button: {
        handleClick: () => {
          dispatch(openModal('create'))
        },
        disabled: fetching || currentUser.role === 'global_administrator',
        icon: 'plus',
        text: '新規'
      },
      form: createFormSetting
    },
    {
      name: 'edit',
      button: {
        handleClick: () => {
          dispatch(openModal('edit'))
        },
        disabled: fetching || !isSelectedPageWritable,
        icon: 'pencil',
        text: '編集'
      },
      form: {
        initialValues: () => {
          if (selectedIds.length < 1) {
            return {}
          }
          const pageId = selectedIds[0]
          const selectedPage = _.find(data, { id: pageId })
          if (!selectedPage) {
            return {}
          }
          const issueDate = moment(selectedPage.issueDate).format('YYYY-MM-DD')
          // console.log('issueDate', issueDate)
          const mediaId = selectedPage.media.id
          const selectedSettingList = mediaId !== '' ? settingList?.filter(s => s.media.id === mediaId) : []
          return {
            layoutableType: 'Page',
            layoutableId: pageId,
            mediaId,
            settingId: selectedPage.setting.id,
            assigneeId: selectedPage.assignee.id,
            selectedSettingList,
            ...selectedPage,
            issueDate
          }
        },
        handleSubmit: handleEditSubmit,
        validationSchema: PageUpsertForm.schema,
        Component: PageUpsertForm,
        props: {
          title: '紙面編集',
          fetching,
          enumValues,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '変更する',
          mediaList: filteredMediaList,
          settingList,
          userList,
          currentUser,
          alert
        }
      }
    },
    {
      name: 'copy',
      button: {
        handleClick: () => {
          dispatch(openModal('copy'))
        },
        disabled: fetching || !isSelectedPageReadable,
        icon: 'copy',
        text: '複製'
      },
      form: {
        initialValues: () => {
          const selectedPage = _.find(data, { id: selectedIds[0] })
          if (!selectedPage) {
            return {}
          }
          return {
            name: selectedPage.name,
            draftNumber: (selectedPage.draftNumber + 1)
          }
        },
        handleSubmit: handleCopySubmit,
        validationSchema: PageCopyForm.schema,
        Component: PageCopyForm,
        props: {
          title: '紙面複製',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '複製する',
          alert
        }
      }
    },
    {
      name: 'delete',
      button: {
        handleClick: () => {
          dispatch(openModal('delete'))
        },
        disabled: fetching || !isSelectedPageWritable,
        icon: 'trash',
        variant: 'outline-danger',
        text: '削除'
      },
      form: {
        initialValues: () => {
          const selectedPage = _.find(data, { id: selectedIds[0] })
          if (!selectedPage) {
            return {}
          }

          return {
            ...selectedPage
          }
        },
        handleSubmit: handleDeleteSubmit,
        Component: PageDeleteForm,
        props: {
          title: '紙面削除',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '削除する'
        }
      }
    },
    {
      name: 'search',
      button: {
        handleClick: () => {
          dispatch(openModal('search'))
        },
        disabled: fetching,
        icon: 'sliders-h',
        text: '絞り込む'
      },
      form: {
        initialValues: {
          issueDate: searchCondition?.issueDate || '',
          mediaId: searchCondition?.mediaId || ''
        },
        handleSubmit: handleSearchSubmit,
        validationSchema: PageSearchForm.schema,
        Component: PageSearchForm,
        props: {
          title: '紙面絞り込み',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '絞り込む',
          mediaList,
          alert
        }
      }
    }
  ]

  return (
    <Container fluid>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <ButtonModals
        settings={toolSettings}
        openedModal={openedModal}
      />
      <SearchConditions
        searchCondition={searchCondition}
        mediaList={mediaList}
      />
      <DataList
        columns={columns}
        data={data}
        currentPage={currentPage}
        totalPages={totalPages}
        onColumnClick={handleColumnClick}
        sort={sort}
        onPaginationClick={handlePaginationClick}
      />
    </Container>
  )
}
