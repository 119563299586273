import { createSlice } from '@reduxjs/toolkit'
import {
  DataList,
  initialErrorState,
  saveErrorInState,
  dismissError as commonDismissError
} from '../common'
import {
  fetchMedia,
  createMedia,
  modifyMedia,
  deleteMedia,
  fetchMediaEngineStats
} from './mediaAPI'

const initialState = {
  ...{
    sort: {
      column: 'id',
      order: 'desc'
    },
    selectedIds: [],
    openedModal: null,
    engineStats: null
  },
  ...DataList.initialState,
  ...initialErrorState
}

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    selectColumn: DataList.selectColumn,
    selectPage: DataList.selectPage,
    selectId: (state, action) => {
      state.selectedIds = Array.from(new Set(state.selectedIds).add(action.payload))
    },
    unselectId: (state, action) => {
      const set = new Set(state.selectedIds)
      set.delete(action.payload)
      state.selectedIds = Array.from(set)
    },
    openModal: (state, action) => {
      state.openedModal = action.payload
    },
    closeModal: (state) => {
      state.openedModal = null
    },
    dismissError: commonDismissError
  },
  extraReducers: builder => builder
    .addCase(fetchMedia.pending, DataList.fetchPending)
    .addCase(fetchMedia.fulfilled, DataList.fetchFulfilled)
    .addCase(fetchMedia.rejected, saveErrorInState(DataList.fetchRejected))
    .addCase(createMedia.pending, state => {
      state.fetching = true
    })
    .addCase(createMedia.fulfilled, state => {
      state.fetching = false
    })
    .addCase(createMedia.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(modifyMedia.pending, state => {
      state.fetching = true
    })
    .addCase(modifyMedia.fulfilled, state => {
      state.fetching = false
    })
    .addCase(modifyMedia.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(deleteMedia.pending, state => {
      state.fetching = true
    })
    .addCase(deleteMedia.fulfilled, state => {
      state.fetching = false
      state.selectedIds = []
    })
    .addCase(deleteMedia.rejected, saveErrorInState(state => {
      state.fetching = false
      state.selectedIds = []
    }))
    .addCase(fetchMediaEngineStats.pending, state => {
      state.fetching = true
    })
    .addCase(fetchMediaEngineStats.fulfilled, (state, action) => {
      state.fetching = false
      state.engineStats = action.payload
    })
    .addCase(fetchMediaEngineStats.rejected, saveErrorInState(state => {
      state.fetching = true
    }))
})

export const selectMedia = state => state.media
export const selectMediaErrors = state => state.media.errors

export const {
  selectColumn,
  selectPage,
  selectId,
  unselectId,
  openModal,
  closeModal,
  dismissError
} = mediaSlice.actions
