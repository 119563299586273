import { useEffect } from 'react'

import {
  $getSelection,
  $isRangeSelection,
  $nodesOfType,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_HIGH,
  INSERT_PARAGRAPH_COMMAND,
  INSERT_LINE_BREAK_COMMAND,
  PASTE_COMMAND,
  FORMAT_TEXT_COMMAND,
  ElementNode,
  ParagraphNode,
  TextNode,
  LineBreakNode,
  createCommand
} from 'lexical'
import {
  useLexicalComposerContext
} from '@lexical/react/LexicalComposerContext'
import {
  mergeRegister
} from '@lexical/utils'
import {
  $generateNodesFromSerializedNodes,
  $insertGeneratedNodes
} from '@lexical/clipboard'

import * as styles from './tageditor.module.css'

/*
 *  reference impl: lexical/packages/lexical-react/src/LexicalLinkPlugin.ts
 *  reference impl: lexical/packages/lexical-plain-text/src/index.ts
 */

export const TOGGLE_MARKUP_COMMAND = createCommand('TOGGLE_MARKUP_COMMAND')
export const RESET_MARKUP_COMMAND = createCommand('RESET_MARKUP_COMMAND')

export class CustomParagraphNode extends ParagraphNode {
  /**
   * 新規パラグラフに付与するスタイル。編集開始時に一番初めに発見したスタイルを用いる。
   */
  static defaultStyle = null

  /**
   * 標準のParagraphNodeを拡張しDOMエクスポート時の属性を変更する。
   * プラグインを導入することで標準のParagraphNodeはオーバーライドされる。
   */
  constructor (key) {
    super(key)
    this.__style = CustomParagraphNode.defaultStyle
  }

  static getType () {
    return 'custom-paragraph'
  }

  static clone (node) {
    const cloned = new CustomParagraphNode(node.__key)
    cloned.__style = node.__style
    return cloned
  }

  createDOM (config) {
    return super.createDOM(config)
  }

  updateDOM (prevNode, dom, config) {
    return super.updateDOM(prevNode, dom, config)
  }

  static importDOM () {
    /**
     * DOM pタグをインポートする際にこのクラスを優先させる。
     * pタグのstyle属性をインスタンスの情報として取り込んでおく。
     */
    return {
      p: (element) => {
        return {
          conversion: (element) => {
            const node = $createCustomParagraphNode()
            CustomParagraphNode.defaultStyle = CustomParagraphNode.defaultStyle ?? element.getAttribute('style') ?? null
            node.setStyle(element.getAttribute('style'))
            return { node }
          },
          priority: 4
        }
      }
    }
  }

  exportDOM (editor) {
    const element = document.createElement('p')
    element.setAttribute('style', this.getStyle())
    return { element }
  }

  static importJSON (serializedNode) {
    const node = super.importJSON(serializedNode)
    node.setStyle(serializedNode?.styleName)
    return node
  }

  exportJSON () {
    return {
      ...super.exportJSON(),
      styleName: this.getStyle(),
      type: 'custom-paragraph',
      version: 1
    }
  }

  /**
   * data setter/getter
   */
  getStyle () {
    return this.getLatest().__style
  }

  setStyle (name) {
    const writable = this.getWritable()
    writable.__style = name
  }
}

const $createCustomParagraphNode = () => {
  const node = new CustomParagraphNode()
  node.setStyle(CustomParagraphNode.defaultStyle)
  return node
}

export const $isCustomParagraphNode = (node) => {
  return node instanceof CustomParagraphNode
}

const convertTextDOMNode = (domNode, _parent, preformatted) => {
  /**
   * from lexical code
   */
  let textContent = domNode.textContent || ''
  if (!preformatted && /\n/.test(textContent)) {
    textContent = textContent.replace(/\r?\n/gm, ' ')
    if (textContent.trim().length === 0) {
      return { node: null }
    }
  }
  return { node: $createCustomTextNode(textContent) }
}

export class CustomTextNode extends TextNode {
  /**
   * TextNodeが生成するspanタグにTextNodeであることを示す属性をつける拡張クラス。
   * DOM生成後にspanをTextNodeに変換するためのもの。
   */
  static getType () {
    return 'custom-text'
  }

  static clone (node) {
    return new CustomTextNode(node.__text, node.__key)
  }

  createDOM (config) {
    return super.createDOM(config)
  }

  updateDOM (prevNode, dom, config) {
    return super.updateDOM(prevNode, dom, config)
  }

  static importDOM () {
    return {
      ...Object.keys(super.importDOM()).reduce((acc, key) => {
        if (key === 'br') {
          return acc
        }

        acc[key] = () => {
          return {
            conversion: convertTextDOMNode,
            priority: 4
          }
        }
        return acc
      }, {}),
      br: () => {
        return {
          conversion: (node) => {
            return null
          },
          priority: 3
        }
      }
    }
  }

  exportDOM (editor) {
    const { element } = super.exportDOM(editor)
    element.setAttribute('data-tageditor-text', 'true')
    return { element }
  }

  static importJSON (serializedNode) {
    return super.importJSON(serializedNode)
  }

  exportJSON () {
    return {
      ...super.exportJSON(),
      type: 'custom-text',
      version: 1
    }
  }
}

export const $createCustomTextNode = (text) => {
  return new CustomTextNode(text)
}

export const $isCustomTextNode = (node) => {
  return node instanceof CustomTextNode
}

export class CustomLineBreakNode extends LineBreakNode {
  static getType () {
    return 'custom-linebreak'
  }

  static clone (node) {
    return new CustomLineBreakNode(node.__key)
  }

  createDOM () {
    const br = document.createElement('span')
    br.classList.add(styles.pseudo_br)
    return br
  }

  updateDOM (prevNode, dom, config) {
    return false
  }

  exportDOM (editor) {
    const element = document.createElement('br')
    return { element }
  }

  static importDOM () {
    return {
      br: () => {
        return {
          conversion: (node) => {
            return { node: $createCustomLineBreakNode() }
          },
          priority: 4
        }
      }
    }
  }

  static importJSON (serializedNode) {
    console.log(serializedNode)
    return $createCustomLineBreakNode()
  }

  exportJSON () {
    return {
      ...super.exportJSON(),
      type: 'custom-linebreak',
      version: 1
    }
  }
}

export const $createCustomLineBreakNode = () => {
  return new CustomLineBreakNode()
}

export const $isCustomLineBreakNode = (node) => {
  return node instanceof CustomLineBreakNode
}

export class MarkupNode extends ElementNode {
  /**
   * タグ付け用のノードクラス
   * タグ名と内容を保持しておきDOMエクスポート時にそのタグで出力する。
   */
  static getType () {
    return 'markup'
  }

  static clone (node) {
    return new MarkupNode(
      node.__tagName, node.__styleName, node.__key)
  }

  constructor (tagName, styleName, key) {
    super(key)
    this.__tagName = tagName
    this.__styleName = styleName
  }

  createDOM (config) {
    const element = document.createElement('span')
    element.classList.add(styles.markup_node)
    element.setAttribute('data-markup-style-name', `${this.getStyleName()}`)
    return element
  }

  updateDOM (prevNode, dom, config) {
    return false
  }

  static importDOM () {
    return {
      c: (element) => {
        return {
          conversion: (element) => {
            const node = $createMarkupNode('c', element.getAttribute('style'))
            return { node }
          },
          priority: 4
        }
      }
    }
  }

  exportDOM (editor) {
    const element = document.createElement(this.__tagName)
    element.setAttribute('style', this.__styleName)
    return { element }
  }

  static importJSON (serializedNode) {
    return $createMarkupNode(serializedNode.tagName, serializedNode.styleName)
  }

  exportJSON () {
    return {
      ...super.exportJSON(),
      tagName: this.getTagName(),
      styleName: this.getStyleName(),
      type: 'markup',
      version: 1
    }
  }

  getTagName () {
    return this.getLatest().__tagName
  }

  setTagName (name) {
    const writable = this.getWritable()
    writable.__tagName = name
  }

  getStyleName () {
    return this.getLatest().__styleName
  }

  setStyleName (name) {
    const writable = this.getWritable()
    writable.__styleName = name
  }

  canInsertTextBefore () {
    return false
  }

  canInsertTextAfter () {
    return false
  }

  canBeEmpty () {
    return false
  }

  isInline () {
    return true
  }
}

const $createMarkupNode = (name, style) => {
  return new MarkupNode(name, style)
}

const $isMarkupNode = (node) => {
  return node instanceof MarkupNode
}

const $getAncestor = (node, predicate) => {
  let parent = node
  while (
    parent !== null &&
    (parent = parent.getParent()) !== null &&
    !predicate(parent)
  );
  return parent
}

const $getMarkupAncestor = (node) => {
  return $getAncestor(node, (ancestor) => $isMarkupNode(ancestor))
}

const filterNodes = (nodes, allowLineBreak, allowMultiParagraph, availableStyles) => {
  /**
   * シングルラインの場合はパラグラフを取り除く
   * それぞれのエディタフォームで対応していないスタイルは取り除く
   */
  const newNodes = nodes.reduce((acc, node) => {
    if ((['linebreak', 'custom-linebreak'].includes(node.type)) && !allowLineBreak) {
      return acc
    }

    if (!('children' in node)) {
      return [...acc, node]
    }

    if ((node.type === 'markup' && availableStyles.every(style => style.name !== node.styleName)) ||
        (node.type === 'custom-paragraph' && !allowMultiParagraph)) {
      return [...acc, ...filterNodes(node.children, availableStyles)]
    }

    node.children = filterNodes(node.children, availableStyles)
    return [...acc, node]
  }, [])

  return newNodes
}

const $insertDataTransferForMarkupPlugin = (
  dataTransfer, selection, editor, allowLineBreak, allowMultiParagraph, characterStyles) => {
  const lexicalString = dataTransfer.getData('application/x-lexical-editor')
  if (lexicalString) {
    // from $insertDataTransferForRichText
    try {
      const payload = JSON.parse(lexicalString)

      if (payload.namespace === editor._config.namespace && Array.isArray(payload.nodes)) {
        const filteredNodes = filterNodes(payload.nodes, allowLineBreak, allowMultiParagraph, characterStyles)
        const nodes = $generateNodesFromSerializedNodes(filteredNodes)
        $insertGeneratedNodes(editor, nodes, selection)
        return
      }
    } catch {
      // Fail silently.
    }
  }

  const plainString = dataTransfer.getData('text/plain')
  if (plainString) {
    const parts = plainString.split(/\r?\n/)
    parts.forEach((part, idx) => {
      if (part !== '') {
        selection.insertText(part)
      }
      if (idx !== parts.length - 1) {
        selection.insertParagraph()
      }
    })
  }
}

export const toggleMarkup = (name, style) => {
  const selection = $getSelection()

  if (!$isRangeSelection(selection) || selection.isCollapsed()) {
    return
  }

  const nodes = selection.extract()

  // Add or merge MarkupNodes
  if (nodes.length === 1) {
    const firstNode = nodes[0]
    // if the first node is a MarkupNode or if its
    // parent is a MarkupNode, we update the markup.
    const markupNode = $isMarkupNode(firstNode)
      ? firstNode
      : $getMarkupAncestor(firstNode)
    if (markupNode !== null) {
      markupNode.setTagName(name)
      markupNode.setStyleName(style)
      return
    }
  }

  let prevParent = null
  let markupNode = null

  nodes.forEach((node) => {
    const parent = node.getParent()

    if (
      parent === markupNode ||
      parent === null ||
      ($isMarkupNode(node) && !node.isInline()) ||
      $isCustomParagraphNode(node)
    ) {
      return
    }

    if ($isMarkupNode(parent)) {
      markupNode = parent
      markupNode.setTagName(name)
      markupNode.setStyleName(style)
      return
    }

    if (!parent.is(prevParent)) {
      prevParent = parent
      markupNode = $createMarkupNode(name, style)

      if ($isMarkupNode(parent)) {
        if (node.getPreviousSibling() === null) {
          parent.insertBefore(markupNode)
        } else {
          parent.insertAfter(markupNode)
        }
      } else {
        node.insertBefore(markupNode)
      }
    }

    if ($isMarkupNode(node)) {
      if (node.is(markupNode)) {
        return
      }
      if (markupNode !== null) {
        const children = node.getChildren()

        for (let i = 0; i < children.length; i++) {
          markupNode.append(children[i])
        }
      }

      node.remove()
      return
    }

    if (markupNode !== null) {
      markupNode.append(node)
    }
  })
}

export const normalizeEditorState = () => {
  const markupNodes = $nodesOfType(MarkupNode)
  for (let i = 0; i < markupNodes.length; i++) {
    const node = markupNodes[i]
    const next = node.getNextSibling()
    if (!$isMarkupNode(next)) {
      continue
    }
    if (node.getTagName() !== next.getTagName() ||
      node.getStyleName() !== next.getStyleName()) {
      continue
    }
    node.append(...next.getChildren())
    next.remove()
    markupNodes.splice(markupNodes.indexOf(next), 1)
    i -= 1
  }

  const textNodes = $nodesOfType(CustomTextNode)
  for (let i = 0; i < textNodes.length; i++) {
    const node = textNodes[i]
    const next = node.getNextSibling()
    if (!$isCustomTextNode(next)) {
      continue
    }
    node.setTextContent(node.getTextContent() + next.getTextContent())
    next.remove()
    textNodes.splice(textNodes.indexOf(next), 1)
    i -= 1
  }
}

export const resetMarkup = () => {
  const nodes = $nodesOfType(MarkupNode)
  for (const node of nodes) {
    const next = node.getNextSibling()
    if (next !== null) {
      const children = node.getChildren()
      node.remove()
      for (const child of children) {
        next.insertBefore(child)
      }
    } else {
      const parent = node.getParent()
      const children = node.getChildren()
      node.remove()
      parent.append(...children)
    }
  }
  return null
}

export const MarkupPlugin = ({ editable, allowLineBreak, allowMultiParagraph, characterStyles, ...props }) => {
  const [editor] = useLexicalComposerContext()

  const editingRegister = editable
    ? [
        editor.registerCommand(INSERT_PARAGRAPH_COMMAND, (payload) => {
          // パラグラフ挿入
          // 複数パラグラフ禁止状態ではtrueを返し、何もしないまま移譲を中止する
          return !allowMultiParagraph
        }, COMMAND_PRIORITY_HIGH),
        editor.registerCommand(PASTE_COMMAND, (payload) => {
          // ペーストの処理
          if (payload.type !== 'paste') {
            return true
          }
          const selection = $getSelection()
          if (!$isRangeSelection(selection)) {
            return true
          }
          payload.preventDefault()
          editor.update(() => {
            $insertDataTransferForMarkupPlugin(
              payload.clipboardData, selection, editor, allowLineBreak, allowMultiParagraph, characterStyles)
            normalizeEditorState()
          }, { tag: 'paste' })
          return true
        }, COMMAND_PRIORITY_LOW),
        editor.registerCommand(FORMAT_TEXT_COMMAND, (payload) => {
          // lexical標準のフォーマット指定コマンド
          // 処理したことにして無視する
          return true
        }, COMMAND_PRIORITY_LOW),
        editor.registerCommand(INSERT_LINE_BREAK_COMMAND, (payload) => {
          if (!allowLineBreak) {
            return true
          }
          const selection = $getSelection()
          if (!$isRangeSelection(selection)) {
            return false
          }
          selection.insertLineBreak(payload)
          return true
        }, COMMAND_PRIORITY_LOW)
      ]
    : []

  useEffect(() => {
    if (!editor.hasNodes([MarkupNode])) {
      throw new Error('MarkupPlugin: MarkupNode not registered on editor')
    }

    return mergeRegister(
      editor.registerCommand(TOGGLE_MARKUP_COMMAND, (payload) => {
        if (payload === null) {
          return false
        } else {
          const { name, style } = payload
          toggleMarkup(name, style)
          normalizeEditorState()
          // workaround for selection glitch
          window.getSelection().removeAllRanges()
          return true
        }
      }, COMMAND_PRIORITY_LOW),
      editor.registerCommand(RESET_MARKUP_COMMAND, (payload) => {
        resetMarkup()
        normalizeEditorState()
        return true
      }, COMMAND_PRIORITY_LOW),
      ...editingRegister
    )
  }, [editor])
}

export const MarkupNodes = [
  CustomParagraphNode,
  {
    replace: ParagraphNode,
    with: (node) => {
      return new CustomParagraphNode()
    }
  },
  CustomTextNode,
  {
    replace: TextNode,
    with: (node) => {
      return new CustomTextNode(node.__text)
    }
  },
  CustomLineBreakNode,
  {
    replace: LineBreakNode,
    with: (node) => {
      return new CustomLineBreakNode()
    }
  },
  MarkupNode
]
