/* eslint-env browser */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  Stack
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'

import {
  modifyLayoutIndex
} from '../subLayout/subLayoutAPI'
import {
  isLayoutEditableStatus
} from '../article'

import {
  drawPreview,
  clearPreview,
  clearSelectStateFromAllElements,
  clearHoverStateFromAllElements,
  setSelectStateForElements,
  setHoverStateForElements,
  setSvgScale,
  getSvgObject
} from './layout_preview'
import {
  BASE_HEIGHT,
  HEADER_HEIGHT
} from './common'

const LayoutIndexSelector = ({
  layoutable,
  layoutIndex,
  setLayoutIndex,
  layoutResultSize
}) => {
  if (layoutResultSize <= 1) {
    return (<></>)
  }
  const dispatch = useDispatch()
  const isWritable = isLayoutEditableStatus(layoutable.status)
  return (
    <>
      <ToggleButtonGroup
        size='sm'
        onChange={layoutIndex => {
          setLayoutIndex(layoutIndex)
          if (isWritable) {
            dispatch(modifyLayoutIndex({ id: layoutable.id, layoutIndex }))
          }
        }}
        type='radio' name='layoutIndex' defaultValue={layoutIndex}
      >
        {_.range(layoutResultSize).map(idx => (
          <ToggleButton
            key={`layoutIndex-option-${idx + 1}`} id={`layoutIndex-${idx + 1}`}
            disabled={!isWritable} variant='outline-primary' value={idx}
          >
            パターン{idx + 1}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  )
}

export const LayoutPreview = (props) => {
  // プレビュー描画コンポーネント
  const containerStyle = {
    border: 'solid 1px black',
    textAlign: 'center',
    overflowX: 'auto',
    overflowY: 'auto',
    maxHeight: `calc(${BASE_HEIGHT} - ${HEADER_HEIGHT} - 60px)`
  }

  const [svgContainerId] = useState(_.uniqueId('svg-'))
  const [rowIndicator, setRowIndicator] = useState('--')
  const [colIndicator, setColIndicator] = useState('--')
  const [zoomLebel, setZoomLevel] = useState(0)

  const handleExportPNG = (e) => {
    // clear visual state
    clearSelectStateFromAllElements(svgContainerId)
    clearHoverStateFromAllElements(svgContainerId)

    // generate PNG and emit download
    const svg = getSvgObject(svgContainerId)

    if (svg !== undefined) {
      // 非同期処理に関連して本当はクローンした方が良いがsvg.jsのcloneに問題があるため
      // 今のところはクローンせずに処理する。3.1.2より後のバージョンで改修されたら
      // クローンしてからのエクスポートにする。
      // const svg2 = svg.clone()
      const svgBase64 = btoa(unescape(encodeURIComponent(svg.svg())))
      const img = new Image()
      img.src = 'data:image/svg+xml;base64,' + svgBase64
      const canvas = document.createElement('canvas')
      canvas.width = svg.width() * 2
      canvas.height = svg.height() * 2

      img.onload = () => {
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, svg.width(), svg.height(), 0, 0, canvas.width, canvas.height)
        const link = document.createElement('a')
        link.download = 'layout.png'
        link.href = canvas.toDataURL('image/png')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }

    // restore visual state
    if (props.selectedElementsSelector !== undefined) {
      setSelectStateForElements(svgContainerId, props.selectedElementsSelector)
    }
    if (props.hoveredElementsSelector !== undefined) {
      setHoverStateForElements(svgContainerId, props.hoveredElementsSelector)
    }
  }

  const handleExportSVG = (e) => {
    // clear visual state
    clearSelectStateFromAllElements(svgContainerId)
    clearHoverStateFromAllElements(svgContainerId)

    // generate SVG and emit download
    const svg = getSvgObject(svgContainerId)

    if (svg !== undefined) {
      // 非同期処理に関連して本当はクローンした方が良いがsvg.jsのcloneに問題があるため
      // 今のところはクローンせずに処理する。3.1.2より後のバージョンで改修されたら
      // クローンしてからのエクスポートにする。
      // const svg2 = svg.clone()
      const svgBase64 = btoa(unescape(encodeURIComponent(svg.svg())))
      const link = document.createElement('a')
      link.download = 'layout.svg'
      link.href = 'data:image/svg+xml;base64,' + svgBase64
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    // restore visual state
    if (props.selectedElementsSelector !== undefined) {
      setSelectStateForElements(svgContainerId, props.selectedElementsSelector)
    }
    if (props.hoveredElementsSelector !== undefined) {
      setHoverStateForElements(svgContainerId, props.hoveredElementsSelector)
    }
  }

  const { layout, articles, layoutable } = props
  useEffect(
    () => {
      // 初期動作、および記事内容が変わったときの動作
      // TODO: 記事のIDが変わった時にのみ動作すようにする
      if ([layout, articles, layoutable].some(e => e === null)) {
        return
      }

      const onRowColIndicatorUpdate = (row, col) => {
        if (row < 0 || col < 0) {
          setRowIndicator('--')
          setColIndicator('--')
          return
        }
        setRowIndicator(String(row))
        setColIndicator(String(col))
      }

      drawPreview(
        layout,
        articles,
        layoutable,
        svgContainerId,
        {
          onClickSegment: props.onClickOfSegments,
          onMouseOverSegment: props.onMouseEnterOfSegments,
          onMouseOutSegment: props.onMouseLeaveOfSegments
        },
        onRowColIndicatorUpdate
      )

      return () => {
        clearPreview(svgContainerId)
      }
    },
    [layout, articles]
  )

  useEffect(
    () => {
      clearSelectStateFromAllElements(svgContainerId)

      if (props.selectedElementsSelector !== undefined) {
        setSelectStateForElements(svgContainerId, props.selectedElementsSelector)
      }

      clearHoverStateFromAllElements(svgContainerId)

      if (props.hoveredElementsSelector !== undefined) {
        setHoverStateForElements(svgContainerId, props.hoveredElementsSelector)
      }
    },
    [props.selectedElementsSelector, props.hoveredElementsSelector]
  )

  useEffect(
    () => {
      const factor = [1.0, 1.5, 2.0, 3.0, 5.0][zoomLebel]
      setSvgScale(svgContainerId, factor)
    },
    [zoomLebel]
  )

  const numberOfMissLayout = layout.articles.filter(elem => elem.areas.length === 0).length
  const missLayoutAlert = numberOfMissLayout > 0
    ? (
      <Alert className='position-absolute start-0' variant='warning' style={{ margin: '10px' }}>
        <FontAwesomeIcon icon='triangle-exclamation' size='xl' />
        割り付けされなかった記事が{numberOfMissLayout}本あります。
      </Alert>
      )
    : undefined

  const rowColIndicatorAreaStyle = {
    margin: '10px',
    padding: '5px',
    background: 'white',
    border: '2px dimgray solid',
    borderRadius: '5px'
  }
  const rowColIndicatorStyle = {
    textAlign: 'right', display: 'inline-block', width: '3em'
  }

  const rowColIndicator = (
    <div
      className='position-absolute end-0'
      style={rowColIndicatorAreaStyle}
    >
      <span style={rowColIndicatorStyle}>{colIndicator}段</span>
      <span style={rowColIndicatorStyle}>{rowIndicator}行</span>
    </div>
  )

  return (
    <>
      <Stack direction='vertical' gap={3}>
        <div className='position-relative'>
          {missLayoutAlert}
          {rowColIndicator}
          <div id={svgContainerId} style={containerStyle} />
        </div>
        <Stack direction='horizontal' gap={3}>
          <div>
            <Button
              className='m-1'
              size='sm'
              onClick={handleExportPNG}
            >PNGファイル
            </Button>
            <Button
              className='m-1'
              size='sm'
              onClick={handleExportSVG}
            >SVGファイル
            </Button>
          </div>
          <LayoutIndexSelector {...props} />
          <div className='ms-auto' />
          <div>
            <FontAwesomeIcon icon='magnifying-glass' size='lg' />
          </div>
          <div>
            <ToggleButtonGroup
              size='sm'
              onChange={setZoomLevel}
              type='radio' name='options' defaultValue={0}
            >
              <ToggleButton id='zoomcheck-1' variant='outline-secondary' value={0}> 100% </ToggleButton>
              <ToggleButton id='zoomcheck-2' variant='outline-secondary' value={1}> 150% </ToggleButton>
              <ToggleButton id='zoomcheck-3' variant='outline-secondary' value={2}> 200% </ToggleButton>
              <ToggleButton id='zoomcheck-4' variant='outline-secondary' value={3}> 300% </ToggleButton>
              <ToggleButton id='zoomcheck-5' variant='outline-secondary' value={4}> 500% </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Stack>
      </Stack>
    </>
  )
}
