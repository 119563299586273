import React from 'react'
import {
  Form,
  Row,
  Col,
  Modal,
  Button
} from 'react-bootstrap'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

export const UpsertGroupForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  fetching,
  onClickCancel,
  title,
  submitButtonText
}) => (
  <>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Form noValidate onSubmit={handleSubmit}>
      <Modal.Body>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column className='col-2'>名称</Form.Label>
          <Col>
            <Form.Control
              type='text'
              name='name'
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.name && errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.name}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClickCancel}>
          キャンセル
        </Button>
        <Button type='submit' disabled={!isValid || fetching}>{submitButtonText}</Button>
      </Modal.Footer>
    </Form>
  </>
)

UpsertGroupForm.schema = Yup.object().shape({
  name: Yup.string().required()
})
