import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import {
  createFetchAsyncThunk,
  csrfToken,
  toSnake,
  throwErrorForErrorResponse,
  errorHandler
} from '../common'
import { closeModal } from './groupSlice'

export const fetchGroups = createFetchAsyncThunk(
  'group/fetchGroups',
  'groups'
)

export const createGroup = createAsyncThunk(
  'group/createGroup',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common

    return fetch(
      `${baseUri}groups`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().group
        dispatch(fetchGroups({ page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue)).finally(() => {
        dispatch(closeModal())
      })
  }
)

export const modifyGroup = createAsyncThunk(
  'group/modifyGroup',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { group: { id } } = params

    return fetch(
      `${baseUri}groups/${id}`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().group
        dispatch(fetchGroups({ page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => {
        dispatch(closeModal())
      })
  }
)

export const disableGroups = createAsyncThunk(
  'group/disableGroups',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common

    return fetch(
      `${baseUri}groups`, {
        method: 'delete',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().group
        dispatch(fetchGroups({ page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue)).finally(() => {
        dispatch(closeModal())
      })
  }
)

export const addUsersToGroup = createAsyncThunk(
  'group/addUsers',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common

    return fetch(
      `${baseUri}users`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => Promise.resolve({}))
      .then(toCamel)
      .catch(errorHandler(rejectWithValue)).finally(() => {
        dispatch(closeModal())
      })
  }
)
