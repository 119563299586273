import React from 'react'
import {
  Alert,
  Button,
  Form,
  Table,
  Modal
} from 'react-bootstrap'

export const UserEnableDisableForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  fetching,
  title,
  submitButtonText,
  onClickCancel,
  selectedIds,
  users,
  message
}) => {
  const selectedIdSet = new Set(selectedIds)
  const targetUsers = users.filter((a) => selectedIdSet.has(a.id))
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Alert variant='danger'>{message}</Alert>
          <Table striped bordered hover size='sm'>
            <thead>
              <tr>
                <td>ID</td>
                <td>名称</td>
                <td>メールアドレス</td>
                <td>ロール</td>
                <td>グループ</td>
              </tr>
            </thead>
            <tbody>
              {targetUsers.map(user => (
                <tr key={user?.id}>
                  <td>{user?.id}</td>
                  <td>{user?.name}</td>
                  <td>{user?.email}</td>
                  <td>{user?.role}</td>
                  <td>{user?.group?.name || '-'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>キャンセル</Button>
          <Button type='submit' disabled={fetching}>{submitButtonText} </Button>
        </Modal.Footer>
      </Form>
    </>
  )
}
