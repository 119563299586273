import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createColumnHelper } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Container,
  Form
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  selectCommon,
  toSnake,
  DataList,
  ButtonModals,
  ErrorModal
} from '../common'
import { selectAuth } from '../auth'
import {
  fetchMedia,
  selectMedia,
  fetchSettings,
  selectSetting
} from '../media'
import {
  fetchSubLayouts,
  createSubLayout,
  modifySubLayout,
  copySubLayout,
  deleteSubLayout
} from './subLayoutAPI'
import {
  selectColumn,
  selectSubLayoutItem,
  selectPage,
  selectId,
  unselectId,
  openModal,
  closeModal,
  dismissError
} from './subLayoutSlice'
import {
  selectUser,
  fetchUsers
} from '../user'
import {
  SubLayoutUpsertForm,
  SubLayoutCopyForm,
  SubLayoutDeleteForm
} from '../subLayout'
import moment from 'moment'
import { isLayoutEditableStatus, isLayoutViewableStatus } from '../article'
moment.locale('ja')

const columnHelper = createColumnHelper()
const convertLinePlacement = (linePlacement) => {
  return linePlacement.replace(/P/, '_p')
}
export const getHandleCreateSubLayoutSubmit = (dispatch, navigate, createSubLayoutAPI) =>
  ({
    mediaId,
    settingId,
    assigneeId,
    issueDate,
    numberOfCharactersPerRow,
    numberOfRows,
    numberOfColumns,
    name,
    layoutNumberOfColumns,
    layoutNumberOfRows,
    layoutOrientation,
    borderLineWidth,
    borderLineType,
    lineAlignment,
    linePlacement,
    loadedBinaryFile
  }) => {
    const formData = new window.FormData()
    // console.log('linePlacement', { before: linePlacement, after: convertLinePlacement(linePlacement) })
    const newSubLayout = {
      mediaId,
      assigneeId,
      pageBaseSettingId: settingId,
      issueDate,
      numberOfCharactersPerRow,
      numberOfRows,
      numberOfColumns,
      name,
      layoutNumberOfColumns,
      layoutNumberOfRows,
      layoutOrientation,
      borderLineWidth,
      borderLineType,
      lineAlignment,
      linePlacement: convertLinePlacement(linePlacement)
    }
    formData.append('sub_layout', JSON.stringify(toSnake(newSubLayout)))
    if (loadedBinaryFile !== undefined) {
      const { blob, name } = loadedBinaryFile
      formData.append('file', blob, name)
    }
    dispatch(createSubLayoutAPI({ navigate, formData }))
  }

export const getHandleEditSubLayoutSubmit = (dispatch, modifySubLayoutAPI) =>
  ({
    subLayoutId,
    mediaId,
    settingId,
    assigneeId,
    numberOfCharactersPerRow,
    numberOfRows,
    numberOfColumns,
    name,
    layoutNumberOfColumns,
    layoutNumberOfRows,
    layoutOrientation,
    borderLineWidth,
    borderLineType,
    lineAlignment,
    linePlacement,
    deleteFile,
    loadedBinaryFile,
    lockVersion
  }) => {
    const formData = new window.FormData()
    const newSubLayout = {
      mediaId,
      pageBaseSettingId: settingId,
      assigneeId,
      numberOfCharactersPerRow,
      numberOfRows,
      numberOfColumns,
      name,
      layoutNumberOfColumns,
      layoutNumberOfRows,
      layoutOrientation,
      borderLineWidth,
      borderLineType,
      lineAlignment,
      linePlacement: convertLinePlacement(linePlacement),
      lockVersion
    }
    formData.append('sub_layout', JSON.stringify(toSnake(newSubLayout)))
    if (deleteFile) {
      formData.append('delete_file', true)
    } else if (loadedBinaryFile !== undefined) {
      const { blob, name } = loadedBinaryFile
      formData.append('file', blob, name)
    }
    dispatch(modifySubLayoutAPI({ id: subLayoutId, formData }))
  }

export const getCreateFormSetting = ({
  handleCreateSubmit,
  fetching,
  enumValues,
  dispatch,
  closeModal,
  mediaList,
  settingList,
  userList,
  currentUser,
  alert
}) => {
  const mediaId = mediaList?.length > 0 ? mediaList[0].id : ''
  const selectedSettingList = mediaId !== '' ? settingList?.filter(s => s.media.id === mediaId) : []
  const selectedColumnSetting = selectedSettingList?.[0]?.columnSetting
  // console.log('selectedColumnSetting', selectedColumnSetting)
  const today = moment(new Date()).format('YYYY-MM-DD')
  return ({
    initialValues: _.merge({
      mediaId,
      settingId: selectedSettingList?.length > 0 ? selectedSettingList[0].id : '',
      assigneeId: currentUser?.id,
      selectedSettingList,
      numberOfCharactersPerRow: selectedColumnSetting?.numberOfCharactersPerRow || '',
      numberOfRows: selectedColumnSetting?.numberOfRows || '',
      numberOfColumns: selectedColumnSetting?.numberOfColumns || '',
      name: '',
      issueDate: today,
      layoutNumberOfColumns: 1,
      layoutNumberOfRows: 1,
      layoutOrientation: 'vertical',
      useBorderLine: true,
      borderLineWidth: '0.5',
      borderLineType: 'solid',
      lineAlignment: 'center',
      linePlacement: 'l4'
    }),
    handleSubmit: handleCreateSubmit,
    validationSchema: SubLayoutUpsertForm.schema,
    Component: SubLayoutUpsertForm,
    props: {
      title: '小組新規作成',
      fetching,
      enumValues,
      onClickCancel: () => dispatch(closeModal()),
      submitButtonText: '作成する',
      mediaList,
      settingList,
      userList,
      currentUser,
      alert
    }
  })
}

export const SubLayoutList = () => {
  const dispatch = useDispatch()
  const {
    data,
    currentPage,
    totalPages,
    sort,
    selectedIds,
    openedModal,
    fetching: subLayoutFetching,
    errors,
    alert
  } = useSelector(selectSubLayoutItem)
  const { currentUser } = useSelector(selectAuth)
  const { enumValues } = useSelector(selectCommon)
  const {
    data: mediaList,
    fetching: mediaFetching
  } = useSelector(selectMedia)
  const {
    data: userList,
    fetching: userFetching
  } = useSelector(selectUser)
  const {
    data: settingList,
    fetching: settingFetching
  } = useSelector(selectSetting)
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(fetchSubLayouts({ page: currentPage, sort }))
    dispatch(fetchMedia({}))
    dispatch(fetchSettings({}))
    dispatch(fetchUsers({}))
  }, [sort, currentPage])
  const [fetching, setFetching] = useState(true)
  useEffect(() => {
    if (subLayoutFetching || mediaFetching || settingFetching || userFetching) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }, [subLayoutFetching, mediaFetching, settingFetching, userFetching])

  if (fetching) {
    return (<></>)
  }
  const mediaIdsInSettings = new Set(settingList?.map(s => s.media.id) || [])
  const filteredMediaList = mediaList?.filter(m => mediaIdsInSettings.has(m.id))
  const columns = [
    {
      id: 'checkboxColumn',
      cell: props => (
        <Form className='d-flex'>
          <Form.Check.Input
            type='checkbox'
            name='checkedGroups'
            checked={selectedIds.includes(parseInt(props.row.original.id))}
            value={props.row.original.id}
            onChange={event => dispatch((event.target.checked ? selectId : unselectId)(parseInt(event.target.value)))}
            disabled={fetching}
          />
        </Form>
      )
    },
    columnHelper.accessor('name', {
      name: '名称',
      cell: props => {
        const subLayout = props.row.original
        const issueDate = moment(subLayout.issueDate).format('LL')
        return (`${subLayout.media.name} ${issueDate} ${subLayout.name} (${subLayout.draftNumber})`)
      },
      sortKey: 'name'
    }),
    columnHelper.accessor('status', {
      name: 'ステータス',
      cell: info => enumValues.statuses[_.camelCase(info.getValue())],
      sortKey: 'status'
    }),
    columnHelper.accessor('layoutRelated', {
      name: '字数/行数/段数',
      cell: props => {
        const { numberOfCharactersPerRow, numberOfRows, numberOfColumns } = props.row.original
        return `${numberOfCharactersPerRow}/${numberOfRows}/${numberOfColumns}`
      }
    }),
    {
      name: '小組版面',
      cell: props => {
        const subLayout = props.row.original
        return (`${subLayout.layoutNumberOfColumns}段${subLayout.layoutNumberOfRows}行`)
      }
    },
    {
      name: '担当者',
      accessorKey: 'assignee',
      cell: info => info.getValue().name
    },
    {
      name: '最終更新者',
      accessorKey: 'updatedBy',
      cell: info => info.getValue().name
    },
    columnHelper.accessor('updatedAt', {
      name: '更新日時',
      cell: info => moment(info.getValue()).format('LLL'),
      sortKey: 'updatedAt'
    }),
    {
      id: 'actionColumn',
      name: '小組詳細',
      cell: props => (
        <Form className='d-flex'>
          <ButtonToolbar>
            <ButtonGroup size='sm'>
              <Button
                className='list-button'
                onClick={() => navigate(`/sub_layouts/${props.row.original.id}/articles`)}
              >
                <FontAwesomeIcon icon='list' fixedWidth />出稿表
              </Button>
              <Button
                className='layout-button'
                onClick={() => navigate(`/sub_layouts/${props.row.original.id}/layout`)}
                disabled={!isLayoutViewableStatus(props.row.original.status)}
              >
                <FontAwesomeIcon icon='table' fixedWidth />紙面
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Form>
      )
    }
  ]

  const handleColumnClick = columnName => () => {
    dispatch(selectColumn(columnName))
  }

  const handlePaginationClick = page => dispatch(selectPage(page))

  const handleCreateSubmit = getHandleCreateSubLayoutSubmit(dispatch, navigate, createSubLayout)

  const handleEditSubmit = getHandleEditSubLayoutSubmit(dispatch, modifySubLayout)

  const handleCopySubmit = ({
    draftNumber
  }) => {
    if (selectedIds.length < 1) {
      return
    }

    const id = selectedIds[0]
    dispatch(copySubLayout({ id, draftNumber, navigate }))
  }

  const handleDeleteSubmit = () => {
    if (selectedIds.length < 1) {
      return
    }

    const id = selectedIds[0]
    dispatch(deleteSubLayout({ id }))
  }

  const singleSelectedSubLayout = selectedIds.length === 1 ? _.find(data, { id: selectedIds[0] }) : null
  const isSelectedSubLayoutReadable = singleSelectedSubLayout &&
        (currentUser.role === 'group_administrator' || currentUser.id === singleSelectedSubLayout.assignee.id)
  const isSelectedSubLayoutWritable = isSelectedSubLayoutReadable && isLayoutEditableStatus(singleSelectedSubLayout.status)
  const createFormSetting = getCreateFormSetting({
    handleCreateSubmit, fetching, enumValues, dispatch, closeModal, mediaList: filteredMediaList, settingList, userList, currentUser, alert
  })

  const toolSettings = [
    {
      name: 'create',
      button: {
        handleClick: () => {
          dispatch(openModal('create'))
        },
        disabled: fetching || currentUser.role === 'global_administrator',
        icon: 'plus',
        text: '新規'
      },
      form: createFormSetting
    },
    {
      name: 'edit',
      button: {
        handleClick: () => {
          dispatch(openModal('edit'))
        },
        disabled: fetching || !isSelectedSubLayoutWritable,
        icon: 'pencil',
        text: '編集'
      },
      form: {
        initialValues: () => {
          if (selectedIds.length < 1) {
            return {}
          }
          const subLayoutId = selectedIds[0]
          const { linePlacement, ...selectedSubLayout } = _.find(data, { id: subLayoutId })
          if (!selectedSubLayout) {
            return {}
          }
          const mediaId = selectedSubLayout.media.id
          const issueDate = moment(selectedSubLayout.issueDate).format('YYYY-MM-DD')
          const selectedSettingList = mediaId !== '' ? settingList?.filter(s => s.media.id === mediaId) : []
          const useBorderLine = selectedSubLayout.linePlacement !== 'l0'
          return {
            subLayoutId,
            mediaId,
            settingId: selectedSubLayout.setting.id,
            assigneeId: selectedSubLayout.assignee.id,
            selectedSettingList,
            useBorderLine,
            ...selectedSubLayout,
            linePlacement: _.camelCase(linePlacement),
            borderLineWidth: selectedSubLayout?.borderLineWidth || '',
            issueDate
          }
        },
        handleSubmit: handleEditSubmit,
        validationSchema: SubLayoutUpsertForm.schema,
        Component: SubLayoutUpsertForm,
        props: {
          title: '小組編集',
          fetching,
          enumValues,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '変更する',
          mediaList: filteredMediaList,
          settingList,
          userList,
          currentUser,
          alert
        }
      }
    },
    {
      name: 'copy',
      button: {
        handleClick: () => {
          dispatch(openModal('copy'))
        },
        disabled: fetching || !isSelectedSubLayoutReadable,
        icon: 'copy',
        text: '複製'
      },
      form: {
        initialValues: () => {
          const selectedSubLayout = _.find(data, { id: selectedIds[0] })
          if (!selectedSubLayout) {
            return {}
          }
          return {
            name: selectedSubLayout.name,
            draftNumber: (selectedSubLayout.draftNumber + 1)
          }
        },
        handleSubmit: handleCopySubmit,
        validationSchema: SubLayoutCopyForm.schema,
        Component: SubLayoutCopyForm,
        props: {
          title: '小組複製',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '複製する',
          alert
        }
      }
    },
    {
      name: 'delete',
      button: {
        handleClick: () => {
          dispatch(openModal('delete'))
        },
        disabled: fetching || !isSelectedSubLayoutWritable,
        icon: 'trash',
        variant: 'outline-danger',
        text: '削除'
      },
      form: {
        initialValues: () => {
          const selectedSubLayout = _.find(data, { id: selectedIds[0] })
          if (!selectedSubLayout) {
            return {}
          }

          return {
            ...selectedSubLayout
          }
        },
        handleSubmit: handleDeleteSubmit,
        Component: SubLayoutDeleteForm,
        props: {
          title: '小組削除',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '削除する'
        }
      }
    }
  ]
  return (
    <Container fluid>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <ButtonModals
        settings={toolSettings}
        openedModal={openedModal}
      />
      <DataList
        columns={columns}
        data={data}
        currentPage={currentPage}
        totalPages={totalPages}
        onColumnClick={handleColumnClick}
        sort={sort}
        onPaginationClick={handlePaginationClick}
      />
    </Container>
  )
}
