import React from 'react'
import {
  Modal,
  Form,
  Button,
  Row,
  Col
} from 'react-bootstrap'
import _ from 'lodash'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

const allowedUserRoles = new Set(['operator', 'supervisor', 'group_administrator'])

export const UserUpdateForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  onClickCancel,
  isValid,
  fetching,
  title,
  submitButtonText,
  enumValues
}) => {
  if (!values) { return }
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column className='col-2'>名前</Form.Label>
            <Col>
              <Form.Control
                type='text'
                name='name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.name && errors.name}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column className='col-2'>メールアドレス</Form.Label>
            <Col>
              <Form.Control
                type='text'
                name='email'
                readOnly
                plaintext
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && errors.email}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.email}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column className='col-2'>ロール</Form.Label>
            <Col>
              <Form.Control
                as='select'
                name='role'
                value={values.role}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.role && errors.role}
              >
                {Object.keys(enumValues.userRoles).filter(k => allowedUserRoles.has(_.snakeCase(k)))
                  .map(k => (<option key={k} value={_.snakeCase(k)}>{enumValues.userRoles[k]}</option>))}
              </Form.Control>
              <Form.Control.Feedback type='invalid'>
                {errors.role}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={!isValid || fetching}>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}

UserUpdateForm.schema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  role: Yup.string().oneOf(allowedUserRoles)
})
