import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createColumnHelper } from '@tanstack/react-table'
import _ from 'lodash'
import {
  Container,
  Form
} from 'react-bootstrap'
import {
  selectCommon,
  Checkbox,
  DataList,
  ButtonModals,
  ErrorModal
} from '../common'
import { administratorRelatedFeaturesGranted, selectAuth } from '../auth'
import {
  fetchGroups,
  createGroup,
  modifyGroup,
  disableGroups,
  addUsersToGroup
} from './groupAPI'
import {
  selectColumn,
  selectGroup,
  selectPage,
  selectId,
  unselectId,
  openModal,
  closeModal,
  dismissError
} from './groupSlice'
import { UpsertGroupForm } from './UpsertGroupForm'
import { DisableGroupForm } from './DisableGroupForm'
import { AddUserForm } from './AddUserForm'

const columnHelper = createColumnHelper()
export const GroupList = () => {
  const dispatch = useDispatch()
  const {
    data,
    currentPage,
    totalPages,
    sort,
    selectedIds,
    openedModal,
    fetching,
    errors
  } = useSelector(selectGroup)
  const { currentUser } = useSelector(selectAuth)
  const { enumValues } = useSelector(selectCommon)
  const isAdministratorRelatedFeaturesGranted = administratorRelatedFeaturesGranted()
  useEffect(() => {
    dispatch(fetchGroups({ page: currentPage, sort }))
  }, [sort, currentPage])

  const columns = [
    {
      id: 'checkboxColumn',
      cell: props => <Checkbox
        value={props.row.original.id}
        checked={selectedIds.includes(parseInt(props.row.original.id))}
        onChange={(value, checked) => { dispatch((checked ? selectId : unselectId)(parseInt(value))) }}
                     />
    },
    { name: 'ID', accessorKey: 'id', sortKey: 'id' },
    { name: '名称', accessorKey: 'name', sortKey: 'name' }
  ]
  if (currentUser?.role === 'global_administrator') {
    columns.push(columnHelper.accessor('groupType', {
      name: 'グループ種別',
      cell: info => (
        <Form.Control
          as='select'
          type='text'
          name='groupType'
          value={info.getValue()}
          onChange={(e) => {
            const groupType = e.target.value
            const props = { group: { ...info.row.original, groupType } }
            dispatch(modifyGroup(props))
          }}
          size='sm'
        >
          {Object.keys(enumValues.groupTypes).map(k => (
            <option key={k} value={_.snakeCase(k)}>{enumValues.groupTypes[k]}</option>
          ))}
        </Form.Control>
      )
    }))
  }
  const handleColumnClick = columnName => () => {
    dispatch(selectColumn(columnName))
  }

  const handlePaginationClick = page => dispatch(selectPage(page))

  const handleCreateSubmit = ({
    name
  }) => {
    dispatch(createGroup({
      name
    }))
  }

  const handleEditSubmit = ({
    name
  }) => {
    if (selectedIds.length < 1) {
      return
    }

    const id = selectedIds[0]
    dispatch(modifyGroup({ group: { id, name } }))
  }

  const handleDisableSubmit = () => dispatch(disableGroups(selectedIds))

  const handleAddUserSubmit = ({ users }) => {
    if (selectedIds.length !== 1) {
      return
    }

    const groupId = selectedIds[0]
    const params = _.map(users, user => _.merge(user, { groupId }))
    dispatch(addUsersToGroup(params))
  }

  const toolSettings = [
    {
      name: 'create',
      button: {
        handleClick: () => dispatch(openModal('create')),
        disabled: currentUser.role !== 'global_administrator',
        icon: 'plus',
        text: '新規'
      },
      form: {
        initialValues: {
          name: ''
        },
        handleSubmit: handleCreateSubmit,
        validationSchema: UpsertGroupForm.schema,
        Component: UpsertGroupForm,
        props: {
          title: 'グループ新規作成',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '作成する'
        }
      }
    },
    {
      name: 'edit',
      button: {
        handleClick: () => dispatch(openModal('edit')),
        disabled: !isAdministratorRelatedFeaturesGranted ||
          selectedIds.length !== 1 ||
          (currentUser.role === 'group_administrator' && selectedIds[0] !== currentUser.groupId),
        icon: 'pencil',
        text: '編集'
      },
      form: {
        initialValues: () => {
          const selectedGroup = _.find(data, { id: selectedIds[0] })
          if (!selectedGroup) {
            return {}
          }

          return {
            name: selectedGroup.name
          }
        },
        handleSubmit: handleEditSubmit,
        validationSchema: UpsertGroupForm.schema,
        Component: UpsertGroupForm,
        props: {
          title: 'グループ編集',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '変更する'
        }
      }
    },
    {
      name: 'delete',
      button: {
        handleClick: () => dispatch(openModal('delete')),
        disabled: !isAdministratorRelatedFeaturesGranted ||
          selectedIds.length < 1 ||
          (currentUser.role === 'group_administrator' && _.some(selectedIds, id => id !== currentUser.groupId)),
        icon: 'trash',
        variant: 'outline-danger',
        text: '削除'
      },
      form: {
        handleSubmit: handleDisableSubmit,
        Component: DisableGroupForm,
        props: {
          title: 'グループ削除',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '削除する',
          groups: _.filter(data, group => selectedIds.includes(group.id))
        }
      }
    },
    {
      name: 'add-user',
      button: {
        handleClick: () => dispatch(openModal('add-user')),
        disabled: !isAdministratorRelatedFeaturesGranted ||
          selectedIds.length !== 1 ||
          (currentUser.role === 'group_administrator' && selectedIds[0] !== currentUser.groupId),
        icon: 'user-plus',
        text: 'ユーザー追加'
      },
      form: {
        initialValues: {
          users: [{
            name: '',
            email: '',
            role: 'operator'
          }]
        },
        handleSubmit: handleAddUserSubmit,
        validationSchema: AddUserForm.schema,
        Component: AddUserForm,
        props: {
          fetching,
          enumValues,
          onClickCancel: () => dispatch(closeModal())
        }
      }
    }
  ]

  return (
    <Container fluid>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <ButtonModals
        settings={toolSettings}
        openedModal={openedModal}
      />
      <DataList
        columns={columns}
        data={data}
        currentPage={currentPage}
        totalPages={totalPages}
        onColumnClick={handleColumnClick}
        sort={sort}
        onPaginationClick={handlePaginationClick}
      />
    </Container>
  )
}
