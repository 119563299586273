import React from 'react'
import {
  Form
} from 'react-bootstrap'

export const Checkbox = ({
  value,
  checked,
  onChange,
  disabled
}) => (
  <Form>
    <Form.Check.Input
      type='checkbox'
      name='checkedGroups'
      checked={checked}
      value={value}
      onChange={event => onChange(event.target.value, event.target.checked)}
      disabled={disabled}
    />
  </Form>
)
