import React from 'react'
import {
  Alert
} from 'react-bootstrap'

export const DismissibleAlert = ({
  alert,
  onClose,
  ...props
}) => {
  if (!alert) {
    return
  }
  const { show, variant, title, alertBody } = alert
  if (show) {
    return (
      <Alert variant={variant || 'success'} onClose={onClose} {...props} dismissible>
        {title &&
          <Alert.Heading>{title}</Alert.Heading>}
        {alertBody}
      </Alert>
    )
  }
}
