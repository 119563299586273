import React from 'react'
import {
  Form,
  Row,
  Col,
  Modal,
  Button,
  Alert
} from 'react-bootstrap'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

export const PageSearchForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  setFieldValue,
  fetching,
  enumValues,
  onClickCancel,
  title,
  submitButtonText,
  mediaList,
  currentUser,
  alert,
  ...props
}) => {
  const clearSearchConditions = () => {
    const fieldNames = ['mediaId', 'issueDate']
    fieldNames.forEach((fieldName) => {
      setFieldValue(fieldName, '')
    })
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {alert?.show && alert?.alertBody &&
        <Alert
          className='upsert-page-errors-alert'
          variant='danger'
        >
          {alert.alertBody}
        </Alert>}
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Row className='mb-3'>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label column className='mediaIdLabel'>媒体名</Form.Label>
              <Col>
                <Form.Control
                  as='select'
                  type='text'
                  name='mediaId'
                  value={values.mediaId}
                  // onChange={(e) => {
                  //   const mediaId = parseInt(e.target.value)
                  //   handleChange(e)
                  // }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.mediaId && errors.mediaId}
                  // disabled={mediaList?.length <= 1}
                >
                  <option value='' />
                  {mediaList?.map(({ id, name }) => (
                    <option key={id} value={id}>{name}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.mediaId}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col} className='mb-3'>
              <Form.Label column className='issueDateLabel'>発行日</Form.Label>
              <Col>
                <Form.Control
                  type='date'
                  name='issueDate'
                  value={values.issueDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.issueDate && errors.issueDate}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.issueDate}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='reset' variant='light' onClick={clearSearchConditions}>条件リセット</Button>
          <Button type='submit' disabled={!isValid || fetching}>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}

PageSearchForm.schema = Yup.object().shape({
  mediaId: Yup.number(),
  issueDate: Yup.date()
})
