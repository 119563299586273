import { useEffect, useRef } from 'react'

export const BASE_HEIGHT = '100vh'
export const HEADER_HEIGHT = '100px'

export const useArrayEffect = (action, data) => {
  const prevDataRef = useRef()

  useEffect(() => {
    if (prevDataRef.current) {
      const changedIndices = data.reduce((acc, item, index) => {
        if (index < prevDataRef.current.length && item !== prevDataRef.current[index]) {
          acc.push(index)
        }
        return acc
      }, [])
      changedIndices(action)
    }

    prevDataRef.current = data
  }, [action, data])

  useEffect(() => {
    if (!prevDataRef.current) {
      data.forEach((_, index) => action(index))
    }
  }, [])
}
