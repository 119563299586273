import React from 'react'
import {
  Alert,
  Form,
  ListGroup,
  Modal,
  Button
} from 'react-bootstrap'
import { camelCase } from 'lodash'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

export const ArticlesDeleteForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  onClickCancel,
  enumValues,
  isValid,
  title,
  layoutable,
  message,
  selectedIds,
  fetching
}) => {
  const selectedIdSet = new Set(selectedIds)
  const targetArticles = layoutable.articles.filter((a) => selectedIdSet.has(a.id))
  // console.log(targetArticles)
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Alert variant='danger'>{message}</Alert>
          <ListGroup>
            {targetArticles.map(article => {
              return (
                <ListGroup.Item key={article.id}>
                  id: {article.id},
                  種別: {enumValues.articleTypes[camelCase(article.articleType)]},
                  優先度: {enumValues.priorities[camelCase(article.priority)]},
                  見出し: {article?.headings?.at(0)?.content}
                </ListGroup.Item>
              )
            })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={!isValid || fetching}>削除する</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}

ArticlesDeleteForm.schema = Yup.object().shape({
  layoutableType: Yup.string().required(),
  layoutableId: Yup.number().required(),
  selectedIds: Yup.array().of(
    Yup.number()
  ).required().min(1)
})
