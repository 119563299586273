import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import _ from 'lodash'
import toCamel from 'lodash-humps'
import { homeSlice } from '../features/home'
import { commonSlice } from '../features/common'
import { authSlice } from '../features/auth'
import { groupSlice } from '../features/group'
import { pageSlice } from '../features/page'
import { articleSlice } from '../features/article'
import { subLayoutSlice } from '../features/subLayout'
import { userSlice } from '../features/user'
import { mediaSlice, settingSlice } from '../features/media'
const slices = [homeSlice, commonSlice, authSlice, groupSlice, articleSlice, pageSlice, subLayoutSlice, userSlice, mediaSlice, settingSlice]

const reducer = _.reduce(
  slices,
  (result, slice) => _.merge(result, { [slice.name]: slice.reducer }),
  {}
)

export const setupStore = initialState => {
  const middleware = (getDefaultMiddleware) => {
    const logger = createLogger()
    const middlewareProps = {
      serializableCheck: {
        ignoredActions: ['page/exportLayout/fulfilled']
      }
    }
    return process.env.NODE_ENV !== 'production'
      ? getDefaultMiddleware(middlewareProps).concat(logger)
      : getDefaultMiddleware(middlewareProps)
  }
  return configureStore({
    reducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: _.reduce(
      slices,
      (result, slice) => _.merge(result, { [slice.name]: slice.getInitialState() }),
      toCamel(initialState)
    )
  })
}
