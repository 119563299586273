import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import {
  csrfToken,
  throwErrorForErrorResponse,
  errorHandler
} from '../common'

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { getState, rejectWithValue }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/sign_out`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })
      .then(throwErrorForErrorResponse)
      .then(() => {
        return Promise.resolve({ redirectUri: baseUri })
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
  }
)
