import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  Form,
  Container,
  Row,
  Col,
  Modal,
  Button
} from 'react-bootstrap'
import { toSnake } from '../common'
import { readAllFiles, parseFilenameTags } from './ArticleParse'

export const ArticleBulkUploadForm = ({
  layoutable,
  values,
  handleSubmit,
  isValid,
  setFieldValue,
  onClickCancel,
  title,
  existingFiles,
  holderArticle,
  submitButtonText,
  layoutableLockVersion
}) => {
  const acceptedTypes = {
    'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.psd'],
    'application/postscript': ['.eps'],
    'text/plain': ['.txt'],
    'application/octet-stream': ['.indd']
  }
  const existingFilesValidator = (file) => {
    if (existingFiles?.length > 0) {
      // console.log(file)
      const { filename } = parseFilenameTags(file.name)
      if (existingFiles.includes(filename)) {
        return {
          code: 'filename-already-exists',
          message: 'このファイルはすでにアップロード済みです。'
        }
      }
    }
  }
  const checkDuplicateFilenames = (files) => {
    const checkedFiles = files.reduce((obj, file, idx) => {
      // console.log({ obj, file })
      const { filename } = parseFilenameTags(file.name)
      const arr = obj?.[filename] || []
      arr.push({ file, idx })
      obj[filename] = arr
      return obj
    }, {})
    const removeList = Object.entries(checkedFiles)
      ?.flatMap(([_, arr]) => arr.length > 1 ? arr : [])
      ?.sort((a, b) => (a.idx > b.idx ? -1 : 1))
    // console.log({ files, checkedFiles, removeList })
    return removeList
  }
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    const removeList = checkDuplicateFilenames(acceptedFiles)
    const duplicateErrorMessage = 'アップロードしようとしているファイルの中に重複した名前があります。'
    console.log({ acceptedFiles, fileRejections, removeList })
    for (const { file, idx } of removeList) {
      acceptedFiles.splice(idx, 1)
      fileRejections.push({
        file, errors: [{ code: 'duplicate', message: duplicateErrorMessage }]
      })
    }
    if (acceptedFiles.length === 0) {
      return
    }
    readAllFiles({ layoutable, holderArticle, allFiles: acceptedFiles })
      .then(({ files, articles }) => {
        const formData = new window.FormData()
        formData.append('articles', JSON.stringify(toSnake(articles)))
        formData.append('lock_version', layoutableLockVersion)
        for (const { name, blob } of files) {
          formData.append('files[]', blob, name)
        }
        setFieldValue('formData', formData)
      })
      .catch(e => window.alert(e.message))
  }, [])
  const maxSize = 20 * 1024 * 1024
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    noDragEventsBubbling: true,
    accept: acceptedTypes,
    validator: existingFilesValidator,
    maxSize,
    onDrop
  })
  const dropZoneStyle = {
    textAlign: 'center',
    padding: '20px',
    border: '3px blue dashed',
    width: '60%',
    margin: 'auto'
  }

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Container>
            <Row className='mb-3 align-items-center'>
              <Col style={dropZoneStyle} className='justify-content-center text-center' {...getRootProps({ className: 'dropzone' })}>
                <input id='files' name='files' {...getInputProps()} />
                <p>フォルダをこちらにドラッグするか、クリックしてファイルを複数選択してください。</p>
              </Col>
            </Row>
            {(acceptedFiles.length > 0 || fileRejections.length > 0) &&
              <Row>
                <Col>
                  <aside>
                    {acceptedFiles.length > 0 &&
                      <>
                        <h4>アップロード対象</h4>
                        <ul>{acceptedFileItems}</ul>
                      </>}
                    {fileRejections.length > 0 &&
                      <>
                        <h4>アップロード対象外</h4>
                        <ul>{fileRejectionItems}</ul>
                      </>}
                  </aside>
                </Col>
              </Row>}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={!values.formData || !isValid || acceptedFiles.length === 0}>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}
