import { createSlice } from '@reduxjs/toolkit'
import {
  DataList,
  initialErrorState,
  saveErrorInState,
  dismissError as commonDismissError,
  closeAlert as commonCloseAlert,
  initialAlertState
} from '../common'
import {
  fetchSettings,
  createSetting,
  showSetting,
  modifySetting,
  createSettingDetail,
  modifySettingDetail,
  deleteSettingDetail,
  deleteSetting,
  copySetting,
  exportSetting
} from './settingAPI'
import {
  downloadFulfilled
} from '../page'

const initialState = {
  ...{
    sort: {
      column: 'id',
      order: 'desc'
    },
    setting: null,
    selectedIds: [],
    openedModal: null,
    currentTab: 'columnSetting'
  },
  ...DataList.initialState,
  ...initialErrorState,
  ...initialAlertState
}

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    selectColumn: DataList.selectColumn,
    selectPage: DataList.selectPage,
    selectId: (state, action) => {
      state.selectedIds = Array.from(new Set(state.selectedIds).add(action.payload))
    },
    unselectId: (state, action) => {
      const set = new Set(state.selectedIds)
      set.delete(action.payload)
      state.selectedIds = Array.from(set)
    },
    clearSelectedIds: (state, _action) => {
      state.selectedIds = []
    },
    openModal: (state, action) => {
      state.openedModal = action.payload
    },
    closeModal: (state) => {
      state.openedModal = null
    },
    selectTab: (state, action) => {
      state.currentTab = action.payload
    },
    closeAlert: commonCloseAlert,
    dismissError: commonDismissError
  },
  extraReducers: builder => builder
    .addCase(fetchSettings.pending, DataList.fetchPending)
    .addCase(fetchSettings.fulfilled, DataList.fetchFulfilled)
    .addCase(fetchSettings.rejected, saveErrorInState(DataList.fetchRejected))
    .addCase(createSetting.pending, state => {
      state.fetching = true
    })
    .addCase(createSetting.fulfilled, state => {
      state.fetching = false
      state.openedModal = null
    })
    .addCase(createSetting.rejected, (state, action) => {
      state.fetching = false
      const error = action.payload
      state.alert = {
        show: true,
        variant: 'danger',
        alertBody: error.message
      }
    })
    .addCase(showSetting.pending, state => {
      state.fetching = true
    })
    .addCase(showSetting.fulfilled, (state, action) => {
      state.fetching = false
      state.setting = action.payload
    })
    .addCase(showSetting.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(modifySetting.pending, state => {
      state.fetching = true
    })
    .addCase(modifySetting.fulfilled, state => {
      state.fetching = false
    })
    .addCase(modifySetting.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(deleteSetting.pending, state => {
      state.fetching = true
    })
    .addCase(deleteSetting.fulfilled, state => {
      state.fetching = false
      state.selectedIds = []
    })
    .addCase(deleteSetting.rejected, saveErrorInState(state => {
      state.fetching = false
      state.selectedIds = []
    }))
    .addCase(copySetting.pending, state => {
      state.fetching = true
    })
    .addCase(copySetting.fulfilled, state => {
      state.fetching = false
    })
    .addCase(copySetting.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(exportSetting.pending, state => {
      state.fetching = true
    })
    .addCase(exportSetting.fulfilled, (state, action) => {
      downloadFulfilled(state, action)
      state.fetching = false
    })
    .addCase(exportSetting.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(createSettingDetail.pending, state => {
      state.fetching = true
    })
    .addCase(createSettingDetail.fulfilled, state => {
      state.fetching = false
    })
    .addCase(createSettingDetail.rejected, (state, action) => {
      state.fetching = false
      const error = action.payload
      state.alert = {
        show: true,
        variant: 'danger',
        alertBody: error.message
      }
    })
    .addCase(modifySettingDetail.pending, state => {
      state.fetching = true
    })
    .addCase(modifySettingDetail.fulfilled, state => {
      state.fetching = false
    })
    .addCase(modifySettingDetail.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(deleteSettingDetail.pending, state => {
      state.fetching = true
    })
    .addCase(deleteSettingDetail.fulfilled, state => {
      state.fetching = false
      state.selectedIds = []
    })
    .addCase(deleteSettingDetail.rejected, saveErrorInState(state => {
      state.fetching = false
      state.selectedIds = []
    }))
})

export const selectSetting = state => state.setting
export const selectSettingErrors = state => state.setting.errors

export const {
  selectColumn,
  selectPage,
  selectId,
  unselectId,
  clearSelectedIds,
  openModal,
  closeModal,
  selectTab,
  dismissError
} = settingSlice.actions
