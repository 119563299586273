import React from 'react'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import {
  MarkupNode,
  MarkupNodes,
  MarkupPlugin
} from './TagEditorMarkupPlugin'
import {
  PrepopulatePlugin
} from './TagEditorPrepopulatePlugin'
import {
  ToolbarPlugin,
  DataUIPlugin
} from './TagEditorToolbarPlugin'
import {
  StatusBarPlugin
} from './TagEditorStatusBarPlugin'
import * as styles from './tageditor.module.css'

const theme = {
  placeholder: styles.placeholder,
  paragraph: styles.paragraph,
  text: {
    underline: styles.style_underline
  }
}

export const TagEditor = ({
  initialTaggedContent,
  styleTagType,
  onSubmit,
  editable,
  allowLineBreak,
  allowMultiParagraph,
  setting,
  ...props
}) => {
  const initialConfig = {
    namespace: 'SSCTagEditor',
    nodes: MarkupNodes,
    editable,
    theme,
    onError: (error) => console.log(error)
  }

  const characterStyles = setting.characterStyleSettings.filter(
    s => s.characterStyleSettingType === styleTagType)

  const [statusText, setStatusText] = React.useState('')

  return (
    <>
      <LexicalComposer initialConfig={initialConfig}>
        <ToolbarPlugin characterStyles={characterStyles} {...props} />
        <div className={styles.container}>
          <RichTextPlugin
            contentEditable={<ContentEditable className={styles.input} />}
          />
        </div>
        <HistoryPlugin />
        <StatusBarPlugin text={statusText} />
        <DataUIPlugin onSaveButton={onSubmit} {...props} />
        <MarkupPlugin
          editable={editable}
          allowLineBreak={allowLineBreak}
          allowMultiParagraph={allowMultiParagraph}
          characterStyles={characterStyles}
        />
        <PrepopulatePlugin initialTaggedContent={initialTaggedContent} />
        <NodeEventPlugin
          nodeType={MarkupNode} eventType='mouseenter'
          eventListener={e => {
            setStatusText(e.target.getAttribute('data-markup-style-name'))
          }}
        />
        <NodeEventPlugin
          nodeType={MarkupNode} eventType='mouseleave'
          eventListener={e => {
            setStatusText('')
          }}
        />
      </LexicalComposer>
    </>
  )
}
