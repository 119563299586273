// @generated by Peggy 4.0.3.
//
// https://peggyjs.org/

"use strict";



  const getNumberOfFullChars = (str) => {
    if (!str) {
      return 0
    }
    let chars = 0
    for (let i = 0; i < str.length; i++) {
      const c = str.charCodeAt(i)
      chars += (c >= 0x0020 && c <= 0x1FFF) ? 1 : 2
    }
    return chars / 2
  }

function peg$subclass(child, parent) {
  function C() { this.constructor = child; }
  C.prototype = parent.prototype;
  child.prototype = new C();
}

function peg$SyntaxError(message, expected, found, location) {
  var self = Error.call(this, message);
  // istanbul ignore next Check is a necessary evil to support older environments
  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(self, peg$SyntaxError.prototype);
  }
  self.expected = expected;
  self.found = found;
  self.location = location;
  self.name = "SyntaxError";
  return self;
}

peg$subclass(peg$SyntaxError, Error);

function peg$padEnd(str, targetLength, padString) {
  padString = padString || " ";
  if (str.length > targetLength) { return str; }
  targetLength -= str.length;
  padString += padString.repeat(targetLength);
  return str + padString.slice(0, targetLength);
}

peg$SyntaxError.prototype.format = function(sources) {
  var str = "Error: " + this.message;
  if (this.location) {
    var src = null;
    var k;
    for (k = 0; k < sources.length; k++) {
      if (sources[k].source === this.location.source) {
        src = sources[k].text.split(/\r\n|\n|\r/g);
        break;
      }
    }
    var s = this.location.start;
    var offset_s = (this.location.source && (typeof this.location.source.offset === "function"))
      ? this.location.source.offset(s)
      : s;
    var loc = this.location.source + ":" + offset_s.line + ":" + offset_s.column;
    if (src) {
      var e = this.location.end;
      var filler = peg$padEnd("", offset_s.line.toString().length, ' ');
      var line = src[s.line - 1];
      var last = s.line === e.line ? e.column : line.length + 1;
      var hatLen = (last - s.column) || 1;
      str += "\n --> " + loc + "\n"
          + filler + " |\n"
          + offset_s.line + " | " + line + "\n"
          + filler + " | " + peg$padEnd("", s.column - 1, ' ')
          + peg$padEnd("", hatLen, "^");
    } else {
      str += "\n at " + loc;
    }
  }
  return str;
};

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
    literal: function(expectation) {
      return "\"" + literalEscape(expectation.text) + "\"";
    },

    class: function(expectation) {
      var escapedParts = expectation.parts.map(function(part) {
        return Array.isArray(part)
          ? classEscape(part[0]) + "-" + classEscape(part[1])
          : classEscape(part);
      });

      return "[" + (expectation.inverted ? "^" : "") + escapedParts.join("") + "]";
    },

    any: function() {
      return "any character";
    },

    end: function() {
      return "end of input";
    },

    other: function(expectation) {
      return expectation.description;
    }
  };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/"/g,  "\\\"")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/\]/g, "\\]")
      .replace(/\^/g, "\\^")
      .replace(/-/g,  "\\-")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = expected.map(describeExpectation);
    var i, j;

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== undefined ? options : {};

  var peg$FAILED = {};
  var peg$source = options.grammarSource;

  var peg$startRuleFunctions = { Expression: peg$parseExpression };
  var peg$startRuleFunction = peg$parseExpression;

  var peg$c0 = "\r\n";
  var peg$c1 = "[\u5728\u7248]";
  var peg$c2 = "[\u898B\u51FA\u3057]";
  var peg$c3 = "[\u30A8\u30C8\u30AD]";
  var peg$c4 = "[\u524D\u6587]";
  var peg$c5 = "[\u672C\u6587]";
  var peg$c6 = "[\u51F8]";
  var peg$c7 = "[\u7E26\u51F8]";
  var peg$c8 = "[\u6A2A\u51F8]";
  var peg$c9 = "[\u4E0A\u88AB]";
  var peg$c10 = "[\u4E0B\u88AB]";
  var peg$c11 = "[\u67F1]";
  var peg$c12 = "[\u4E3B]";
  var peg$c13 = "[\u8896]";
  var peg$c14 = "[\u524D\u8107]";

  var peg$r0 = /^[\n\r]/;
  var peg$r1 = /^[^[]/;

  var peg$e0 = peg$anyExpectation();
  var peg$e1 = peg$literalExpectation("\r\n", false);
  var peg$e2 = peg$classExpectation(["\n", "\r"], false, false);
  var peg$e3 = peg$literalExpectation("[\u5728\u7248]", false);
  var peg$e4 = peg$literalExpectation("[\u898B\u51FA\u3057]", false);
  var peg$e5 = peg$literalExpectation("[\u30A8\u30C8\u30AD]", false);
  var peg$e6 = peg$literalExpectation("[\u524D\u6587]", false);
  var peg$e7 = peg$literalExpectation("[\u672C\u6587]", false);
  var peg$e8 = peg$literalExpectation("[\u51F8]", false);
  var peg$e9 = peg$literalExpectation("[\u7E26\u51F8]", false);
  var peg$e10 = peg$literalExpectation("[\u6A2A\u51F8]", false);
  var peg$e11 = peg$literalExpectation("[\u4E0A\u88AB]", false);
  var peg$e12 = peg$literalExpectation("[\u4E0B\u88AB]", false);
  var peg$e13 = peg$literalExpectation("[\u67F1]", false);
  var peg$e14 = peg$literalExpectation("[\u4E3B]", false);
  var peg$e15 = peg$literalExpectation("[\u8896]", false);
  var peg$e16 = peg$literalExpectation("[\u524D\u8107]", false);
  var peg$e17 = peg$classExpectation(["["], true, false);

  var peg$f0 = function(c) { return c };
  var peg$f1 = function(templates) { return { templates } };
  var peg$f2 = function(chars) { return chars };
  var peg$f3 = function(l, n) { return l + (n ?? '') };
  var peg$f4 = function(lines) { return lines.join('').trimEnd() };
  var peg$f5 = function(headings, captions, preamble, body) {
    let obj = { headings, preamble, captions, body }
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v !== null) && (v.length > 0)))
  };
  var peg$f6 = function() { return 'designed' };
  var peg$f7 = function() { return 'designed' };
  var peg$f8 = function() { return 'h_designed' };
  var peg$f9 = function() { return 'top_covering' };
  var peg$f10 = function() { return 'bottom_covering' };
  var peg$f11 = function() { return 'pillar' };
  var peg$f12 = function() { return 'primary' };
  var peg$f13 = function() { return 'secondary' };
  var peg$f14 = function() { return 'preamble' };
  var peg$f15 = function(tag, chars) { return getNumberOfFullChars(chars) <= 64};
  var peg$f16 = function(tag, chars) {
    if (tag === null) {
      return { content: chars }
    } else {
      return { type: tag, content: chars }
    }
  };
  var peg$f17 = function(line) { return line };
  var peg$f18 = function(headings) { return headings };
  var peg$f19 = function(first, rest) { return first + rest };
  var peg$f20 = function(chars) { return chars };
  var peg$f21 = function(line) { return line };
  var peg$f22 = function(captions) { return captions };
  var peg$f23 = function(preamble) { return preamble.trimEnd() };
  var peg$f24 = function(line, newlines) { return line + newlines.join('') };
  var peg$f25 = function(lines) { return lines.join('').trimEnd() };
  var peg$f26 = function(body) { return body };
  var peg$currPos = options.peg$currPos | 0;
  var peg$savedPos = peg$currPos;
  var peg$posDetailsCache = [{ line: 1, column: 1 }];
  var peg$maxFailPos = peg$currPos;
  var peg$maxFailExpected = options.peg$maxFailExpected || [];
  var peg$silentFails = options.peg$silentFails | 0;

  var peg$result;

  if (options.startRule) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function offset() {
    return peg$savedPos;
  }

  function range() {
    return {
      source: peg$source,
      start: peg$savedPos,
      end: peg$currPos
    };
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos];
    var p;

    if (details) {
      return details;
    } else {
      if (pos >= peg$posDetailsCache.length) {
        p = peg$posDetailsCache.length - 1;
      } else {
        p = pos;
        while (!peg$posDetailsCache[--p]) {}
      }

      details = peg$posDetailsCache[p];
      details = {
        line: details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;

      return details;
    }
  }

  function peg$computeLocation(startPos, endPos, offset) {
    var startPosDetails = peg$computePosDetails(startPos);
    var endPosDetails = peg$computePosDetails(endPos);

    var res = {
      source: peg$source,
      start: {
        offset: startPos,
        line: startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line: endPosDetails.line,
        column: endPosDetails.column
      }
    };
    if (offset && peg$source && (typeof peg$source.offset === "function")) {
      res.start = peg$source.offset(res.start);
      res.end = peg$source.offset(res.end);
    }
    return res;
  }

  function peg$fail(expected) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$parseExpression() {
    var s0;

    s0 = peg$parseTemplateSection();
    if (s0 === peg$FAILED) {
      s0 = peg$parseArticle();
    }

    return s0;
  }

  function peg$parsechar() {
    var s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    s2 = peg$parseNEWLINE();
    peg$silentFails--;
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e0); }
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f0(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNEWLINE() {
    var s0;

    if (input.substr(peg$currPos, 2) === peg$c0) {
      s0 = peg$c0;
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e1); }
    }
    if (s0 === peg$FAILED) {
      s0 = input.charAt(peg$currPos);
      if (peg$r0.test(s0)) {
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e2); }
      }
    }

    return s0;
  }

  function peg$parseTemplateSectionSeparator() {
    var s0;

    if (input.substr(peg$currPos, 4) === peg$c1) {
      s0 = peg$c1;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e3); }
    }

    return s0;
  }

  function peg$parseTemplateSection() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseTemplateSectionSeparator();
    if (s1 !== peg$FAILED) {
      s2 = peg$parseNEWLINE();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parseTemplateMultiLines();
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parseTemplateMultiLines();
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f1(s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTemplateLine() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = [];
    s3 = peg$parsechar();
    if (s3 !== peg$FAILED) {
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parsechar();
      }
    } else {
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      s1 = input.substring(s1, peg$currPos);
    } else {
      s1 = s2;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f2(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseTemplateMultiLines() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parseNEWLINE();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseTemplateLine();
      if (s4 !== peg$FAILED) {
        s5 = peg$parseNEWLINE();
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        peg$savedPos = s3;
        s3 = peg$f3(s4, s5);
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parseTemplateLine();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseNEWLINE();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            peg$savedPos = s3;
            s3 = peg$f3(s4, s5);
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f4(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseArticle() {
    var s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parseHeadingSection();
    s2 = peg$parseCaptionSection();
    s3 = peg$parsePreambleSection();
    if (s3 === peg$FAILED) {
      s3 = null;
    }
    s4 = peg$parseBodySection();
    peg$savedPos = s0;
    s0 = peg$f5(s1, s2, s3, s4);

    return s0;
  }

  function peg$parseHeadingSectionSeparator() {
    var s0;

    if (input.substr(peg$currPos, 5) === peg$c2) {
      s0 = peg$c2;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e4); }
    }

    return s0;
  }

  function peg$parseCaptionSectionSeparator() {
    var s0;

    if (input.substr(peg$currPos, 5) === peg$c3) {
      s0 = peg$c3;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e5); }
    }

    return s0;
  }

  function peg$parsePreambleSectionSeparator() {
    var s0;

    if (input.substr(peg$currPos, 4) === peg$c4) {
      s0 = peg$c4;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e6); }
    }

    return s0;
  }

  function peg$parseBodySectionSeparator() {
    var s0;

    if (input.substr(peg$currPos, 4) === peg$c5) {
      s0 = peg$c5;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e7); }
    }

    return s0;
  }

  function peg$parseHeadingTypeTagDesigned() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c6) {
      s1 = peg$c6;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e8); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f6();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseHeadingTypeTagVDesigned() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c7) {
      s1 = peg$c7;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e9); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f7();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseHeadingTypeTagHDesigned() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c8) {
      s1 = peg$c8;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e10); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f8();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseHeadingTypeTagTopCovering() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c9) {
      s1 = peg$c9;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e11); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f9();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseHeadingTypeTagBottomCovering() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c10) {
      s1 = peg$c10;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e12); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f10();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseHeadingTypeTagPillar() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c11) {
      s1 = peg$c11;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e13); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f11();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseHeadingTypeTagPrimary() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c12) {
      s1 = peg$c12;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e14); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f12();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseHeadingTypeTagSecondary() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c13) {
      s1 = peg$c13;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e15); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f13();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseHeadingTypeTagPreamble() {
    var s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4) === peg$c14) {
      s1 = peg$c14;
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$e16); }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f14();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseHeadingTypeTags() {
    var s0;

    s0 = peg$parseHeadingTypeTagDesigned();
    if (s0 === peg$FAILED) {
      s0 = peg$parseHeadingTypeTagVDesigned();
      if (s0 === peg$FAILED) {
        s0 = peg$parseHeadingTypeTagHDesigned();
        if (s0 === peg$FAILED) {
          s0 = peg$parseHeadingTypeTagTopCovering();
          if (s0 === peg$FAILED) {
            s0 = peg$parseHeadingTypeTagBottomCovering();
            if (s0 === peg$FAILED) {
              s0 = peg$parseHeadingTypeTagPillar();
              if (s0 === peg$FAILED) {
                s0 = peg$parseHeadingTypeTagPrimary();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseHeadingTypeTagSecondary();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseHeadingTypeTagPreamble();
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseSectionSeparators() {
    var s0;

    s0 = peg$parseHeadingSectionSeparator();
    if (s0 === peg$FAILED) {
      s0 = peg$parseCaptionSectionSeparator();
      if (s0 === peg$FAILED) {
        s0 = peg$parsePreambleSectionSeparator();
        if (s0 === peg$FAILED) {
          s0 = peg$parseBodySectionSeparator();
        }
      }
    }

    return s0;
  }

  function peg$parseHeadingLine() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parseHeadingTypeTags();
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    s2 = peg$currPos;
    s3 = [];
    s4 = peg$currPos;
    s5 = peg$currPos;
    peg$silentFails++;
    s6 = peg$parseSectionSeparators();
    peg$silentFails--;
    if (s6 === peg$FAILED) {
      s5 = undefined;
    } else {
      peg$currPos = s5;
      s5 = peg$FAILED;
    }
    if (s5 !== peg$FAILED) {
      s6 = peg$parsechar();
      if (s6 !== peg$FAILED) {
        s5 = [s5, s6];
        s4 = s5;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
    } else {
      peg$currPos = s4;
      s4 = peg$FAILED;
    }
    if (s4 !== peg$FAILED) {
      while (s4 !== peg$FAILED) {
        s3.push(s4);
        s4 = peg$currPos;
        s5 = peg$currPos;
        peg$silentFails++;
        s6 = peg$parseSectionSeparators();
        peg$silentFails--;
        if (s6 === peg$FAILED) {
          s5 = undefined;
        } else {
          peg$currPos = s5;
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s6 = peg$parsechar();
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      }
    } else {
      s3 = peg$FAILED;
    }
    if (s3 !== peg$FAILED) {
      s2 = input.substring(s2, peg$currPos);
    } else {
      s2 = s3;
    }
    if (s2 !== peg$FAILED) {
      peg$savedPos = peg$currPos;
      s3 = peg$f15(s1, s2);
      if (s3) {
        s3 = undefined;
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f16(s1, s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseHeadingSection() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseHeadingSectionSeparator();
    if (s2 !== peg$FAILED) {
      s3 = peg$parseNEWLINE();
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    s2 = [];
    s3 = peg$currPos;
    s4 = peg$parseHeadingLine();
    if (s4 !== peg$FAILED) {
      peg$savedPos = s3;
      s4 = peg$f17(s4);
    }
    s3 = s4;
    while (s3 !== peg$FAILED) {
      s2.push(s3);
      s3 = peg$currPos;
      s4 = peg$parseNEWLINE();
      if (s4 !== peg$FAILED) {
        s4 = peg$currPos;
        s5 = peg$parseHeadingLine();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s4;
          s5 = peg$f17(s5);
        }
        s4 = s5;
        if (s4 === peg$FAILED) {
          peg$currPos = s3;
          s3 = peg$FAILED;
        } else {
          s3 = s4;
        }
      } else {
        s3 = s4;
      }
    }
    s3 = [];
    s4 = peg$parseNEWLINE();
    while (s4 !== peg$FAILED) {
      s3.push(s4);
      s4 = peg$parseNEWLINE();
    }
    peg$savedPos = s0;
    s0 = peg$f18(s2);

    return s0;
  }

  function peg$parseLine() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$currPos;
    peg$silentFails++;
    s3 = peg$parseNEWLINE();
    peg$silentFails--;
    if (s3 === peg$FAILED) {
      s2 = undefined;
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      s3 = input.charAt(peg$currPos);
      if (peg$r1.test(s3)) {
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$e17); }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$currPos;
        s5 = [];
        s6 = peg$parsechar();
        while (s6 !== peg$FAILED) {
          s5.push(s6);
          s6 = peg$parsechar();
        }
        s4 = input.substring(s4, peg$currPos);
        peg$savedPos = s1;
        s1 = peg$f19(s3, s4);
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f20(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseCaptionSection() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$parseCaptionSectionSeparator();
    if (s3 !== peg$FAILED) {
      s4 = peg$parseLine();
      if (s4 !== peg$FAILED) {
        s5 = [];
        s6 = peg$parseNEWLINE();
        if (s6 !== peg$FAILED) {
          while (s6 !== peg$FAILED) {
            s5.push(s6);
            s6 = peg$parseNEWLINE();
          }
        } else {
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          peg$savedPos = s2;
          s2 = peg$f21(s4);
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$currPos;
      s3 = peg$parseCaptionSectionSeparator();
      if (s3 !== peg$FAILED) {
        s4 = peg$parseLine();
        if (s4 !== peg$FAILED) {
          s5 = [];
          s6 = peg$parseNEWLINE();
          if (s6 !== peg$FAILED) {
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parseNEWLINE();
            }
          } else {
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            peg$savedPos = s2;
            s2 = peg$f21(s4);
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    }
    peg$savedPos = s0;
    s1 = peg$f22(s1);
    s0 = s1;

    return s0;
  }

  function peg$parsePreambleSection() {
    var s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    s1 = peg$parsePreambleSectionSeparator();
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      s3 = peg$currPos;
      s4 = [];
      s5 = peg$parseLine();
      while (s5 !== peg$FAILED) {
        s4.push(s5);
        s5 = peg$currPos;
        s6 = peg$parseNEWLINE();
        if (s6 !== peg$FAILED) {
          s6 = peg$parseLine();
          if (s6 === peg$FAILED) {
            peg$currPos = s5;
            s5 = peg$FAILED;
          } else {
            s5 = s6;
          }
        } else {
          s5 = s6;
        }
      }
      s5 = peg$parseNEWLINE();
      if (s5 !== peg$FAILED) {
        s4 = [s4, s5];
        s3 = s4;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s2 = input.substring(s2, peg$currPos);
      } else {
        s2 = s3;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f23(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBodyMultiLines() {
    var s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = [];
    s2 = peg$currPos;
    s3 = peg$parseLine();
    if (s3 !== peg$FAILED) {
      s4 = [];
      s5 = peg$parseNEWLINE();
      while (s5 !== peg$FAILED) {
        s4.push(s5);
        s5 = peg$parseNEWLINE();
      }
      peg$savedPos = s2;
      s2 = peg$f24(s3, s4);
    } else {
      peg$currPos = s2;
      s2 = peg$FAILED;
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$currPos;
      s3 = peg$parseLine();
      if (s3 !== peg$FAILED) {
        s4 = [];
        s5 = peg$parseNEWLINE();
        while (s5 !== peg$FAILED) {
          s4.push(s5);
          s5 = peg$parseNEWLINE();
        }
        peg$savedPos = s2;
        s2 = peg$f24(s3, s4);
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
    }
    peg$savedPos = s0;
    s1 = peg$f25(s1);
    s0 = s1;

    return s0;
  }

  function peg$parseBodySection() {
    var s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$currPos;
    s2 = peg$parseBodySectionSeparator();
    if (s2 !== peg$FAILED) {
      s3 = peg$parseNEWLINE();
      if (s3 !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    s2 = peg$parseNEWLINE();
    if (s2 === peg$FAILED) {
      s2 = null;
    }
    s3 = peg$parseBodyMultiLines();
    peg$savedPos = s0;
    s0 = peg$f26(s3);

    return s0;
  }

  peg$result = peg$startRuleFunction();

  if (options.peg$library) {
    return /** @type {any} */ ({
      peg$result,
      peg$currPos,
      peg$FAILED,
      peg$maxFailExpected,
      peg$maxFailPos
    });
  }
  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

module.exports = {
  StartRules: ["Expression"],
  SyntaxError: peg$SyntaxError,
  parse: peg$parse
};
