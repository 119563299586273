import React from 'react'
import { useDispatch } from 'react-redux'
import { camelCase, snakeCase } from 'lodash'
import {
  Button,
  Form,
  InputGroup,
  Row,
  Col
} from 'react-bootstrap'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
import { modifyHeading } from './articleAPI'
import { selectCell } from './articleSlice'
import {
  handleHeading,
  changeHeadingProperties,
  changeHeadingOrientation,
  changeHeadingObjectStyleType,
  normalHeadingTypes,
  coveringHeadingTypes,
  stripTags,
  HeadingType,
  Orientation
} from './ArticleParse'
import { TagEditor } from '../article'
import { CustomForm } from '../common'
Yup.setLocale(ja.suggestive)

const PerHeadingSizeForm = ({
  values,
  errors,
  touched,
  isValid,
  handleChange,
  handleSubmit,
  setFieldValue,
  handleBlur,
  // pageId,
  // layoutableType,
  // layoutableId,
  heading,
  headings,
  layoutable,
  article,
  isSelectedLayoutableWritable
}) => {
  if (heading === undefined) {
    return (<></>)
  }
  const setting = layoutable.setting
  const handleNumberOfOccupyingColumnsChange = (e) => {
    const headingNumberOfOccupyingColumns = e.target.value
    const numberOfOccupyingRows = handleHeading({ headings, headingNumberOfOccupyingColumns, setting })
    console.log({ numberOfOccupyingRows, headingNumberOfOccupyingColumns })
    setFieldValue('numberOfOccupyingRows', numberOfOccupyingRows)
    handleChange(e)
  }
  const changeHandler = heading.headingType === HeadingType.Preamble ? handleNumberOfOccupyingColumnsChange : handleChange
  const inputDisabled = !isSelectedLayoutableWritable || article.layoutLocked
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Row>
        {!coveringHeadingTypes.has(heading.headingType) &&
          <Form.Group as={Col}>
            <InputGroup size='sm' className='align-items-center'>
              <InputGroup.Text>縦</InputGroup.Text>
              <Form.Control
                id='numberOfOccupyingColumnsInput'
                type='number'
                name='numberOfOccupyingColumns'
                value={values.numberOfOccupyingColumns}
                onChange={changeHandler}
                onBlur={handleBlur}
                isInvalid={touched.numberOfOccupyingColumns && errors.numberOfOccupyingColumns}
                disabled={inputDisabled}
              />
              <InputGroup.Text>段</InputGroup.Text>
              <Form.Control.Feedback type='invalid'>
                {errors.numberOfOccupyingColumns}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>}
        {coveringHeadingTypes.has(heading.headingType) &&
          <Form.Group as={Col}>
            <InputGroup size='sm' className='align-items-center' hasValidation>
              <InputGroup.Text>縦</InputGroup.Text>
              <Form.Control
                id='numberOfOccupyingCharactersInput'
                type='number'
                name='numberOfOccupyingCharacters'
                value={values.numberOfOccupyingCharacters || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.numberOfOccupyingCharacters && errors.numberOfOccupyingCharacters}
                disabled={inputDisabled}
              />
              <InputGroup.Text>字</InputGroup.Text>
              <Form.Control.Feedback type='invalid'>
                {errors.numberOfOccupyingCharacters}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>}
        <Form.Group as={Col}>
          <InputGroup size='sm' className='align-items-center' hasValidation>
            <InputGroup.Text>幅</InputGroup.Text>
            <Form.Control
              id='numberOfOccupyingRowsInput'
              type='number'
              name='numberOfOccupyingRows'
              value={values.numberOfOccupyingRows}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.numberOfOccupyingRows && errors.numberOfOccupyingRows}
              disabled={inputDisabled}
            />
            <InputGroup.Text>行幅</InputGroup.Text>
            <Form.Control.Feedback type='invalid'>
              {errors.numberOfOccupyingRows}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Col sm='auto'>
          <Button
            type='submit'
            size='sm'
            disabled={inputDisabled || !isValid || (!touched.numberOfOccupyingRows &&
                                                    !touched.numberOfOccupyingCharacters &&
                                                    !touched.numberOfOccupyingColumns)}
          >
            保存
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
PerHeadingSizeForm.schema = Yup.object().shape({
  numberOfOccupyingCharacters: Yup.number().min(1),
  numberOfOccupyingColumns: Yup.number().min(1),
  numberOfOccupyingRows: Yup.number().min(1)
})

export const ArticleHeadingsForm = ({
  values,
  errors,
  touched,
  isValid,
  handleChange,
  setFieldValue,
  handleBlur,
  handleSubmit,
  enumValues,
  // pageId,
  layoutableType,
  layoutableId,
  article,
  isSelectedLayoutableWritable,
  layoutable,
  unselectCell,
  ...props
}) => {
  const headings = article?.headings
  const dispatch = useDispatch()
  const getHeadingAreaSettingForm = (heading) => {
    const handleHeadingSubmit = ({
      numberOfOccupyingCharacters,
      numberOfOccupyingColumns,
      numberOfOccupyingRows
    }) => {
      const newHeading = {
        ...heading,
        ...{
          numberOfOccupyingCharacters,
          numberOfOccupyingColumns,
          numberOfOccupyingRows
        }
      }
      if (heading.numberOfOccupyingColumns !== newHeading.numberOfOccupyingColumns) {
        // case 1. change columns for non normal headings
        changeHeadingProperties(newHeading, values.headingNumberOfOccupyingColumns, layoutable.setting)
      }
      dispatch(modifyHeading({ layoutableType, layoutableId, heading: newHeading }))
      unselectCell()
    }
    const form = {
      initialValues: {
        numberOfOccupyingCharacters: heading?.numberOfOccupyingCharacters || '',
        numberOfOccupyingColumns: heading?.numberOfOccupyingColumns || '',
        numberOfOccupyingRows: heading?.numberOfOccupyingRows || ''
      },
      validationSchema: PerHeadingSizeForm.schema,
      handleSubmit: handleHeadingSubmit,
      Component: PerHeadingSizeForm,
      props: {
        ...props,
        ...{
          heading,
          headings,
          isSelectedLayoutableWritable,
          layoutable,
          layoutableType,
          layoutableId,
          article
        }
      }
    }
    return (
      <CustomForm form={form} />
    )
  }
  const handleHeadingNumberOfOccupyingColumnsChange = (e) => {
    const headingNumberOfOccupyingColumns = e.target.value
    const setting = layoutable.setting
    const headingNumberOfOccupyingRows = handleHeading({ headings, headingNumberOfOccupyingColumns, setting })
    setFieldValue('headingNumberOfOccupyingRows', headingNumberOfOccupyingRows)
    handleChange(e)
  }
  const normalHeadingAreaFormSubmit = (e) => {
    e.preventDefault()
    if (!touched.headingNumberOfOccupyingColumns) {
      handleSubmit()
      return
    }
    // case 2. change columns for normal
    const headingNumberOfOccupyingColumns = values.headingNumberOfOccupyingColumns
    const setting = layoutable.setting
    const newHeadings = headings?.map(h => {
      const heading = { ...h }
      if (normalHeadingTypes.has(h.headingType) || coveringHeadingTypes.has(h.headingType)) {
        changeHeadingProperties(heading, headingNumberOfOccupyingColumns, setting)
      }
      return heading
    })
    const newArticle = {
      ...values.article,
      headings: newHeadings
    }
    setFieldValue('article', newArticle)
    handleSubmit()
  }
  const inputDisabled = !isSelectedLayoutableWritable || article.layoutLocked
  const headingAreaSettingForm = (
    <Form noValidate onSubmit={normalHeadingAreaFormSubmit}>
      <Row>
        <Form.Group as={Col}>
          <InputGroup size='sm' className='align-items-center' hasValidation>
            <InputGroup.Text>縦</InputGroup.Text>
            <Form.Control
              id='headingColumnsInput'
              type='number'
              name='headingNumberOfOccupyingColumns'
              value={values.headingNumberOfOccupyingColumns}
              onChange={handleHeadingNumberOfOccupyingColumnsChange}
              onBlur={handleBlur}
              isInvalid={touched.headingNumberOfOccupyingColumns && errors.headingNumberOfOccupyingColumns}
              disabled={inputDisabled}
            />
            <InputGroup.Text>段</InputGroup.Text>
            <Form.Control.Feedback type='invalid'>
              {errors.headingNumberOfOccupyingColumns}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col}>
          <InputGroup size='sm' className='align-items-center' hasValidation>
            <InputGroup.Text>幅</InputGroup.Text>
            <Form.Control
              id='headingRowsInput'
              type='number'
              name='headingNumberOfOccupyingRows'
              value={values.headingNumberOfOccupyingRows}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.headingNumberOfOccupyingRows && errors.headingNumberOfOccupyingRows}
              disabled={inputDisabled}
            />
            <InputGroup.Text>行幅</InputGroup.Text>
            <Form.Control.Feedback type='invalid'>
              {errors.headingNumberOfOccupyingRows}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Col sm='auto'>
          <Button
            type='submit'
            disabled={inputDisabled || !isValid || (!touched.headingNumberOfOccupyingRows && !touched.headingNumberOfOccupyingColumns)}
            size='sm'
          >
            保存
          </Button>
        </Col>
      </Row>
    </Form>
  )

  const F = React.Fragment
  const noContentTag = (<Form.Control size='sm' plaintext readOnly defaultValue='設定なし' />)
  const headingBaseData = {
    horizontalDesigned: { tagText: '[横凸]', title: 'カット見出し（横）' },
    verticalDesigned: { tagText: '[縦凸]', title: 'カット見出し（縦）' },
    topCovering: { tagText: '[上被]', title: '上被せ' },
    pillar: { tagText: '[柱]' },
    primary: { tagText: '[主]' },
    secondary: { tagText: '[袖]' },
    bottomCovering: { tagText: '[下被]', title: '下被せ' },
    preamble: { tagText: '[前脇]', title: '前文脇見出し' }
  }
  const designedHeadingTypes = new Set(['horizontalDesigned', 'verticalDesigned'])
  const designedHeadingTypesOrientation = {
    horizontalDesigned: Orientation.Horizontal,
    verticalDesigned: Orientation.Vertical
  }
  const headingTypeOptions = Object.entries(headingBaseData)
    .map(([headingType, { tagText }]) => ({ headingType, tagText }))
  // console.log('headingTypeOptions', headingTypeOptions)
  const getHeadingFromHeadingType = (headingType) => {
    if (designedHeadingTypes.has(headingType)) {
      const orientation = designedHeadingTypesOrientation[headingType]
      return headings?.find((h) => camelCase(h.headingType) === HeadingType.Designed && h.orientation === orientation)
    } else {
      return headings?.find((h) => camelCase(h.headingType) === headingType)
    }
  }
  const modifyHeadingType = (heading, newHeadingType) => {
    const {
      numberOfOccupyingCharacters,
      numberOfOccupyingColumns,
      numberOfOccupyingRows,
      ...restHeading
    } = heading
    const newHeading = {
      ...restHeading,
      numberOfOccupyingCharacters: '',
      numberOfOccupyingColumns: '',
      numberOfOccupyingRows: ''
    }
    if (designedHeadingTypes.has(newHeadingType)) {
      // find orientation
      newHeading.headingType = HeadingType.Designed
      newHeading.orientation = designedHeadingTypesOrientation[newHeadingType]
    } else {
      newHeading.headingType = snakeCase(newHeadingType)
      if (normalHeadingTypes.has(newHeading.headingType)) {
        const basicNormalHeadings = headings?.filter(h => normalHeadingTypes.has(h.headingType))
        const firstHeading = basicNormalHeadings?.[0]
        newHeading.orientation = firstHeading.orientation || Orientation.Vertical
      }
    }
    // console.log('newHeading', { newHeading })
    if (heading.headingType !== newHeading.headingType) {
      // case 3 change heading type
      changeHeadingProperties(newHeading, values.headingNumberOfOccupyingColumns, layoutable.setting)
    }
    dispatch(modifyHeading({ layoutableType, layoutableId, heading: newHeading }))
  }
  const getContent = (val, heading) => {
    const { tagText } = val
    const onHeadingContentSubmit = (taggedContent) => {
      const content = stripTags(taggedContent)
      const newHeading = {
        ...heading,
        content,
        taggedContent
      }
      dispatch(modifyHeading({ layoutableType, layoutableId, heading: newHeading }))
      unselectCell()
    }
    const onHeadingTypeChange = (e) => {
      const moveHeadingType = e.target.value
      const currHeadingType = val.headingType
      const moveHeading = getHeadingFromHeadingType(moveHeadingType)
      // console.log('onHeadingTypeChange: headingType', { currHeading: heading, moveHeading, currHeadingType, moveHeadingType })
      modifyHeadingType(heading, moveHeadingType)
      if (moveHeading !== undefined) {
        modifyHeadingType(moveHeading, currHeadingType)
        dispatch(selectCell(null))
      }
    }
    const headingTypeInput = heading === undefined
      ? (<Form.Control size='sm' plaintext readOnly defaultValue={tagText} />)
      : (
        <Form.Select
          as='select'
          type='text'
          name='headingType'
          value={val.headingType}
          onChange={onHeadingTypeChange}
          size='sm'
          disabled={inputDisabled}
        >
          {headingTypeOptions.map(({ headingType, tagText }) => (
            <option key={headingType} value={headingType}>{tagText}</option>
          ))}
        </Form.Select>
        )
    const characterTagEditInput = (heading === undefined || heading?.taggedContent === undefined)
      ? noContentTag
      : (
        <TagEditor
          initialTaggedContent={heading.taggedContent}
          styleTagType='heading'
          setting={layoutable.setting}
          onSubmit={onHeadingContentSubmit}
          disabled={inputDisabled}
          editable={!inputDisabled}
          // TODO: allow heading text edit after layout lock in future
          // disabled={!isSelectedLayoutableWritable}
          allowLineBreak
          allowMultiParagraph={false}
        />
        )
    return (
      <Row className='align-items-center'>
        <Col sm={3}>
          {headingTypeInput}
        </Col>
        <Col>
          {characterTagEditInput}
        </Col>
      </Row>
    )
  }
  const headingData = Object.entries(headingBaseData).reduce((acc, item) => {
    const [headingType, val] = item
    val.headingType = headingType
    if (designedHeadingTypes.has(headingType)) {
      const orientation = designedHeadingTypesOrientation[headingType]
      const heading = headings?.find((h) => camelCase(h.headingType) === HeadingType.Designed && h.orientation === orientation)
      // console.log('foo', heading)
      val.heading = heading
      val.content = getContent(val, heading)
    } else {
      const heading = headings?.find((h) => camelCase(h.headingType) === headingType)
      val.heading = heading
      val.content = getContent(val, heading)
    }
    if (val !== undefined) {
      acc[headingType] = val
    }
    return acc
  }, {})

  const designedHeadingsSettings = (
    <>
      <Row>
        <Col className='fs-6 fw-bold' sm='auto'>
          {headingData.horizontalDesigned.title}
        </Col>
        <Col>
          {getHeadingAreaSettingForm(headingData.horizontalDesigned.heading)}
        </Col>
      </Row>
      {headingData.horizontalDesigned.content}
      <Row>
        <Col className='fs-6 fw-bold' sm='auto'>
          {headingData.verticalDesigned.title}
        </Col>
        <Col>
          {getHeadingAreaSettingForm(headingData.verticalDesigned.heading)}
        </Col>
      </Row>
      {headingData.verticalDesigned.content}
    </>
  )
  const orientationForm = () => {
    const basicNormalHeadings = headings?.filter(h => normalHeadingTypes.has(h.headingType))
    const heading = basicNormalHeadings?.[0]
    if (heading === undefined) {
      return (<></>)
    }
    const onOrientationChange = (e) => {
      const newOrientation = e.target.value
      const {
        headingNumberOfOccupyingColumns,
        headingNumberOfOccupyingRows,
        headingObjectStyleType
      } = changeHeadingOrientation({ article, basicNormalHeadings, newOrientation, setting: layoutable.setting })
      console.log('onOrientationChange', { newOrientation, headingObjectStyleType })
      setFieldValue('headingNumberOfOccupyingColumns', headingNumberOfOccupyingColumns)
      setFieldValue('headingNumberOfOccupyingRows', headingNumberOfOccupyingRows)
      setFieldValue('headingObjectStyleType', headingObjectStyleType)
      // update Article
      if (values.article) {
        const newArticle = {
          ...values.article,
          headingNumberOfOccupyingColumns,
          headingNumberOfOccupyingRows
        }
        const newHeadings = newArticle?.headings?.map((h) => {
          const heading = { ...h }
          if (normalHeadingTypes.has(h.headingType)) {
            heading.headingObjectStyleType = headingObjectStyleType
            heading.orientation = newOrientation
            changeHeadingProperties(heading, headingNumberOfOccupyingColumns, layoutable.setting)
          }
          return heading
        })

        newArticle.headings = newHeadings
        setFieldValue('article', newArticle)
        const {
          headingObjectStyleType: styleType,
          ...restValues
        } = values
        handleSubmit({ ...restValues, keepOpen: true })
      }
    }
    const isHorizontal = heading.orientation === Orientation.Horizontal
    return (
      <Row>
        <Col sm='auto'>
          {Object.keys(enumValues.headingOrientations).map(k => (
            <Form.Check
              key={`heading-${heading.id}-${k}`}
              inline
              label={`${enumValues.headingOrientations[k]}見出し`}
              type='radio'
              // name={`heading-${heading.id}-orientation`}
              name='orientation'
              checked={k === heading.orientation}
              value={k}
              onChange={onOrientationChange}
              disabled={inputDisabled}
            />
          ))}
        </Col>
        {isHorizontal &&
          <Col>
            <Form.Control
              as='select'
              type='text'
              name='headingObjectStyleType'
              size='sm'
              value={values.headingObjectStyleType}
              onChange={(e) => {
                const headingObjectStyleType = e.target.value
                console.log('onChange', { headingObjectStyleType })
                const headingNumberOfOccupyingRows = changeHeadingObjectStyleType({ headingObjectStyleType, setting: layoutable.setting })
                const headingNumberOfOccupyingColumns = 1
                setFieldValue('headingNumberOfOccupyingColumns', headingNumberOfOccupyingColumns)
                setFieldValue('headingNumberOfOccupyingRows', headingNumberOfOccupyingRows)
                if (values.article) {
                  const newArticle = {
                    ...values.article,
                    headingNumberOfOccupyingColumns,
                    headingNumberOfOccupyingRows
                  }
                  const newHeadings = newArticle?.headings?.map((h) => {
                    const heading = { ...h }
                    if (normalHeadingTypes.has(h.headingType)) {
                      heading.headingObjectStyleType = headingObjectStyleType
                      changeHeadingProperties(heading, headingNumberOfOccupyingColumns, layoutable.setting)
                    }
                    return heading
                  })

                  newArticle.headings = newHeadings
                  setFieldValue('article', newArticle)
                  const {
                    headingObjectStyleType: styleType,
                    ...restValues
                  } = values
                  handleSubmit({ ...restValues, keepOpen: true })
                }
                handleChange(e)
              }}
              onBlur={handleBlur}
              isInvalid={touched.headingObjectStyleType && errors.headingObjectStyleType}
            >
              {Object.keys(enumValues.horizontalHeadingObjectStyleTypes).map(k => (
                <option key={`headingObjectStyleType-option-${k}`} value={snakeCase(k)}>{enumValues.horizontalHeadingObjectStyleTypes[k]}</option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>
              {errors.parentId}
            </Form.Control.Feedback>
          </Col>}
      </Row>
    )
  }
  const normalHeadingsSettings = (
    <>
      {orientationForm()}
      <Row className='align-items-center'>
        <Col className='fs-6 fw-bold' sm='auto'>
          {headingData.topCovering.title}
        </Col>
        <Col>
          {getHeadingAreaSettingForm(headingData.topCovering.heading)}
        </Col>
      </Row>
      {headingData.topCovering.content}
      <Row className='align-items-center'>
        <Col className='fs-6 fw-bold' sm='auto'>
          見出し
        </Col>
        <Col>
          {headingAreaSettingForm}
        </Col>
      </Row>
      {Array.from(normalHeadingTypes).map((headingType) => (
        <F key={headingType}>
          {headingData[headingType].content}
          <hr />
        </F>
      ))}
      <Row className='align-items-center'>
        <Col className='fs-6 fw-bold' sm='auto'>
          {headingData.bottomCovering.title}
        </Col>
        <Col>
          {getHeadingAreaSettingForm(headingData.bottomCovering.heading)}
        </Col>
      </Row>
      {headingData.bottomCovering.content}
    </>
  )
  const preambleHeadingsSettings = (
    <>
      <Row className='align-items-center'>
        <Col className='fs-6 fw-bold' sm='auto'>
          {headingData.preamble.title}
        </Col>
        <Col>
          {getHeadingAreaSettingForm(headingData.preamble.heading)}
        </Col>
      </Row>
      {headingData.preamble.content}
    </>
  )
  // console.log(headings)
  return (
    <>
      {designedHeadingsSettings}
      <hr className='border border-primary' />
      {normalHeadingsSettings}
      <hr className='border border-primary' />
      {preambleHeadingsSettings}
    </>
  )
}

ArticleHeadingsForm.schema = Yup.object().shape({
  // pageId: Yup.number().required(),
  headingNumberOfOccupyingColumns: Yup.number().required().min(1),
  headingNumberOfOccupyingRows: Yup.number().required().min(1),
  headingObjectStyleType: Yup.string().nullable(true)
})
