import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import {
  createFetchAsyncThunk,
  csrfToken,
  toSnake,
  throwErrorForErrorResponse,
  throwTextErrorForErrorResponse,
  errorHandler
} from '../common'
import { closeModal } from './settingSlice'

export const fetchSettings = createFetchAsyncThunk(
  'setting/fetchSettings',
  'settings'
)

export const createSetting = createAsyncThunk(
  'setting/createSetting',
  async (params, { getState, rejectWithValue }) => {
    const { baseUri } = getState().common
    const { navigate, formData } = params
    delete params.navigate

    return fetch(
      `${baseUri}settings`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: formData
      })
      .then(throwErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .then(payload => {
        const { settingId } = payload
        // console.log('settingId', settingId)
        navigate(`${baseUri}settings/${settingId}`)
        return Promise.resolve({})
      })
      .catch(errorHandler(rejectWithValue))
  }
)

export const showSetting = createAsyncThunk(
  'setting/showSetting',
  async (params, { getState, rejectWithValue }) => {
    const { baseUri } = getState().common
    const { settingId } = params

    return fetch(
      `${baseUri}settings/${settingId}`, {
        headers: {
          Accept: 'application/json'
        }
      })
      .then(throwTextErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
  }
)

export const modifySetting = createAsyncThunk(
  'setting/modifySetting',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { settingId, formData } = params

    return fetch(
      `${baseUri}settings/${settingId}`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: formData
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().setting
        dispatch(fetchSettings({ page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => {
        dispatch(closeModal())
      })
  }
)

export const deleteSetting = createAsyncThunk(
  'setting/deleteSetting',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { id } = params

    return fetch(
      `${baseUri}settings/${id}`, {
        method: 'delete',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().setting
        dispatch(fetchSettings({ page: currentPage, sort }))
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
      .finally(() => {
        dispatch(closeModal())
      })
  }
)

export const copySetting = createAsyncThunk(
  'setting/copySetting',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { id } = params
    const { navigate } = params
    delete params.navigate

    return fetch(
      `${baseUri}settings/${id}/copy`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .then(payload => {
        const { settingId } = payload
        navigate(`${baseUri}settings/${settingId}`)
        return Promise.resolve({})
      })
      .catch(errorHandler(rejectWithValue))
      .finally(() => {
        dispatch(closeModal())
      })
  }
)

export const exportSetting = createAsyncThunk(
  'setting/exportSetting',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { id } = params
    delete params.navigate

    return fetch(`${baseUri}settings/${id}/export.zip`)
      .then(throwErrorForErrorResponse)
      .then(response => response.blob())
      .catch(errorHandler(rejectWithValue))
      .finally(() => {
        dispatch(closeModal())
      })
  }
)
export const modifySettingDetail = createAsyncThunk(
  'setting/modifySettingDetail',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { id, settingId, settingName } = params
    return fetch(
      `${baseUri}settings/${settingId}/${settingName}/${id}`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        dispatch(showSetting({ settingId }))
        return Promise.resolve({})
      })
      .catch(errorHandler(rejectWithValue)).finally(() => {
        dispatch(closeModal())
      })
  }
)

export const createSettingDetail = createAsyncThunk(
  'setting/createSettingDetail',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { settingId, settingName } = params
    return fetch(
      `${baseUri}settings/${settingId}/${settingName}`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        dispatch(closeModal())
        dispatch(showSetting({ settingId }))
        return Promise.resolve({})
      })
      .catch(errorHandler(rejectWithValue))
  }
)

export const deleteSettingDetail = createAsyncThunk(
  'setting/deleteSettingDetail',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { settingId, settingName, id } = params
    return fetch(
      `${baseUri}settings/${settingId}/${settingName}/${id}`, {
        method: 'delete',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        dispatch(showSetting({ settingId }))
        return Promise.resolve({})
      })
      .catch(errorHandler(rejectWithValue))
      .finally(() => {
        dispatch(closeModal())
      })
  }
)
