import React from 'react'
import _ from 'lodash'
import { Pagination } from './Pagination'
import { Table, Header } from './Table'

export const DataList = ({
  data,
  columns,
  onColumnClick,
  handleCellClick,
  sort,
  currentPage,
  totalPages,
  onPaginationClick,
  className
}) => {
  const defaultClassName = 'my-2'
  const convertColumn = column => _.merge(column, {
    header: column.sortKey
      ? () => <Header
          name={column.name}
          ascending={sort.order === 'asc'}
          selected={sort.column === column.sortKey}
          onClick={onColumnClick(column.sortKey)}
              />
      : column.name
  })
  return (
    <div className={className || defaultClassName}>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPaginationClick={onPaginationClick}
      />
      <Table columns={columns.map(convertColumn)} data={data} handleCellClick={handleCellClick} />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPaginationClick={onPaginationClick}
      />
    </div>
  )
}

DataList.initialState = {
  data: [],
  currentPage: 1,
  totalPages: 1,
  totalCount: 0,
  fetching: false
}

DataList.fetchPending = state => {
  state.fetching = true
}

DataList.fetchFulfilled = (state, action) => {
  state.fetching = false
  const { totalPages, totalCount, list } = action.payload
  state.totalPages = totalPages
  state.totalCount = totalCount
  state.data = list
}

DataList.fetchRejected = (state, _action) => {
  state.fetching = false
}

DataList.selectColumn = (state, action) => {
  const clickedColumn = action.payload
  const { column, order } = state.sort

  state.sort.column = clickedColumn
  state.sort.order = (column === clickedColumn && order === 'desc') ? 'asc' : 'desc'
  state.currentPage = 1
}

DataList.selectPage = (state, action) => {
  state.currentPage = action.payload
}
