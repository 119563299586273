import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  DataList,
  initialErrorState,
  saveErrorInState,
  dismissError as commonDismissError
} from '../common'
import {
  fetchUsers,
  modifyUsers,
  modifyUser
} from './userAPI'

const initialState = _.merge(
  {
    sort: {
      column: 'id',
      order: 'desc'
    },
    selectedIds: [],
    searchCondition: {
      includeDisabled: false
    },
    openedModal: null
  },
  DataList.initialState,
  initialErrorState
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    selectColumn: DataList.selectColumn,
    selectPage: DataList.selectPage,
    selectId: (state, action) => {
      state.selectedIds = Array.from(new Set(state.selectedIds).add(action.payload))
    },
    unselectId: (state, action) => {
      const set = new Set(state.selectedIds)
      set.delete(action.payload)
      state.selectedIds = Array.from(set)
    },
    openModal: (state, action) => {
      state.openedModal = action.payload
    },
    closeModal: (state) => {
      state.openedModal = null
    },
    setSearchCondition: (state, action) => {
      const { name, value } = action.payload
      state.searchCondition[name] = value
    },
    dismissError: commonDismissError
  },
  extraReducers: builder => builder
    .addCase(fetchUsers.pending, DataList.fetchPending)
    .addCase(fetchUsers.fulfilled, DataList.fetchFulfilled)
    .addCase(fetchUsers.rejected, saveErrorInState(DataList.fetchRejected))
    .addCase(modifyUsers.pending, state => {
      state.fetching = true
    })
    .addCase(modifyUsers.fulfilled, state => {
      state.fetching = false
      state.selectedIds = []
    })
    .addCase(modifyUsers.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(modifyUser.pending, state => {
      state.fetching = true
    })
    .addCase(modifyUser.fulfilled, state => {
      state.fetching = false
    })
    .addCase(modifyUser.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
})

export const selectUser = state => state.user

export const {
  selectColumn,
  selectPage,
  selectId,
  unselectId,
  openModal,
  closeModal,
  setSearchCondition,
  dismissError
} = userSlice.actions
