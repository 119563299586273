import React from 'react'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal
} from 'react-bootstrap'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ConfirmationForm,
  CustomForm
} from '../form'

export const CustomButton = ({
  name,
  button
}) => (
  <Button
    key={name}
    className={name}
    variant={button.variant || 'outline-primary'}
    onClick={button.handleClick}
    disabled={button.disabled}
    hidden={button.hidden}
  >
    <FontAwesomeIcon icon={button.icon} fixedWidth />
    {button.text}
  </Button>
)

export const ConfirmationModal = ({
  form,
  name,
  size,
  openedModal
}) => {
  form.Component = ConfirmationForm
  return (
    <Modal
      key={name}
      size={size || 'lg'}
      centered
      show={openedModal === name}
    >
      <CustomForm form={form} />
    </Modal>
  )
}

export const CustomModal = ({
  form,
  name,
  size,
  openedModal
}) => (
  <Modal
    key={name}
    size={size || 'lg'}
    centered
    show={openedModal === name}
  >
    <CustomForm form={form} />
  </Modal>
)

export const ButtonModals = ({
  settings,
  openedModal
}) => (
  <>
    <ButtonToolbar>
      <ButtonGroup size='sm'>
        {_.map(settings, ({ name, button }) => (
          <CustomButton key={name} name={name} button={button} />
        ))}
      </ButtonGroup>
    </ButtonToolbar>
    {_.map(settings, ({ name, form }) => (
      <CustomModal key={name} {...{ openedModal, name, form }} />
    ))}
  </>
)
