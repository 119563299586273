import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import { HomeScreen } from './features/home'
import { Layout, NotFound } from './features/common'
import {
  ArticleList
} from './features/article'
import {
  PageList
} from './features/page'
import {
  LayoutPreviewScreen
} from './features/layout'
import {
  SubLayoutList
} from './features/subLayout'
import { GroupList } from './features/group'
import { UserList } from './features/user'
import {
  MediaList,
  MediaEngineStats,
  SettingList,
  SettingConfig
} from './features/media'
import { AuthDropdown } from './features/auth'

// <Route path='pages/:pageId' element={<PageConfig />} />
const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Layout><AuthDropdown /></Layout>}>
        <Route index element={<HomeScreen />} />
        <Route path='pages' element={<PageList />} />
        <Route path='pages/:pageId/articles' element={<ArticleList />} />
        <Route path='pages/:pageId/layout' element={<LayoutPreviewScreen />} />
        <Route path='sub_layouts' element={<SubLayoutList />} />
        <Route path='sub_layouts/:subLayoutId/articles' element={<ArticleList layoutableType='SubLayout' />} />
        <Route path='sub_layouts/:subLayoutId/layout' element={<LayoutPreviewScreen layoutableType='SubLayout' />} />
        <Route path='groups' element={<GroupList />} />
        <Route path='users' element={<UserList />} />
        <Route path='media' element={<MediaList />} />
        <Route path='media/:mediaId/engineStats' element={<MediaEngineStats />} />
        <Route path='settings' element={<SettingList />} />
        <Route path='settings/:settingId' element={<SettingConfig />} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
)

export default App
