import { createSlice } from '@reduxjs/toolkit'
import {
  initialErrorState,
  saveErrorInState,
  dismissError as commonDismissError,
  closeAlert as commonCloseAlert,
  initialAlertState
} from '../common'
import {
  createPage,
  createSubLayout,
  fetchMessage
} from './homeAPI'

const initialState = {
  ...{
    message: '',
    fetching: false,
    openedModal: null
  },
  ...initialErrorState,
  ...initialAlertState
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.openedModal = action.payload
    },
    closeModal: (state) => {
      state.openedModal = null
      // clear alert state
      state.alert = null
    },
    closeAlert: commonCloseAlert,
    dismissError: commonDismissError
  },
  extraReducers: builder => builder
    .addCase(fetchMessage.pending, (state, action) => {
      state.fetching = true
    })
    .addCase(fetchMessage.fulfilled, (state, action) => {
      state.fetching = false
      state.message = action.payload.message
    })
    .addCase(fetchMessage.rejected, saveErrorInState(state => {
      state.fetching = false
    }))
    .addCase(createPage.pending, state => {
      state.fetching = true
    })
    .addCase(createPage.fulfilled, state => {
      state.fetching = false
    })
    .addCase(createPage.rejected, (state, action) => {
      state.fetching = false
      const error = action.payload
      state.alert = {
        show: true,
        variant: 'danger',
        alertBody: error.message
      }
    })
    .addCase(createSubLayout.pending, state => {
      state.fetching = true
    })
    .addCase(createSubLayout.fulfilled, state => {
      state.fetching = false
    })
    .addCase(createSubLayout.rejected, (state, action) => {
      state.fetching = false
      const error = action.payload
      state.alert = {
        show: true,
        variant: 'danger',
        alertBody: error.message
      }
    })
})

export const selectHome = state => state.home

export const {
  openModal,
  closeModal,
  closeAlert,
  dismissError
} = homeSlice.actions
