import React from 'react'
import { Formik } from 'formik'

export const CustomForm = ({
  form
}) => (
  form.initialValues
    ? (
      <Formik
        initialValues={
               typeof form.initialValues === 'function'
                 ? form.initialValues()
                 : form.initialValues
              }
        onSubmit={form.handleSubmit}
        validationSchema={form.validationSchema}
      >
        {props => <form.Component
          {...props}
          {...form.props}
                  />}
      </Formik>)
    : (
      <form.Component
        {...form.props}
        onSubmit={form.handleSubmit}
      />
      )
)
