import React from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import {
  Button,
  Table as BSTable
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const arrowUpIcon = <FontAwesomeIcon icon='chevron-up' fixedWidth />
const arrowDownIcon = <FontAwesomeIcon icon='chevron-down' fixedWidth />

export const Header = ({
  onClick,
  name,
  selected,
  ascending
}) => {
  return (
    <Button onClick={onClick} variant='link'>
      {name}
      {selected && (ascending ? arrowUpIcon : arrowDownIcon)}
    </Button>
  )
}

export const Table = ({ columns, data, handleCellClick }) => {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <>
      <BSTable responsive striped bordered hover size='sm'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className='align-middle'>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className='fetched-entry align-middle'>
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  {...(handleCellClick && { onClick: () => handleCellClick(row, cell) })}
                  {...cell.column.columnDef.meta?.cellProps}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </BSTable>
    </>
  )
}
