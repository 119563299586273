/* eslint-env browser */
import React from 'react'
import {
  Button,
  InputGroup,
  Stack
} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

import {
  $generateHtmlFromNodes
} from '@lexical/html'
import {
  useLexicalComposerContext
} from '@lexical/react/LexicalComposerContext'

import * as styles from './tageditor.module.css'
import {
  TOGGLE_MARKUP_COMMAND,
  RESET_MARKUP_COMMAND
} from './TagEditorMarkupPlugin'

export const ToolbarPlugin = ({ characterStyles, disabled, ...props }) => {
  const [editor] = useLexicalComposerContext()
  const [activeEditor] = React.useState(editor)
  const [activeStyle, setActiveStyle] = React.useState('')

  const onSelectChanged = (e) => {
    setActiveStyle(e.target.value)
  }
  const applyEnabled = characterStyles.length > 0
  const styleOptions = characterStyles.map((s, index) =>
    <option value={s.name} key={index}>{s.description}</option>
  )

  return (
    <div className={styles.toolbar}>
      <InputGroup size='sm'>
        <Form.Select onChange={onSelectChanged} disabled={disabled}>
          <option value=''>なし</option>
          {styleOptions}
        </Form.Select>
        <Button
          className={styles.toolbar_item}
          disabled={disabled || !applyEnabled || activeStyle === ''}
          onClick={() => {
            activeEditor.dispatchCommand(TOGGLE_MARKUP_COMMAND, { name: 'c', style: activeStyle })
          }}
        >
          選択範囲に適用
        </Button>
      </InputGroup>
    </div>
  )
}

export const DataUIPlugin = ({
  disabled,
  ...props
}) => {
  const [editor] = useLexicalComposerContext()
  const [activeEditor] = React.useState(editor)

  const onSaveButton = (e) => {
    if (props.onSaveButton === null) {
      return
    }

    editor.getEditorState().read(() => {
      const htmlString = $generateHtmlFromNodes(editor, null)
      const domparser = new DOMParser()
      const doc = domparser.parseFromString(htmlString, 'text/html')
      const body = doc.querySelector('body')
      const spans = body.querySelectorAll('span[data-tageditor-text="true"]')
      spans.forEach((s) => {
        while (s.firstChild) {
          s.parentNode.insertBefore(s.firstChild, s)
        }
        s.parentNode.removeChild(s)
      })

      const resultXmlString = body.innerHTML.replaceAll('<br>', '<sr />')
      props.onSaveButton(resultXmlString)
    })
  }

  return (
    <Stack direction='horizontal' className={styles.toolbar}>
      <div className='ms-auto'>
        <InputGroup size='sm'>
          <Button
            variant='secondary'
            onClick={
              () => { activeEditor.dispatchCommand(RESET_MARKUP_COMMAND, {}) }
            }
            disabled={disabled}
          >
            スタイル全消去
          </Button>
          <Button
            onClick={onSaveButton}
            disabled={disabled}
          >保存
          </Button>
        </InputGroup>
      </div>
    </Stack>
  )
}
