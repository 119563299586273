export const HeadingType = Object.freeze({
  Primary: 'primary',
  Secondary: 'secondary',
  Pillar: 'pillar',
  TopCovering: 'top_covering',
  BottomCovering: 'bottom_covering',
  Preamble: 'preamble',
  Designed: 'designed'
})

export const Orientation = Object.freeze({
  Horizontal: 'horizontal',
  Vertical: 'vertical'
})

export const ArticleType = Object.freeze({
  Flow: 'flow',
  Anchor: 'anchor',
  Fold: 'fold',
  Area: 'area',
  Template: 'template',
  Holder: 'holder'
})

export const Priority = Object.freeze({
  Head: 'head',
  Shoulder: 'shoulder',
  Neck: 'neck',
  Navel: 'navel',
  RightFoot: 'right_foot',
  LeftFoot: 'left_foot',
  Other: 'other'
})

export const RelationType = Object.freeze({
  Same: 'same',
  Related: 'related',
  Grouped: 'grouped'
})

export const DividerLineMode = Object.freeze({
  None: 'none',
  Left: 'left',
  Right: 'right',
  Both: 'both'
})

export const Status = Object.freeze({
  LayoutInProgress: 'layout_in_progress',
  LayoutEngineWaiting: 'layout_engine_waiting',
  LayoutEngineExecuting: 'layout_engine_executing',
  LayoutEngineComplete: 'layout_engine_complete',
  LayoutEngineError: 'layout_engine_error',
  LayoutComplete: 'layout_complete'
})
