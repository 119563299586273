import React from 'react'
import {
  Button,
  Col,
  Form,
  Modal,
  Row
} from 'react-bootstrap'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

export const SettingCopyForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  // onSubmit,
  fetching,
  title,
  submitButtonText,
  onClickCancel,
  page
}) => (
  <>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Form noValidate onSubmit={handleSubmit}>
      <Modal.Body>
        <p>以下の紙面ベースを複製します。よろしいですか？</p>
        <p>コピー元紙面ベース名称：{values.name}</p>
        <Row>
          <Form.Group as={Col}>
            <Form.Label column>コピー後紙面ベース名称</Form.Label>
            <Form.Control
              id='newNameInput'
              name='newName'
              value={values.newName}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.newName && errors.newName}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.newName}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClickCancel}>キャンセル</Button>
        <Button type='submit' disabled={fetching}>{submitButtonText} </Button>
      </Modal.Footer>
    </Form>
  </>
)

SettingCopyForm.schema = Yup.object().shape({
  name: Yup.string().required(),
  newName: Yup.string().required()
})
