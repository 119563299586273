import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
import { camelCase, snakeCase } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { createColumnHelper } from '@tanstack/react-table'
import {
  Alert,
  Badge,
  Button,
  CloseButton,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  ListGroup,
  Stack,
  Spinner
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  getColumnString,
  selectCommon,
  toSnake,
  useInterval,
  ButtonModals,
  Checkbox,
  CustomModal,
  ConfirmationModal,
  ConfirmationForm,
  DataList,
  DismissibleAlert,
  ErrorModal,
  OverlayLink
} from '../common'
import validateLayoutable, { isValidArea, figureSchema } from './validateLayoutable'
import { selectAuth } from '../auth'
import {
  bulkCreateArticles,
  createArticle,
  deleteArticles,
  deleteFigure,
  fetchArticles,
  modifyArticle,
  modifyPage,
  startLayout,
  switchArticleOrders,
  getLayoutableBaseUrl,
  fetchLayoutableStatus,
  modifyLayoutLocked
} from './articleAPI'
import {
  closeModal,
  openModal,
  selectId,
  selectCell,
  selectTargetArticle,
  selectArticle,
  unselectId,
  closeAlert,
  dismissError
} from './articleSlice'
import {
  ArticleType,
  ArticleUpsertForm,
  ArticleDetail,
  ArticleViewForm,
  ArticlesDeleteForm,
  ArticleBulkUploadForm,
  ArticleImagesAddForm,
  DividerLineMode,
  getDefaultDividerLineMode,
  isAreaArticleType,
  isSizedArticleType,
  isTopPositionPriority,
  sizeCheckRequiredHeadingTypes,
  nullish,
  isDisabledArticleType,
  coerceArticleTypeOnPriorityChange,
  isLayoutEngineInProgressStatus,
  isLayoutEngineExecutableStatus,
  isLayoutEditableStatus,
  isLayoutViewableStatus,
  Priority
} from '../article'
import {
  getHandleEditPageSubmit,
  PageUpsertForm,
  StartLayoutForm
} from '../page'
import {
  selectUser,
  fetchUsers
} from '../user'
import {
  fetchMedia,
  selectMedia,
  fetchSettings,
  selectSetting
} from '../media'
import moment from 'moment'
Yup.setLocale(ja.suggestive)
moment.locale('ja')

const LayoutValidationErrorsAlert = ({
  closeValidationErrors,
  errors
}) => {
  if (errors.length > 0) {
    const title = '割り付け実行前に以下の確認をしてください'
    const alertBody = (
      <ol>
        {errors.map(({ message }, idx) => (<li key={`layout-validation-error-${idx}`}>{message}</li>))}
      </ol>
    )
    const alert = {
      show: true,
      title,
      variant: 'warning',
      alertBody
    }
    return (<DismissibleAlert className='layout-validation-errors-alert' alert={alert} onClose={closeValidationErrors} />)
  }
}

const ArticleListHeader = ({
  layoutableType,
  layoutable,
  ...props
}) => {
  // console.log('props', props)
  switch (layoutableType) {
    case 'Page': {
      const page = layoutable
      return (
        <Row className='align-items-center'>
          <Col className='fs-3 mr-auto'>
            <Stack direction='horizontal' gap={3}>
              <div className='layoutableType pageName'>紙面名称: {page?.name}</div>
              {/* <div className='bg-light border'>{enumValues.pageTypes[camelCase(page?.pageType)]}</div> */}
              {/* <div className='bg-light border'>{enumValues.columnSettings[camelCase(page?.columnSetting)]}</div> */}
              <div className='assigneeName'>担当者: {page?.assignee?.name}</div>
            </Stack>
          </Col>
          <PageStatusSpinner layoutable={layoutable} {...props} />
          <Col sm='auto' className='justify-content-end ps-1'>
            <ButtonModals {...props} />
          </Col>
        </Row>
      )
    }
    case 'SubLayout': {
      const subLayout = layoutable
      return (
        <Row className='align-items-center'>
          <Col className='fs-3 mr-auto'>
            <Stack direction='horizontal' gap={4}>
              <div className='layoutableType subLayoutName'>小組名称: {subLayout?.name} ({subLayout?.draftNumber})</div>
              {/* <div className='bg-light border'>{enumValues.columnSettings[camelCase(subLayout?.columnSetting)]}</div> */}
              <div className='assigneeName'>担当者: {subLayout?.assignee?.name}</div>
            </Stack>
          </Col>
          <PageStatusSpinner layoutable={layoutable} {...props} />
          <Col sm='auto' className='justify-content-end ps-1'>
            <ButtonModals {...props} />
          </Col>
        </Row>
      )
    }
  }
}

const AssignedFiguresList = ({
  assignedFigures
}) => {
  if (!assignedFigures?.length) {
    return
  }
  return (
    <Row className='align-items-center assignedFiguresList my-2'>
      <Col>
        <Stack direction='horizontal' gap={4}>
          <div className='fs-5'>割り当て済み図表類</div>
          <div className='assignedFigures'>
            <ListGroup horizontal>
              {assignedFigures?.map(f => (
                <ListGroup.Item key={`assigned-figure-${f.id}`} className={figureListItemClassName}>
                  {f.type === ArticleType.Template &&
                    <Badge pill bg='secondary'>在版</Badge>}
                  {f.filename}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </Stack>
      </Col>
    </Row>
  )
}

const UnassignedFiguresList = ({
  dispatch,
  openedModal,
  layoutableId,
  layoutableType,
  unassignedFigures
}) => {
  if (!unassignedFigures?.length) {
    return
  }
  const [selectedFigureId, setSelectedFigureId] = useState(undefined)
  const handleSubmit = ({
    figureId
  }) => {
    const delFigure = unassignedFigures?.find(f => f.id === figureId)
    if (!delFigure) {
      return
    }
    console.log('will delete this figure!', delFigure)
    dispatch(deleteFigure({ layoutableType, layoutableId, figure: delFigure, showAlert: true }))
  }
  const modalSetting = {
    name: 'delete-unassigned-figure',
    key: 'delete-unassigned-figure-modal',
    form: {
      initialValues: {
        figureId: selectedFigureId
      },
      handleSubmit,
      props: {
        title: '未割り当て図表類削除',
        submitButtonText: '削除する',
        onClickCancel: () => dispatch(closeModal()),
        ConfirmationBody: ({ selectedFigureId }) => {
          const fig = unassignedFigures?.find(f => f.id === selectedFigureId)
          if (!fig) {
            return
          }
          // console.log('selectedFigure inside ConfirmationBody', fig)
          return (
            <>「{fig.filename}」を削除しますか？</>
          )
        },
        selectedFigureId
      }
    }
  }
  return (
    <Row className='align-items-center unassignedFiguresList my-2'>
      <Col>
        <Stack direction='horizontal' gap={4}>
          <ConfirmationModal key={modalSetting.key} {...modalSetting} openedModal={openedModal} />
          <div className='fs-5'>未割り当て図表類</div>
          <div className='unassignedFigures'>
            <ListGroup horizontal>
              {unassignedFigures?.map(f => {
                const figId = f.id
                const handleClick = (e) => {
                  e.preventDefault()
                  setSelectedFigureId(figId)
                  dispatch(openModal('delete-unassigned-figure'))
                }
                return (
                  <ListGroup.Item key={`unassigned-figure-${f.id}`} className={figureListItemClassName}>
                    <div className='d-flex align-items-center'>
                      <CloseButton
                        onClick={handleClick}
                      />
                      {f.filename}
                    </div>
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </div>
        </Stack>
      </Col>
    </Row>
  )
}

const PageInformationFooter = ({
  layoutable
}) => {
  // console.log('page in footer', page)
  const page = layoutable
  return (
    <Row xs='auto' className='pb-3'>
      <Col sm={3}>
        <InputGroup size='sm'>
          <InputGroup.Text>記事下広告分の余白</InputGroup.Text>
          <Form.Control
            id='bottomAdColumnsInput'
            type='number'
            name='bottomAdNumberOfColumns'
            defaultValue={page?.bottomAdNumberOfColumns}
            readOnly
          />
          <InputGroup.Text>段</InputGroup.Text>
        </InputGroup>
      </Col>
      <Col sm={4}>
        <InputGroup size='sm'>
          <InputGroup.Text>紙面テンプレートファイル(indtファイル)</InputGroup.Text>
          <Form.Control
            id='templateFilenameInput'
            type='text'
            name='templateFilename'
            defaultValue={page?.templateFilename || ''}
            readOnly
          />
        </InputGroup>
      </Col>
    </Row>
  )
}

const getArticleOccupyingLines = (article) => {
  if (isAreaArticleType(article.articleType)) {
    const { width, height } = article.areas[0]
    return width * height
  }
  const {
    headingNumberOfOccupyingRows,
    headingNumberOfOccupyingColumns,
    preambleMarginRows,
    preambleNumberOfColumns,
    bodyNumberOfRows,
    rows
  } = article
  const normalHeadingLines = headingNumberOfOccupyingColumns * headingNumberOfOccupyingRows
  const nonNormalHeadingLines = article?.headings?.filter(h => sizeCheckRequiredHeadingTypes.has(snakeCase(h.headingType)))
    ?.map(h => h.numberOfOccupyingRows * h.numberOfOccupyingColumns)
    ?.reduce((prev, curr) => prev + curr, 0) || 0
  const preambleLines = preambleMarginRows * preambleNumberOfColumns
  const figuresLines = article?.figures?.map(fig => {
    const {
      numberOfOccupyingRows, numberOfOccupyingColumns
    } = fig
    if (!nullish(numberOfOccupyingRows) && !nullish(numberOfOccupyingColumns)) {
      return numberOfOccupyingRows * numberOfOccupyingColumns
    }
    return 0
  })?.reduce((prev, curr) => prev + curr, 0) || 0
  const bodyLines = rows || bodyNumberOfRows
  const totalLines = normalHeadingLines + nonNormalHeadingLines + preambleLines + figuresLines + bodyLines
  // console.log({ article, totalLines, normalHeadingLines, nonNormalHeadingLines, preambleLines, figuresLines, bodyLines })
  return totalLines
}

const listItemClassName = 'py-1 px-2'
const figureListItemClassName = 'bg-light py-1 px-2'

const LinesInformation = ({
  layoutable
}) => {
  // console.log({ layoutable })
  const {
    bottomAdNumberOfColumns,
    numberOfColumns,
    numberOfRows
  } = layoutable
  const requiredLines = numberOfColumns * numberOfRows
  const bottomAdLines = bottomAdNumberOfColumns * numberOfRows
  const articleOccupyingLines = layoutable?.articles?.map(a => getArticleOccupyingLines(a))
    ?.reduce((prev, curr) => prev + curr, 0) || 0
  const existingLines = bottomAdLines + articleOccupyingLines
  const differenceLines = existingLines - requiredLines
  const differenceStr = differenceLines < 0 ? `アキ: ${Math.abs(differenceLines)}行` : `アフレ: ${differenceLines}行`
  const differenceClassName = differenceLines < 0 ? `${listItemClassName} text-danger fw-bold` : listItemClassName
  return (
    <ListGroup horizontal size='sm' className='justify-content-end my-2'>
      <ListGroup.Item className={listItemClassName}>全体行数: {requiredLines}</ListGroup.Item>
      <ListGroup.Item className={listItemClassName}>出稿行数: {existingLines}</ListGroup.Item>
      <ListGroup.Item className={differenceClassName}>{differenceStr}</ListGroup.Item>
    </ListGroup>
  )
}

const getLargestOtherOrder = (articles) => {
  const otherArticles = articles.filter((a) => a.priority === Priority.Other)
    ?.sort((a, b) => (b.order - a.order))
  return otherArticles?.at(0)?.order ?? -1
}

const getLargestNonAreaOtherOrder = (articles) => {
  const otherArticles = articles.filter((a) => (a.priority === Priority.Other && !isAreaArticleType(a.articleType)))
    ?.sort((a, b) => (b.order - a.order))
  return otherArticles?.at(0)?.order ?? -1
}

const getSmallestNonAreaOtherOrder = (articles) => {
  const otherArticles = articles.filter((a) => (a.priority === Priority.Other && !isAreaArticleType(a.articleType)))
    ?.sort((a, b) => (a.order - b.order))
  return otherArticles?.at(0)?.order ?? -1
}

export const getSubmitParamForArticleUpsert = ({
  layoutableId,
  layoutableType,
  layoutableLockVersion,
  article,
  loadedBinaryFile,
  useAuxiliaryLine,
  deleteFile,
  auxiliaryLine,
  ...props
}) => {
  // handle replace and delete
  const { auxiliaryLine: auxiliaryLineFromArticle, ...otherArticleProps } = article
  const { replaceFile, ...otherProps } = props
  const newArticle = {
    ...otherArticleProps,
    ...otherProps
  }
  // console.log('getSubmitParamForArticleUpsert', { newArticle, otherProps, otherArticleProps })
  if (auxiliaryLine !== null) {
    newArticle.auxiliaryLine = auxiliaryLine
  }
  const formData = new window.FormData()
  formData.append('article', JSON.stringify(toSnake(newArticle)))
  formData.append('lock_version', layoutableLockVersion)
  if (deleteFile) {
    formData.append('delete_file', true)
  } else if (loadedBinaryFile !== undefined) {
    const { blob, name } = loadedBinaryFile
    formData.append('file', blob, name)
  }
  const { id: articleId } = newArticle
  if (articleId === undefined) {
    return { layoutableId, layoutableType, formData }
  } else {
    return { layoutableId, layoutableType, articleId, formData }
  }
}

const PageStatusSpinner = ({
  layoutable,
  enumValues,
  spinnerProps
}) => {
  const { delay, waitedTime, setWaitedTime, dispatch } = spinnerProps
  const status = layoutable?.status
  const shouldPoll = isLayoutEngineInProgressStatus(status)
  const { id: layoutableId, layoutableType } = layoutable
  useInterval(() => {
    // console.log('current status', { status, delay, shouldPoll })
    if (shouldPoll) {
      dispatch(fetchLayoutableStatus({ layoutableId, layoutableType }))
    }
    if (delay > 0) {
      setWaitedTime(waitedTime + delay)
    }
  }, shouldPoll ? delay : null)

  const variant = shouldPoll ? 'primary' : 'outline-success'
  const statusString = shouldPoll
    ? (
      <>
        <Spinner
          as='span'
          animation='border'
          role='status'
          aria-hidden='true'
          size='sm'
        />
        {' '}割り付け実行中...
      </>
      )
    : enumValues.statuses[camelCase(layoutable?.status)]
  return (
    <Col sm='auto' className='px-0'>
      <Button variant={variant} size='sm' disabled>
        {statusString}
      </Button>
    </Col>
  )
}

const columnHelper = createColumnHelper()
export const ArticleList = ({ layoutableType = 'Page' }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let layoutableId
  switch (layoutableType) {
    case 'Page': {
      const { pageId: pageIdString } = useParams()
      layoutableId = parseInt(pageIdString)
      break
    }
    case 'SubLayout': {
      const { subLayoutId: subLayoutIdString } = useParams()
      layoutableId = parseInt(subLayoutIdString)
      break
    }
  }
  const {
    layoutable: fetchedLayoutable,
    data: articleList,
    fetching: articleFetching,
    openedModal,
    sort,
    selectedArticle,
    selectedCell,
    selectedIds,
    errors,
    alert
  } = useSelector(selectArticle)
  const {
    data: mediaList,
    fetching: mediaFetching
  } = useSelector(selectMedia)
  const {
    data: userList,
    fetching: userFetching
  } = useSelector(selectUser)
  const {
    data: settingList,
    fetching: settingFetching
  } = useSelector(selectSetting)

  // const articleFiles = articleList?.filter((a) => a.location?.length > 0)?.map((a) => a.filename) || []
  const [layoutValidationErrors, setLayoutValidationErrors] = useState([])
  const articleFiles = articleList?.filter(a => a.filename?.length > 0)?.map(a => a.filename) || []
  const nonHolderArticles = articleList?.filter(a => a.articleType !== ArticleType.Holder)
  const holderArticle = articleList?.find(a => a.articleType === ArticleType.Holder)
  const unassignedFigures = holderArticle?.figures
  const assignedFiguresFromArticles = nonHolderArticles?.flatMap(a => a.figures?.length > 0 ? a.figures : [])
    ?.filter(f => f.location?.length > 0)
  const assignedFiguresFromTemplateArticles = nonHolderArticles
    ?.flatMap(a => (a.articleType === ArticleType.Template && a?.templateArticles?.length > 0) ? a.templateArticles : [])
    ?.filter(t => t.templateArticleType === 'figure' && t?.location?.length > 0)
    ?.map(({ id, filename }) => ({ id, filename, type: 'template' }))
  const assignedFigures = [...assignedFiguresFromArticles, ...assignedFiguresFromTemplateArticles]
  const unassignedFiguresFilenames = unassignedFigures?.map(f => f.filename) || []
  const assignedFiguresFilenames = assignedFigures?.map(f => f.filename) || []
  const existingFiles = [].concat(articleFiles, unassignedFiguresFilenames, assignedFiguresFilenames)
    ?.filter(f => f !== null)
  // const articleFiles = articleList?.filter((a) => a.location?.length > 0)?.map((a) => a.filename)
  // console.log('articleFiles', articleFiles)
  // console.log('articleList', articleList)
  // console.log('existingFiles', existingFiles)
  // console.log('assignedFigures', assignedFigures)
  // console.log('unassignedFigures', unassignedFigures)
  const layoutable = { articles: nonHolderArticles, ...fetchedLayoutable }
  const { enumValues } = useSelector(selectCommon)
  useEffect(() => {
    dispatch(fetchArticles({ layoutableId, layoutableType }))
    dispatch(fetchMedia({}))
    dispatch(fetchSettings({}))
    dispatch(fetchUsers({}))
  }, [sort])
  const [fetching, setFetching] = useState(true)
  useEffect(() => {
    if (articleFetching || mediaFetching || settingFetching || userFetching) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }, [articleFetching, mediaFetching, settingFetching, userFetching])
  const DEFAULT_DELAY = 5000
  const maxWaitedTime = 5 * 60 * 1000 // 5 minutes
  const [waitedTime, setWaitedTime] = useState(0)
  const [delay, setDelay] = useState(DEFAULT_DELAY)
  const [isPolling, setIsPolling] = useState(false)
  const spinnerProps = { delay, waitedTime, setWaitedTime, dispatch }
  const layoutableBaseUrl = getLayoutableBaseUrl({ layoutableId, layoutableType })
  const isLayoutShowable = (layoutable) => isLayoutViewableStatus(layoutable?.status)
  useEffect(() => {
    const isWaitingState = isLayoutEngineInProgressStatus(layoutable?.status)
    const shouldPoll = isWaitingState && (waitedTime < maxWaitedTime)
    if (!isPolling && shouldPoll) {
      console.log('start polling')
    } else if (isPolling && !shouldPoll) {
      console.log(`stop polling waitedTime = ${waitedTime}`)
      if (isLayoutShowable(layoutable)) {
        console.log(`engine execute complete. results should be ready. status = ${layoutable?.status}`)
        navigate(`/${layoutableBaseUrl}/layout`)
      }
    } else {
      console.log(`still polling waitedTime = ${waitedTime}`)
    }
    setIsPolling(shouldPoll)
    setDelay(shouldPoll ? DEFAULT_DELAY : null)
  }, [layoutable?.status, waitedTime])
  const usedPriorities = new Set(
    nonHolderArticles.filter(a => a.priority !== Priority.Other)
      .map(a => camelCase(a.priority))
  )
  const largestOrder = getLargestOtherOrder(nonHolderArticles)
  const largestNonAreaOtherOrder = getLargestNonAreaOtherOrder(nonHolderArticles)
  const smallestNonAreaOtherOrder = getSmallestNonAreaOtherOrder(nonHolderArticles)
  const { currentUser } = useSelector(selectAuth)
  const isSelectedLayoutableReadable = layoutable && currentUser &&
        (currentUser.role === 'group_administrator' ||
         currentUser.role === 'supervisor' ||
         currentUser.id === layoutable?.assignee?.id)
  const isSelectedLayoutableWritable = isSelectedLayoutableReadable && isLayoutEditableStatus(layoutable.status)
  // console.log('page', [page, isSelectedLayoutableReadable, isSelectedLayoutableWritable])
  // console.log(usedPriorities)

  const switchArticleOrder = (articles, fromIdx, toIdx) => {
    if (articles?.length === 0 || fromIdx < 0 || toIdx < 0 || fromIdx >= articles.length || toIdx >= articles.length) {
      console.log('index out of bounds.', { fromIdx, toIdx })
      return
    }
    const fromArticle = articles[fromIdx]
    const toArticle = articles[toIdx]
    if (fromArticle.priority !== Priority.Other || toArticle.priority !== Priority.Other ||
        isAreaArticleType(fromArticle.articleType) || isAreaArticleType(toArticle.articleType)) {
      console.log('cannot switch order of specified articles.', { fromArticle, toArticle })
      return
    }
    const switchArticles = [
      { id: fromArticle.id, order: toArticle.order },
      { id: toArticle.id, order: fromArticle.order }
    ]
    const submitParam = { layoutableId, layoutableType, lockVersion: layoutable?.lockVersion, articles: switchArticles }
    // console.log('switch order of specified articles.', { fromArticle, toArticle, switchArticles })
    console.log('switch order of specified articles.', submitParam)
    dispatch(switchArticleOrders(submitParam))
  }
  // console.log('layoutResult', page?.layoutResult)
  const layoutIndex = layoutable?.layoutIndex || 0
  const layoutResult = Array.isArray(layoutable?.layoutResult) ? layoutable?.layoutResult?.[layoutIndex] : layoutable?.layoutResult
  const missedArticles = new Set(layoutResult?.articles?.filter(elem => elem.areas.length === 0)?.map(a => a.id) ?? [])
  const numberOfMissLayout = missedArticles.size
  // console.log('missedArticles', missedArticles)
  const layoutMissed = (props) => (missedArticles.has(`article-${props.row.original.id}`))

  // console.log('selectedArticle for edit2', selectedArticle)
  const columns = [
    {
      id: 'checkboxColumn',
      cell: props => <Checkbox
        value={props.row.original.id}
        checked={selectedIds.includes(parseInt(props.row.original.id))}
        onChange={(value, checked) => { dispatch((checked ? selectId : unselectId)(parseInt(value))) }}
                     />
    },
    {
      name: '#',
      cell: props => {
        const row = props.row.original
        const rowNumber = props.row.index + 1
        if (row.parentId === null) {
          return rowNumber
        }
        const childChar = '\u2514'
        const typeMapping = {
          same: '(合)',
          related: '(関)',
          grouped: '(塊)'
        }
        const relationType = typeMapping[props.row.original.relationType]
        return `${childChar}${rowNumber}${relationType}`
      }
    },
    {
      id: 'actionColumn',
      name: '編集',
      meta: { cellProps: { style: { width: '70px' } } },
      cell: props => {
        const article = props.row.original
        // console.log('newArticle for select', newArticle)
        const articleId = parseInt(article.id)
        // console.log('article from action', article)
        const keyBase = `article-${articleId}-action`
        const inputDisabled = !isSelectedLayoutableWritable || article.layoutLocked
        if (inputDisabled) {
          return (
            <>
              <OverlayLink
                keyName={`${keyBase}-view`}
                tooltipText='記事内容詳細'
                placement='left'
                icon='info-circle'
                disabled={!inputDisabled}
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(selectTargetArticle(article))
                  dispatch(openModal('view'))
                }}
              />
              {article.layoutLocked &&
                <OverlayLink
                  keyName={`${keyBase}-unlock`}
                  tooltipText='レイアウトロック解除'
                  placement='left'
                  icon='unlock'
                  disabled={!isSelectedLayoutableWritable}
                  onClick={(e) => {
                    e.preventDefault()
                    dispatch(selectTargetArticle(article))
                    dispatch(openModal('unlock'))
                  }}
                />}
            </>
          )
        }
        return (
          <span>
            <OverlayLink
              keyName={`${keyBase}-edit`}
              tooltipText='記事内容編集'
              placement='left'
              icon='edit'
              disabled={inputDisabled}
              onClick={(e) => {
                e.preventDefault()
                dispatch(selectTargetArticle(article))
                dispatch(openModal('edit'))
              }}
            />
            <OverlayLink
              keyName={`${keyBase}-up`}
              placement='left'
              tooltipText='優先順位を上げる'
              icon='angle-double-up'
              disabled={inputDisabled || article.priority !== Priority.Other || isAreaArticleType(article.articleType) || article.order === smallestNonAreaOtherOrder}
              onClick={(e) => {
                e.preventDefault()
                switchArticleOrder(nonHolderArticles, props.row.index, props.row.index - 1)
              }}
            />
            <OverlayLink
              keyName={`${keyBase}-down`}
              placement='left'
              tooltipText='優先順位を下げる'
              disabled={inputDisabled || article.priority !== Priority.Other || isAreaArticleType(article.articleType) || article.order === largestNonAreaOtherOrder}
              onClick={(e) => {
                e.preventDefault()
                switchArticleOrder(nonHolderArticles, props.row.index, props.row.index + 1)
              }}
              icon='angle-double-down'
            />
          </span>
        )
      }
    },
    columnHelper.accessor('articleType', {
      name: '記事種別',
      cell: info => {
        const article = info.row.original
        return (
          <Form.Control
            as='select'
            type='text'
            name='articleType'
            value={info.getValue()}
            onChange={(e) => {
              const articleType = e.target.value
              const newArticle = { ...article, articleType }
              if (articleType === ArticleType.Flow) {
                newArticle.areas = undefined
              } else if (isSizedArticleType(articleType)) {
                if (isTopPositionPriority(newArticle.priority)) {
                  newArticle.areas = [{ x: '', y: 0, width: '', height: '', fromLeft: false }]
                } else {
                  newArticle.areas = undefined
                }
              }
              const props = { layoutableId, layoutableType, layoutableLockVersion: layoutable?.lockVersion, article: newArticle }
              const submitParam = getSubmitParamForArticleUpsert(props)
              dispatch(modifyArticle(submitParam))
            }}
            size='sm'
            disabled={!isSelectedLayoutableWritable || isAreaArticleType(info.getValue()) || article.layoutLocked}
          >
            {Object.keys(enumValues.articleTypes).map(articleType => (
              <option
                key={articleType}
                value={snakeCase(articleType)}
                disabled={fetching || isAreaArticleType(articleType) || isDisabledArticleType(article.priority, articleType)}
              >
                {enumValues.articleTypes[articleType]}
              </option>
            ))}
          </Form.Control>
        )
      }
    }),
    columnHelper.accessor('priority', {
      id: 'priorityColumn',
      name: '記事優先度',
      cell: info => {
        const article = info.row.original
        return (
          <Form.Control
            as='select'
            type='text'
            name='priority'
            value={info.getValue()}
            onChange={(e) => {
              const priority = e.target.value
              const articleType = coerceArticleTypeOnPriorityChange(priority, article.articleType)
              const order = priority === Priority.Other ? largestOrder + 1 : 0
              const newArticle = { ...article, articleType, priority, order }
              if (isSizedArticleType(newArticle.articleType)) {
                if (isTopPositionPriority(priority)) {
                  newArticle.areas = [{ x: '', y: 0, width: '', height: '', fromLeft: false }]
                } else {
                  newArticle.areas = undefined
                }
              }
              const props = { layoutableId, layoutableType, layoutableLockVersion: layoutable?.lockVersion, article: newArticle }
              const submitParam = getSubmitParamForArticleUpsert(props)
              dispatch(modifyArticle(submitParam))
            }}
            size='sm'
            disabled={!isSelectedLayoutableWritable || article.articleType === ArticleType.Template || article.layoutLocked}
          >
            {Object.keys(enumValues.priorities).map(k => (
              <option key={k} value={snakeCase(k)} disabled={usedPriorities.has(k)}>{enumValues.priorities[k]}</option>
            ))}
          </Form.Control>
        )
      }
    }),
    columnHelper.accessor('name', {
      name: '名称',
      cell: info => {
        const article = info.row.original
        const name = article.filename || article.name
        const articleLayoutLockedIcon = article.layoutLocked ? <><FontAwesomeIcon icon='lock' /> </> : undefined
        const nameColumn = layoutMissed(info)
          ? (
            <Col className='text-danger'>
              <FontAwesomeIcon icon='triangle-exclamation' />
              {name}
            </Col>
            )
          : (<Col>{articleLayoutLockedIcon}{name}</Col>)
        const unassignedFigureCount = article?.templateArticles
          ?.filter(t => t.templateArticleType === 'figure' && !t?.location)?.length || 0
        const templateArticle = article.articleType === ArticleType.Template
          ? (
            <Col sm='auto'>
              <Button
                size='sm'
                variant='outline-primary'
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(selectCell({ articleId: article.id, rowId: info.row.index, column: 'templateArticle' }))
                }}
              >
                在版記事
                {unassignedFigureCount > 0 &&
                  <>
                   &nbsp;
                    <Badge pill bg='danger'>{unassignedFigureCount}</Badge>
                  </>}
              </Button>
            </Col>
            )
          : (<></>)
        if (article?.auxiliaryLine !== null) {
          const auxiliaryLineStr = `補助線あり(${article.auxiliaryLine.startColumn + 1}段目${article.auxiliaryLine.startRow + 1}行目${article.auxiliaryLine.length}段)`
          return (
            <Row>
              {nameColumn}
              <Col sm='auto'><Badge bg='secondary'>{auxiliaryLineStr}</Badge></Col>
            </Row>
          )
        }
        return (<Row>{nameColumn}{templateArticle}</Row>)
      }
    }),
    columnHelper.accessor('headings', {
      name: '見出し',
      meta: { cellProps: { style: { cursor: 'pointer' } } },
      cell: info => {
        const article = info.row.original
        return (
          article.headings?.length
            ? `${article.headingNumberOfOccupyingColumns}段${article.headings?.length}本${article.headingNumberOfOccupyingRows}行取り`
            : ''
        )
      }
    }),
    columnHelper.accessor('preambleNumberOfRows', {
      id: 'preambleColumn',
      name: '前文',
      meta: { cellProps: { style: { cursor: 'pointer' } } },
      cell: info => {
        const preambleNumberOfRows = info.getValue()
        return preambleNumberOfRows ? `${preambleNumberOfRows}行` : ''
      }
    }),
    {
      name: '本文',
      id: 'bodyColumn',
      meta: { cellProps: { style: { cursor: 'pointer' } } },
      cell: props => {
        const article = props.row.original
        const columnSpaceExistence = article.columnSpaceExistence ? '中段有' : '中段無'
        return (article.bodyNumberOfRows ? `${article.bodyNumberOfRows}行/${columnSpaceExistence}` : '')
      }
    },
    columnHelper.accessor('figures', {
      name: '図表類枚数',
      meta: { cellProps: { style: { cursor: 'pointer' } } },
      cell: info => {
        const figures = info?.getValue()
        const figuresCount = figures?.length || ''
        const invalidFiguresCount = figures?.filter(fig => !figureSchema.isValidSync(fig))?.length || 0
        return (
          <>
            {figuresCount}
            &nbsp;
            {invalidFiguresCount > 0 &&
              <Badge pill bg='danger'>{invalidFiguresCount}</Badge>}
          </>
        )
      }
    }),
    columnHelper.accessor('areas', {
      name: '領域',
      meta: { cellProps: { style: { cursor: 'pointer' } } },
      cell: props => {
        const article = props.row.original
        if (isSizedArticleType(article.articleType) || !article?.areas?.length) {
          return ''
        }
        const { width, height } = article.areas[0]
        const columnString = getColumnString(height)
        const invalidAreasCount = article.areas?.filter(area => !isValidArea(layoutable, area))?.length || 0
        return (
          <>
            {`天地${columnString} 左右${parseInt(width)}行幅`}
            {invalidAreasCount > 0 &&
              <>
                &nbsp;
                <Badge pill bg='danger'>!</Badge>
              </>}
          </>
        )
      }
    }),
    // columnHelper.accessor('createdAt', {
    //   name: '作成日時',
    //   cell: info => moment(info.getValue()).format('LLL')
    // }),
    columnHelper.accessor('updatedAt', {
      name: '更新日時',
      cell: info => moment(info.getValue()).format('LLL')
    })
  ]
  const handleCellClick = (row, cell) => {
    const column = cell.column.id
    const supportedColumns = new Set(['headings', 'figures', 'areas', 'bodyColumn', 'preambleColumn'])
    if (supportedColumns.has(column)) {
      dispatch(selectCell({ articleId: row.original.id, rowId: row.index, column }))
    }
    // console.log(row)
    // console.log(`column ${column} clicked!`)
  }
  const unselectArticle = () => {
    dispatch(selectTargetArticle(null))
  }
  const unselectCell = () => {
    dispatch(selectCell(null))
  }
  const handleBulkArticlesSubmit = ({
    layoutableType,
    layoutableId,
    formData
  }) => {
    dispatch(bulkCreateArticles({ layoutableType, layoutableId, formData }))
  }
  const handleArticlesDeleteSubmit = ({
    layoutableType,
    layoutableId,
    layoutableLockVersion,
    selectedIds
  }) => {
    const submitParam = { layoutableType, layoutableId, layoutableLockVersion, selectedIds }
    console.log(submitParam)
    unselectCell()
    unselectArticle()
    dispatch(deleteArticles(submitParam))
  }
  const handleAddArticleSubmit = ({
    ...props
  }) => {
    const submitParam = getSubmitParamForArticleUpsert(props)
    dispatch(createArticle(submitParam))
  }
  const handleChangeArticleSubmit = ({
    ...props
  }) => {
    const submitParam = getSubmitParamForArticleUpsert(props)
    dispatch(modifyArticle(submitParam))
    unselectCell()
  }

  const articleModalSettings = [
    {
      name: 'edit',
      key: 'edit-article-modal',
      form: {
        initialValues: {
          layoutableId,
          layoutableType,
          layoutableLockVersion: layoutable?.lockVersion,
          article: selectedArticle,
          articleType: selectedArticle?.articleType || ArticleType.Flow,
          priority: selectedArticle?.priority || Priority.Other,
          columnSpaceExistence: selectedArticle?.columnSpaceExistence ?? true,
          dividerLineMode: selectedArticle?.dividerLineMode || getDefaultDividerLineMode(selectedArticle?.articleType),
          filename: selectedArticle?.filename || '',
          file: '',
          name: selectedArticle?.name || '',
          parentId: selectedArticle?.parentId || '',
          relationType: selectedArticle?.relationType || '',
          useAuxiliaryLine: selectedArticle?.auxiliaryLine !== null,
          auxiliaryLine: selectedArticle?.auxiliaryLine,
          rows: selectedArticle?.rows || ''
        },
        handleSubmit: handleChangeArticleSubmit,
        validationSchema: ArticleUpsertForm.schema,
        Component: ArticleUpsertForm,
        props: {
          enumValues,
          usedPriorities,
          largestOrder,
          nonHolderArticles,
          selectedArticle,
          existingFiles,
          title: '記事内容変更',
          layoutable,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '更新する'
        }
      }
    },
    {
      name: 'view',
      key: 'view-article-modal',
      form: {
        initialValues: {
          layoutableId,
          layoutableType,
          article: selectedArticle,
          articleType: selectedArticle?.articleType || ArticleType.Flow,
          priority: selectedArticle?.priority || Priority.Other,
          columnSpaceExistence: selectedArticle?.columnSpaceExistence ?? true,
          dividerLineMode: selectedArticle?.dividerLineMode || getDefaultDividerLineMode(selectedArticle?.articleType),
          filename: selectedArticle?.filename || '',
          file: '',
          name: selectedArticle?.name || '',
          parentId: selectedArticle?.parentId || '',
          relationType: selectedArticle?.relationType || '',
          useAuxiliaryLine: selectedArticle?.auxiliaryLine !== null,
          auxiliaryLine: selectedArticle?.auxiliaryLine,
          rows: selectedArticle?.rows || ''
        },
        Component: ArticleViewForm,
        props: {
          enumValues,
          // usedPriorities,
          // largestOrder,
          // nonHolderArticles,
          selectedArticle,
          // existingFiles,
          title: '記事内容詳細',
          // page: { articles: nonHolderArticles, ...page },
          layoutable,
          onClickCancel: () => dispatch(closeModal())
          // submitButtonText: '更新する'
        }
      }
    },
    {
      name: 'unlock',
      key: 'unlock-article-layout-modal',
      form: {
        initialValues: {
          layoutableId,
          layoutableType,
          lockVersion: layoutable?.lockVersion,
          article: selectedArticle
        },
        handleSubmit: ({
          article,
          ...props
        }) => {
          // console.log('handleSubmit', { props })
          const params = {
            ...props,
            ...{
              article: { id: article.id, layoutLocked: false }
            }
          }
          // console.log('handleSubmit', { params, props })
          dispatch(modifyLayoutLocked(params))
        },
        Component: ConfirmationForm,
        props: {
          enumValues,
          selectedArticle,
          title: 'レイアウトロック解除',
          article: selectedArticle,
          layoutable,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '解除する',
          ConfirmationBody: ({ article }) => {
            const articleName = article.filename || article.name || article.id || ''
            return (
              <>「{articleName}」のレイアウトロックを解除しますか？</>
            )
          }
        }
      }
    }
  ]
  const handleStartLayoutSubmit = () => {
    const newLayoutValidationErrors = validateLayoutable({ layoutable: { ...layoutable, articles: nonHolderArticles }, enumValues })
    setLayoutValidationErrors(newLayoutValidationErrors)
    if (newLayoutValidationErrors.length === 0) {
      dispatch(startLayout({ layoutableType, layoutableId, lockVersion: layoutable?.lockVersion }))
    } else {
      dispatch(closeModal())
    }
  }
  let layoutableEditSetting
  switch (layoutableType) {
    case 'Page': {
      const page = layoutable
      const handleEditSubmit = getHandleEditPageSubmit(dispatch, modifyPage)
      layoutableEditSetting = {
        name: 'page-edit',
        button: {
          handleClick: () => {
            dispatch(openModal('page-edit'))
          },
          disabled: fetching || !isSelectedLayoutableWritable,
          icon: 'pencil',
          text: '紙面設定変更'
        },
        form: {
          initialValues: () => {
            if (!page) {
              return {}
            }
            const issueDate = moment(page.issueDate).format('YYYY-MM-DD')
            // console.log('issueDate', issueDate)
            const mediaId = page.media.id
            const selectedSettingList = mediaId !== '' ? settingList?.filter(s => s.media.id === mediaId) : []
            const {
              name, setting, media, assignee,
              ...pageProps
            } = page
            // console.log('page in page-edit', pageProps)
            return {
              ...pageProps,
              layoutableType,
              layoutableId,
              mediaId,
              settingId: page.setting.id,
              assigneeId: page?.assignee?.id,
              selectedSettingList,
              issueDate,
              templateFile: ''
            }
          },
          handleSubmit: handleEditSubmit,
          validationSchema: PageUpsertForm.schema,
          Component: PageUpsertForm,
          props: {
            title: '紙面編集',
            fetching,
            enumValues,
            onClickCancel: () => dispatch(closeModal()),
            submitButtonText: '変更する',
            mediaList,
            settingList,
            userList,
            currentUser
          }
        }
      }
      break
    }
    case 'SubLayout': {
      layoutableEditSetting = {
        name: 'subLayout-edit',
        button: {
          handleClick: () => {
            dispatch(openModal('subLayout-edit'))
          },
          disabled: fetching || !isSelectedLayoutableWritable,
          icon: 'pencil',
          text: '小組設定変更',
          hidden: true
        }
      }
      break
    }
  }
  const headerModalSettings = [
    layoutableEditSetting,
    {
      name: 'show-layout',
      button: {
        handleClick: () => navigate(`/${layoutableBaseUrl}/layout`),
        icon: 'table',
        text: '紙面表示',
        disabled: !isLayoutShowable(layoutable)
      }
    },
    {
      name: 'start-layout',
      button: {
        handleClick: () => dispatch(openModal('start-layout')),
        icon: 'circle-play',
        text: '割り付け実行',
        disabled: fetching || !isSelectedLayoutableWritable || !isLayoutEngineExecutableStatus(layoutable?.status)
      },
      form: {
        handleSubmit: handleStartLayoutSubmit,
        Component: StartLayoutForm,
        props: {
          onClickCancel: () => dispatch(closeModal()),
          layoutableName: layoutable?.name,
          fetching
        }
      }
    }
  ]
  const buttonModalsSettings = [
    {
      name: 'bulk-add',
      button: {
        handleClick: () => {
          dispatch(closeAlert())
          dispatch(openModal('bulk-add'))
        },
        disabled: fetching || !isSelectedLayoutableWritable,
        icon: 'folder-plus',
        text: '複数記事追加'
      },
      form: {
        initialValues: {
          layoutableId,
          layoutableType,
          formData: null
        },
        handleSubmit: handleBulkArticlesSubmit,
        Component: ArticleBulkUploadForm,
        props: {
          enumValues,
          title: '複数記事追加',
          layoutable,
          holderArticle,
          existingFiles,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '追加する',
          layoutableLockVersion: layoutable?.lockVersion
        }
      }
    },
    {
      name: 'add',
      button: {
        handleClick: () => {
          dispatch(closeAlert())
          dispatch(openModal('add'))
        },
        disabled: fetching || !isSelectedLayoutableWritable,
        icon: 'plus',
        text: '記事追加'
      },
      form: {
        initialValues: {
          layoutableId,
          layoutableType,
          layoutableLockVersion: layoutable?.lockVersion,
          article: null,
          articleType: ArticleType.Flow,
          priority: Priority.Other,
          columnSpaceExistence: true,
          dividerLineMode: DividerLineMode.Both,
          file: '',
          parentId: '',
          relationType: '',
          name: null,
          useAuxiliaryLine: false,
          auxiliaryLine: null
        },
        handleSubmit: handleAddArticleSubmit,
        validationSchema: ArticleUpsertForm.schema,
        Component: ArticleUpsertForm,
        props: {
          enumValues,
          usedPriorities,
          largestOrder,
          title: '新規記事追加',
          layoutable,
          existingFiles,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '追加する',
          alert
        }
      }
    },
    {
      name: 'images-add',
      button: {
        handleClick: () => {
          dispatch(openModal('images-add'))
        },
        disabled: fetching || !isSelectedLayoutableWritable,
        icon: 'images',
        text: '画像追加'
      },
      form: {
        initialValues: {
          layoutableId,
          layoutableType,
          acceptedImageList: [],
          rejectedImageList: [],
          formData: undefined,
          images: undefined
        },
        handleSubmit: handleBulkArticlesSubmit,
        Component: ArticleImagesAddForm,
        props: {
          enumValues,
          title: '画像追加',
          layoutable,
          holderArticle,
          existingFiles,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '追加する',
          layoutableLockVersion: layoutable?.lockVersion
        }
      }
    },
    {
      name: 'delete',
      button: {
        handleClick: () => {
          dispatch(openModal('delete'))
        },
        disabled: !isSelectedLayoutableWritable || selectedIds.length === 0,
        variant: 'outline-danger',
        icon: 'trash',
        text: '選択記事削除'
      },
      form: {
        initialValues: {
          layoutableId,
          layoutableType,
          layoutableLockVersion: layoutable?.lockVersion,
          selectedIds
        },
        handleSubmit: handleArticlesDeleteSubmit,
        validationSchema: ArticlesDeleteForm.schema,
        Component: ArticlesDeleteForm,
        props: {
          enumValues,
          title: '選択記事削除',
          message: '選択した記事を本当に削除しますか？',
          selectedIds,
          // page: { articles: nonHolderArticles, ...page },
          layoutable,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '削除する'
        }
      }
    }
  ]
  const missLayoutAlert = numberOfMissLayout > 0
    ? (
      <Row className='align-items-center'>
        <Col className='fs-5 mr-auto'>
          <Alert variant='warning'>
            <FontAwesomeIcon icon='triangle-exclamation' />
            割り付けされなかった記事が{numberOfMissLayout}本あります。
          </Alert>
        </Col>
      </Row>
      )
    : undefined
  const closeValidationErrors = () => setLayoutValidationErrors([])
  return (
    <>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <LayoutValidationErrorsAlert
        errors={layoutValidationErrors}
        {...{ closeValidationErrors }}
      />
      <DismissibleAlert alert={alert} onClose={() => dispatch(closeAlert())} />
      {missLayoutAlert}
      <Container fluid>
        <ArticleListHeader
          layoutableType={layoutableType}
          layoutableId={layoutableId}
          layoutable={layoutable}
          enumValues={enumValues}
          openedModal={openedModal}
          settings={headerModalSettings}
          spinnerProps={spinnerProps}
        />
        {layoutableType === 'Page' &&
          <LinesInformation layoutable={layoutable} />}
        {layoutableType === 'SubLayout' &&
          <div className='my-3' />}
        <DataList
          columns={columns}
          data={nonHolderArticles}
          sort={sort}
          handleCellClick={handleCellClick}
        />
        <AssignedFiguresList
          assignedFigures={assignedFigures}
        />
        <UnassignedFiguresList
          dispatch={dispatch}
          unassignedFigures={unassignedFigures}
          openedModal={openedModal}
          {...{
            layoutableId,
            layoutableType
          }}
        />
        <div className='bottom-buttons my-3'>
          <ButtonModals settings={buttonModalsSettings} openedModal={openedModal} />
        </div>
        {layoutableType === 'Page' &&
          <PageInformationFooter layoutable={layoutable} />}
        {articleModalSettings.map(modalSetting =>
          <CustomModal key={modalSetting.key} {...modalSetting} openedModal={openedModal} />
        )}
      </Container>
      <ArticleDetail
        articles={nonHolderArticles} cell={selectedCell}
        {...{
          layoutable,
          layoutableId,
          layoutableType,
          enumValues,
          selectedArticle,
          unselectCell,
          handleChangeArticleSubmit,
          isSelectedLayoutableWritable,
          holderArticle,
          existingFiles
        }}
      />
    </>
  )
}
