import React from 'react'
import {
  Form,
  Row,
  Col,
  InputGroup,
  Modal,
  Button,
  Alert
} from 'react-bootstrap'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
import { TemplateFileControl } from '../page'
Yup.setLocale(ja.suggestive)

const boxSize = '26px'
const solidStyle = '2px solid black'
const baseBoxStyle = {
  width: boxSize, height: boxSize, border: '1px dashed gray'
}
const borderLinePlacementStyles = {
  l4: { ...baseBoxStyle, border: solidStyle },

  l3P1: { ...baseBoxStyle, borderTop: solidStyle, borderRight: solidStyle, borderBottom: solidStyle },
  l3P2: { ...baseBoxStyle, borderTop: solidStyle, borderLeft: solidStyle, borderBottom: solidStyle },
  l3P3: { ...baseBoxStyle, borderLeft: solidStyle, borderRight: solidStyle, borderBottom: solidStyle },
  l3P4: { ...baseBoxStyle, borderTop: solidStyle, borderLeft: solidStyle, borderRight: solidStyle },

  l2P1: { ...baseBoxStyle, borderTop: solidStyle, borderBottom: solidStyle },
  l2P2: { ...baseBoxStyle, borderTop: solidStyle, borderLeft: solidStyle },
  l2P3: { ...baseBoxStyle, borderTop: solidStyle, borderRight: solidStyle },
  l2P4: { ...baseBoxStyle, borderLeft: solidStyle, borderBottom: solidStyle },
  l2P5: { ...baseBoxStyle, borderRight: solidStyle, borderBottom: solidStyle },
  l2P6: { ...baseBoxStyle, borderLeft: solidStyle, borderRight: solidStyle },

  l1P1: { ...baseBoxStyle, borderTop: solidStyle },
  l1P2: { ...baseBoxStyle, borderBottom: solidStyle },
  l1P3: { ...baseBoxStyle, borderLeft: solidStyle },
  l1P4: { ...baseBoxStyle, borderRight: solidStyle },
  l0: {}
}

const boxInputs = [
  { title: '4方', keys: ['l4'] },
  { title: '3方', keys: ['l3P1', 'l3P2', 'l3P3', 'l3P4'] },
  { title: '2方', keys: ['l2P1', 'l2P2', 'l2P3', 'l2P4', 'l2P5', 'l2P6'] },
  { title: '1方', keys: ['l1P1', 'l1P2', 'l1P3', 'l1P4'] }
]

export const SubLayoutUpsertForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  setFieldValue,
  fetching,
  enumValues,
  onClickCancel,
  title,
  submitButtonText,
  mediaList,
  settingList,
  userList,
  currentUser,
  alert,
  ...props
}) => {
  const handleChangeSetting = (e) => {
    const settingId = parseInt(e.target.value)
    const selectedSetting = values.selectedSettingList?.find(s => s.id === settingId)
    const selectedColumnSetting = selectedSetting?.columnSetting
    setFieldValue('numberOfColumns', selectedColumnSetting?.numberOfColumns || '')
    setFieldValue('numberOfRows', selectedColumnSetting?.numberOfRows || '')
    setFieldValue('numberOfCharactersPerRow', selectedColumnSetting?.numberOfCharactersPerRow || '')
    handleChange(e)
  }
  const handleUseBorderLineChange = (e) => {
    const useBorderLine = e.target.value === 'true'
    setFieldValue('useBorderLine', useBorderLine)
    if (useBorderLine) {
      setFieldValue('linePlacement', 'l4')
    } else {
      setFieldValue('linePlacement', 'l0')
    }
  }
  // if (errors) {
  //   console.log('errors', errors)
  // }
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {alert?.show && alert?.alertBody &&
        <Alert
          className='upsert-sub-layout-errors-alert'
          variant='danger'
        >
          {alert.alertBody}
        </Alert>}
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Form.Group as={Col}>
              <Form.Label column className='mediaIdLabel'>媒体名</Form.Label>
              <Col>
                <Form.Control
                  as='select'
                  type='text'
                  name='mediaId'
                  value={values.mediaId}
                  onChange={(e) => {
                    const mediaId = parseInt(e.target.value)
                    const selectedSettingList = mediaId !== '' ? settingList?.filter(s => s.media.id === mediaId) : []
                    const settingId = selectedSettingList?.length > 0 ? selectedSettingList[0].id : ''
                    setFieldValue('selectedSettingList', selectedSettingList)
                    setFieldValue('settingId', settingId)
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  isInvalid={touched.mediaId && errors.mediaId}
                  disabled={mediaList?.length <= 1}
                  size='sm'
                >
                  {mediaList?.map(({ id, name }) => (
                    <option key={id} value={id}>{name}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.mediaId}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column className='mediaIdLabel'>紙面ベース</Form.Label>
              <Col>
                <Form.Control
                  as='select'
                  type='text'
                  name='settingId'
                  value={values.settingId}
                  onChange={handleChangeSetting}
                  onBlur={handleBlur}
                  isInvalid={touched.settingId && errors.settingId}
                  disabled={errors.mediaId || settingList?.length <= 1}
                  size='sm'
                >
                  {values.selectedSettingList?.map(({ id, name }) => (
                    <option key={id} value={id}>{name}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.settingId}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col}>
              <Form.Label column>字数</Form.Label>
              <Col>
                <Form.Control
                  type='number'
                  name='numberOfCharactersPerRow'
                  value={values.numberOfCharactersPerRow}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.numberOfCharactersPerRow && errors.numberOfCharactersPerRow}
                  disabled={values.columnSetting === 'regular'}
                  size='sm'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.numberOfCharactersPerRow}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column>行数</Form.Label>
              <Col>
                <Form.Control
                  type='number'
                  name='numberOfRows'
                  value={values.numberOfRows}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.numberOfRows && errors.numberOfRows}
                  disabled={values.columnSetting === 'regular'}
                  size='sm'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.numberOfRows}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column>段数</Form.Label>
              <Col>
                <Form.Control
                  type='number'
                  name='numberOfColumns'
                  value={values.numberOfColumns}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.numberOfColumns && errors.numberOfColumns}
                  disabled={values.columnSetting === 'regular'}
                  size='sm'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.numberOfColumns}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Row>
          {
            currentUser.role === 'group_administrator' &&
              <Form.Group as={Row} className='align-items-center'>
                <Form.Label column className='col-2'>担当者</Form.Label>
                <Col>
                  <Form.Control
                    as='select'
                    type='text'
                    name='assigneeId'
                    value={values.assigneeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.assigneeId && errors.assigneeId}
                    disabled={userList?.length <= 1}
                    size='sm'
                  >
                    {userList?.map(({ id, name }) => (
                      <option key={id} value={id}>{name}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {errors.assigneeId}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
          }
          <Row className='mb-3'>
            <Form.Group as={Col} sm={4}>
              <Form.Label column className='issueDateLabel'>発行日</Form.Label>
              <Col>
                <Form.Control
                  type='date'
                  name='issueDate'
                  value={values.issueDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.issueDate && errors.issueDate}
                  size='sm'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.issueDate}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column className='name'>名称</Form.Label>
              <Col>
                <Form.Control
                  type='text'
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.name && errors.name}
                  size='sm'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.name}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label column>小組テンプレートファイル(indtファイル)</Form.Label>
              <Col>
                <TemplateFileControl
                  key='templateFileControl'
                  {...{ values, errors, handleBlur, handleChange, setFieldValue }}
                />
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label column>小組段数</Form.Label>
              <Col>
                <InputGroup size='sm' hasValidation>
                  <Form.Control
                    type='number'
                    name='layoutNumberOfColumns'
                    value={values.layoutNumberOfColumns}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.layoutNumberOfColumns && errors.layoutNumberOfColumns}
                    size='sm'
                  />
                  <InputGroup.Text>段</InputGroup.Text>
                  <Form.Control.Feedback type='invalid'>
                    {errors.layoutNumberOfColumns}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column>小組行数（罫線を含む）</Form.Label>
              <Col>
                <InputGroup size='sm' hasValidation>
                  <Form.Control
                    type='number'
                    name='layoutNumberOfRows'
                    value={values.layoutNumberOfRows}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.layoutNumberOfRows && errors.layoutNumberOfRows}
                  />
                  <InputGroup.Text>行</InputGroup.Text>
                  <Form.Control.Feedback type='invalid'>
                    {errors.layoutNumberOfRows}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column>小組組み方向</Form.Label>
              <Col>
                {Object.keys(enumValues.layoutOrientations).map(k => (
                  <Form.Check
                    key={`layoutOrientation-${k}`}
                    inline
                    label={enumValues.layoutOrientations[k]}
                    type='radio'
                    name='layoutOrientation'
                    checked={k === values.layoutOrientation}
                    value={k}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size='sm'
                  />
                ))}
              </Col>
            </Form.Group>
          </Row>
          <Form.Group as={Row} className='mt-2'>
            <Form.Label column className='col-2'>罫囲み</Form.Label>
            <Col>
              <Row>
                <Col className='align-items-center'>
                  <Form.Check
                    inline
                    type='radio'
                    name='useBorderLine'
                    label='あり'
                    value
                    checked={values.useBorderLine}
                    onChange={handleUseBorderLineChange}
                    onBlur={handleBlur}
                    size='sm'
                  />
                  <Form.Check
                    inline
                    type='radio'
                    name='useBorderLine'
                    label='なし'
                    value={false}
                    checked={!values.useBorderLine}
                    onChange={handleUseBorderLineChange}
                    onBlur={handleBlur}
                    size='sm'
                  />
                </Col>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label column sm='auto'>線幅</Form.Label>
                  <Col>
                    <InputGroup size='sm' hasValidation>
                      <Form.Control
                        type='number'
                        name='borderLineWidth'
                        value={values.borderLineWidth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.borderLineWidth && errors.borderLineWidth}
                        disabled={!values.useBorderLine}
                        size='sm'
                      />
                      <InputGroup.Text>mm</InputGroup.Text>
                      <Form.Control.Feedback type='invalid'>
                        {errors.borderLineWidth}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label column sm='auto'>線種</Form.Label>
                  <Col>
                    <Form.Control
                      as='select'
                      type='text'
                      name='borderLineType'
                      value={values.borderLineType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.borderLineType && errors.borderLineType}
                      disabled={!values.useBorderLine}
                      size='sm'
                    >
                      {Object.keys(enumValues.borderLineTypes).map(k => (
                        <option key={k} value={k}>{enumValues.borderLineTypes[k]}</option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      {errors.settingId}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Row>
              <Form.Group as={Row} className='align-items-center'>
                <Form.Label column sm='auto' className='justify-items-center'>線の位置</Form.Label>
                <Col>
                  {Object.keys(enumValues.lineAlignments).map(k => (
                    <Form.Check
                      key={`lineAlignments-${k}`}
                      inline
                      label={`${enumValues.lineAlignments[k]}に揃える`}
                      type='radio'
                      name='lineAlignment'
                      checked={k === values.lineAlignment}
                      value={k}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!values.useBorderLine}
                      size='sm'
                    />
                  ))}
                </Col>
              </Form.Group>
              <Row>
                <Col className='align-items-start'>
                  {boxInputs.map((d, i) => (
                    <Row className='align-items-start' style={{ paddingBottom: 5 }} key={`box-inputs-row-${i}`}>
                      <Col sm={1}>{d.title}</Col>
                      {d.keys.map(k => (
                        <Col key={`linePlacement-check-col-${k}`} sm='auto' style={{ display: 'flex' }}>
                          <Form.Check
                            key={`linePlacement-${k}`}
                            inline
                            type='radio'
                            name='linePlacement'
                            checked={k === values.linePlacement}
                            value={k}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!values.useBorderLine}
                            size='sm'
                          />
                          <div style={borderLinePlacementStyles[k]} />
                        </Col>
                      ))}
                    </Row>
                  ))}
                </Col>
              </Row>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={!isValid || fetching}>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}

SubLayoutUpsertForm.schema = Yup.object().shape({
  name: Yup.string().required(),
  mediaId: Yup.number().required(),
  settingId: Yup.number().required(),
  numberOfCharactersPerRow: Yup.number().required().min(10).max(30),
  numberOfRows: Yup.number().required().min(30).max(100),
  numberOfColumns: Yup.number().required().min(7).max(15),
  assigneeId: Yup.number().required(),
  templateFilename: Yup.string().nullable(true),
  deleteFile: Yup.boolean(),
  layoutNumberOfColumns: Yup.number().required().min(1),
  layoutNumberOfRows: Yup.number().required().min(1),
  layoutOrientation: Yup.string().required(),
  useBorderLine: Yup.boolean().required(),
  borderLineWidth: Yup.number().min(0),
  borderLineType: Yup.string(),
  lineAlignment: Yup.string(),
  linePlacement: Yup.string()
})
