import React from 'react'
import {
  Modal,
  Button,
  Table
} from 'react-bootstrap'
import _ from 'lodash'

export const SettingDeleteForm = ({
  onSubmit,
  fetching,
  title,
  submitButtonText,
  onClickCancel,
  setting
}) => (
  <>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>以下の紙面ベースを削除します。よろしいですか？</p>
      <Table striped bordered hover size='sm'>
        <thead>
          <tr>
            <td>ID</td>
            <td>名称</td>
          </tr>
        </thead>
        <tbody>
          {_.map(setting, ({ id, name }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='secondary' onClick={onClickCancel}>
        キャンセル
      </Button>
      <Button
        type='submit'
        disabled={fetching}
        onClick={onSubmit}
      >{submitButtonText}
      </Button>
    </Modal.Footer>
  </>
)
