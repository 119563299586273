import React, { useEffect } from 'react'
import {
  Col,
  Container,
  Form,
  Row
} from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import _ from 'lodash'
import {
  ButtonModals,
  Checkbox,
  DataList,
  ErrorModal,
  selectCommon
} from '../common'
import { selectAuth } from '../auth'
import {
  fetchUsers,
  modifyUsers,
  selectUser,
  selectColumn,
  selectPage,
  selectId,
  unselectId,
  openModal,
  closeModal,
  setSearchCondition,
  dismissError,
  UserEnableDisableForm,
  UserUpdateForm
} from '../user'
import { modifyUser } from './userAPI'
moment.locale('ja')

export const UserList = () => {
  const {
    data,
    currentPage,
    totalPages,
    sort,
    selectedIds,
    searchCondition,
    openedModal,
    fetching,
    errors
  } = useSelector(selectUser)
  const { currentUser } = useSelector(selectAuth)
  const { enumValues } = useSelector(selectCommon)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchUsers({ page: currentPage, sort, search: searchCondition }))
  }, [sort, currentPage, searchCondition])

  const handleColumnClick = columnName => () => dispatch(selectColumn(columnName))

  const handlePaginationClick = page => dispatch(selectPage(page))

  const columnHelper = createColumnHelper()

  const columns = [
    {
      id: 'checkboxColumn',
      cell: props => {
        const user = props.row.original
        const userId = parseInt(user.id)
        return (
          <Checkbox
            value={user.id}
            checked={selectedIds.includes(userId)}
            disabled={fetching || currentUser.id === userId}
            onChange={(value, checked) => { dispatch((checked ? selectId : unselectId)(parseInt(value))) }}
          />
        )
      }
    },
    { name: 'ID', accessorKey: 'id', sortKey: 'id' },
    { name: '名称', accessorKey: 'name', sortKey: 'name' },
    { name: 'メールアドレス', accessorKey: 'email', sortKey: 'email' },
    columnHelper.accessor('role', {
      name: 'ロール',
      sortKey: 'role',
      cell: info => enumValues.userRoles[_.camelCase(info.getValue())]
    }),
    columnHelper.accessor('confirmedAt', {
      name: 'メールアドレスの確認',
      cell: info => info.getValue()
        ? moment(info.getValue()).format('LLL') + ' 確認済み'
        : '未確認',
      sortKey: 'confirmedAt'
    }),
    { name: 'グループ', accessorFn: ({ group }) => group ? group.name : '-' },
    columnHelper.accessor('enable', {
      name: '有効 / 無効',
      cell: info => info.getValue() ? '有効' : '無効',
      sortKey: 'enable'
    })
  ]
  const handleDisableSubmit = () => {
    if (selectedIds.length < 1) {
      return
    }

    dispatch(modifyUsers({
      disablingUsers: { ids: selectedIds }
    }))
  }
  const handleEnableSubmit = () => {
    if (selectedIds.length < 1) {
      return
    }

    dispatch(modifyUsers({
      enablingUsers: { ids: selectedIds }
    }))
  }
  const singleSelectedUser = selectedIds.length === 1 ? _.find(data, { id: selectedIds[0] }) : null
  const isSelectedUserReadable = singleSelectedUser
  const buttonModalsEnabled = !fetching && currentUser.role !== 'operator'
  const handleUpdateUserSubmit = ({
    ...props
  }) => {
    // console.log('handleUpdateUserSubmit: props', props)
    dispatch(modifyUser({ user: props }))
  }
  const toolSettings = [
    {
      name: 'disable',
      button: {
        handleClick: () => {
          dispatch(openModal('disable'))
        },
        disabled: !buttonModalsEnabled || selectedIds.length === 0,
        icon: 'times',
        variant: 'outline-danger',
        text: '無効化'
      },
      form: {
        initialValues: {
          selectedIds
        },
        handleSubmit: handleDisableSubmit,
        Component: UserEnableDisableForm,
        props: {
          title: 'ユーザ無効化',
          fetching,
          selectedIds,
          users: data,
          onClickCancel: () => dispatch(closeModal()),
          message: '以下のユーザを無効化します。よろしいですか？',
          submitButtonText: '無効化する'
        }
      }
    },
    {
      name: 'enable',
      button: {
        handleClick: () => {
          dispatch(openModal('enable'))
        },
        disabled: !buttonModalsEnabled || selectedIds.length === 0,
        icon: 'user',
        text: '有効化'
      },
      form: {
        initialValues: {
          selectedIds
        },
        handleSubmit: handleEnableSubmit,
        Component: UserEnableDisableForm,
        props: {
          title: 'ユーザ有効化',
          fetching,
          selectedIds,
          users: data,
          onClickCancel: () => dispatch(closeModal()),
          message: '以下のユーザを有効化します。よろしいですか？',
          submitButtonText: '有効化する'
        }
      }
    },
    {
      name: 'edit',
      button: {
        handleClick: () => {
          dispatch(openModal('edit'))
        },
        disabled: !buttonModalsEnabled || !isSelectedUserReadable,
        icon: 'edit',
        text: 'ユーザー情報編集'
      },
      form: {
        initialValues: () => {
          if (!singleSelectedUser) {
            return
          }
          return {
            id: singleSelectedUser?.id,
            name: singleSelectedUser?.name,
            email: singleSelectedUser?.email,
            role: singleSelectedUser?.role
          }
        },
        handleSubmit: handleUpdateUserSubmit,
        Component: UserUpdateForm,
        validationSchema: UserUpdateForm.schema,
        props: {
          title: 'ユーザー情報編集',
          fetching,
          selectedIds,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '変更する',
          enumValues
        }
      }
    }
  ]

  return (
    <Container fluid>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      {buttonModalsEnabled &&
        <Row className='mb-3 align-items-center'>
          <Col sm='auto'>
            <ButtonModals
              settings={toolSettings}
              openedModal={openedModal}
            />
          </Col>
          <Col sm className='d-flex justify-content-end'>
            <Form>
              <Form.Check
                type='switch'
                id='includeDisabledSwitch'
                name='includeDisabled'
                label='無効なユーザを含めて表示'
                checked={searchCondition.includeDisabled}
                onChange={e => {
                  const newValue = e.target.checked
                  const name = 'includeDisabled'
                  dispatch(setSearchCondition({ name, value: newValue }))
                }}
              />
            </Form>
          </Col>
        </Row>}
      <DataList
        columns={columns}
        data={data}
        currentPage={currentPage}
        totalPages={totalPages}
        onColumnClick={handleColumnClick}
        sort={sort}
        onPaginationClick={handlePaginationClick}
      />
    </Container>
  )
}
