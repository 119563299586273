import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import {
  Container
} from 'react-bootstrap'
import {
  toSnake,
  Checkbox,
  DataList,
  ButtonModals,
  ErrorModal
} from '../common'
import { administratorRelatedFeaturesGranted, selectAuth } from '../auth'
import {
  selectSetting,
  selectColumn,
  selectPage,
  selectId,
  unselectId,
  openModal,
  closeModal,
  dismissError
} from './settingSlice'
import {
  fetchSettings,
  createSetting,
  modifySetting,
  deleteSetting,
  copySetting
} from './settingAPI'
import {
  fetchMedia,
  selectMedia,
  SettingUpsertForm,
  SettingDeleteForm,
  SettingCopyForm
} from '../media'

export const SettingList = () => {
  const {
    data,
    currentPage,
    totalPages,
    sort,
    selectedIds,
    openedModal,
    fetching,
    alert,
    errors
  } = useSelector(selectSetting)
  const { currentUser } = useSelector(selectAuth)
  const isAdministratorRelatedFeaturesGranted = administratorRelatedFeaturesGranted()
  const {
    data: mediaList,
    fetching: mediaFetching
  } = useSelector(selectMedia)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchMedia({}))
    dispatch(fetchSettings({ page: currentPage, sort }))
  }, [sort, currentPage])

  const handleColumnClick = columnName => () => dispatch(selectColumn(columnName))

  const handlePaginationClick = page => dispatch(selectPage(page))
  const isSettingShowable = (currentUser?.role === 'group_administrator')

  const columns = [
    {
      id: 'checkboxColumn',
      cell: props => <Checkbox
        value={props.row.original.id}
        checked={selectedIds.includes(parseInt(props.row.original.id))}
        onChange={(value, checked) => { dispatch((checked ? selectId : unselectId)(parseInt(value))) }}
                     />
    },
    { name: 'ID', accessorKey: 'id' },
    {
      name: '媒体名称',
      cell: props => props.row.original.media.name,
      accessor: row => row.media.name
    },
    {
      name: '紙面ベース名称',
      cell: props => {
        const setting = props.row.original
        return (
          isSettingShowable ? <a href={`/settings/${setting.id}`}>{setting.name}</a> : setting.name
        )
      },
      accessorKey: 'name',
      sortKey: 'name'
    }
  ]
  if (currentUser?.role === 'global_administrator') {
    columns.push({
      name: 'グループ',
      cell: props => (
        props.row.original.group.name
      )
    })
  }

  const handleCreateSubmit = ({
    name,
    mediaId,
    loadedBinaryFile
  }) => {
    const formData = new window.FormData()
    const newSetting = {
      mediaId,
      name
    }
    formData.append('setting', JSON.stringify(toSnake(newSetting)))
    if (loadedBinaryFile !== undefined) {
      const { blob, name } = loadedBinaryFile
      formData.append('file', blob, name)
    }
    dispatch(createSetting({ navigate, formData }))
  }
  const handleEditSubmit = ({
    name,
    mediaId,
    loadedBinaryFile
  }) => {
    if (selectedIds.length < 1) {
      return
    }
    const settingId = selectedIds[0]
    const newSetting = {
      mediaId,
      name
    }
    const formData = new window.FormData()
    formData.append('setting', JSON.stringify(toSnake(newSetting)))
    if (loadedBinaryFile !== undefined) {
      const { blob, name } = loadedBinaryFile
      formData.append('file', blob, name)
    }
    dispatch(modifySetting({ navigate, settingId, formData }))
  }
  const handleCopySubmit = ({
    newName
  }) => {
    if (selectedIds.length < 1) {
      return
    }
    const id = selectedIds[0]
    dispatch(copySetting({ id, name: newName, navigate }))
  }

  const handleDeleteSubmit = () => dispatch(deleteSetting({ id: selectedIds[0] }))
  const toolSettings = [
    {
      name: 'create',
      button: {
        handleClick: () => dispatch(openModal('create')),
        disabled: mediaFetching || !isAdministratorRelatedFeaturesGranted,
        icon: 'plus',
        text: '新規'
      },
      form: {
        initialValues: {
          name: '',
          mediaId: mediaList?.length > 0 ? mediaList[0].id : ''
        },
        handleSubmit: handleCreateSubmit,
        validationSchema: SettingUpsertForm.schema,
        Component: SettingUpsertForm,
        props: {
          title: '紙面ベース新規作成',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '作成する',
          mediaList,
          alert
        }
      }
    },
    {
      name: 'edit',
      button: {
        handleClick: () => dispatch(openModal('edit')),
        disabled: mediaFetching || !isAdministratorRelatedFeaturesGranted || selectedIds.length !== 1,
        icon: 'pencil',
        text: '編集'
      },
      form: {
        initialValues: () => {
          const selectedSetting = _.find(data, { id: selectedIds[0] })
          if (!selectedSetting) {
            return {}
          }
          // console.log('selectedSetting', selectedSetting)
          return {
            name: selectedSetting.name,
            mediaId: selectedSetting.media.id
          }
        },
        handleSubmit: handleEditSubmit,
        validationSchema: SettingUpsertForm.schema,
        Component: SettingUpsertForm,
        props: {
          title: '紙面ベース編集',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '変更する',
          mediaList,
          alert
        }
      }
    },
    {
      name: 'copy',
      button: {
        handleClick: () => {
          dispatch(openModal('copy'))
        },
        disabled: mediaFetching || !isAdministratorRelatedFeaturesGranted || selectedIds.length !== 1,
        icon: 'copy',
        text: '複製'
      },
      form: {
        initialValues: () => {
          const selectedSetting = _.find(data, { id: selectedIds[0] })
          if (!selectedSetting) {
            return {}
          }
          return {
            name: selectedSetting.name,
            newName: `${selectedSetting.name}のコピー`
          }
        },
        handleSubmit: handleCopySubmit,
        validationSchema: SettingCopyForm.schema,
        Component: SettingCopyForm,
        props: {
          title: '紙面ベース複製',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '複製する'
        }
      }
    },
    {
      name: 'delete',
      button: {
        handleClick: () => dispatch(openModal('delete')),
        disabled: mediaFetching || !isAdministratorRelatedFeaturesGranted || selectedIds.length !== 1,
        icon: 'trash',
        variant: 'outline-danger',
        text: '削除'
      },
      form: {
        handleSubmit: handleDeleteSubmit,
        Component: SettingDeleteForm,
        props: {
          title: '紙面ベース削除',
          fetching,
          onClickCancel: () => dispatch(closeModal()),
          submitButtonText: '削除する',
          setting: _.filter(data, setting => selectedIds.includes(setting.id))
        }
      }
    }
  ]
  return (
    <Container fluid>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <ButtonModals
        settings={toolSettings}
        openedModal={openedModal}
      />
      <DataList
        columns={columns}
        data={data}
        currentPage={currentPage}
        totalPages={totalPages}
        onColumnClick={handleColumnClick}
        sort={sort}
        onPaginationClick={handlePaginationClick}
      />
    </Container>
  )
}
