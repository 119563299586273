const { v4: uuidv4 } = require('uuid')

/* this version uses response json */
export const throwErrorForErrorResponse = async (response) => {
  if (!response.ok) {
    const error = new Error(`${response.status} ${response.statusText}`)
    error.status = response.status
    error.statusText = response.statusText
    const contentType = response.headers.get('Content-Type')
    if (contentType && contentType.startsWith('application/json')) {
      const responseBody = await response.json()
      if (responseBody.errors) {
        error.message = responseBody.errors.message
      }
    }
    throw error
  }
  return response
}

/* this version doesn't try to parse json */
export const throwTextErrorForErrorResponse = async (response) => {
  if (!response.ok) {
    const error = new Error(`${response.status} ${response.statusText}`)
    error.status = response.status
    error.statusText = response.statusText
    throw error
  }
  return response
}

export const errorHandler = rejectWithValue => error => rejectWithValue({
  message: error.message,
  status: error.status,
  statusText: error.statusText
})

export const initialErrorState = { errors: {} }

export const saveErrorInState = reducer => (state, action) => {
  // eslint-disable-next-line no-undef
  state.errors[uuidv4()] = action.payload
  reducer(state, action)
}

export const dismissError = (state, action) => {
  delete state.errors[action.payload]
}
