import { useSelector } from 'react-redux'
import { selectAuth } from '../auth'

export const shouldShowExperimentalFeatures = () => {
  const { groupType, currentUser } = useSelector(selectAuth)
  return currentUser?.role === 'global_administrator' || groupType === 'experimental'
}

const administratorRelatedFeaturesGrantedRoles = new Set(['global_administrator', 'group_administrator'])

export const administratorRelatedFeaturesGranted = () => {
  const { currentUser } = useSelector(selectAuth)
  return administratorRelatedFeaturesGrantedRoles.has(currentUser?.role)
}
