import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Alert,
  Button,
  Form,
  Row,
  Col
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TagEditor } from '../article'
import {
  modifyTemplateArticle,
  deleteFigure
} from './articleAPI'
import { selectCell } from './articleSlice'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
import { shouldShowExperimentalFeatures } from '../auth'
Yup.setLocale(ja.suggestive)

const getTempArticleFigureRows = ({
  dispatch,
  layoutableType,
  layoutableId,
  layoutableLockVersion,
  holderArticle,
  isSelectedLayoutableWritable,
  idx,
  tempArticle
}) => {
  let tempArticleFigRowControls = (<></>)
  if (tempArticle?.location?.length > 0) {
    // image is already assigned.
    const handleDeleteClick = (_e) => {
      const newTemplateArticle = {
        ...tempArticle,
        deleteFile: true,
        location: null
      }
      dispatch(modifyTemplateArticle({ layoutableType, layoutableId, lockVersion: layoutableLockVersion, templateArticle: newTemplateArticle }))
    }
    tempArticleFigRowControls = (
      <>
        <br />
        <Button
          size='sm'
          disabled={!isSelectedLayoutableWritable}
          onClick={handleDeleteClick}
          variant='danger'
        >
          <FontAwesomeIcon icon='trash' fixedWidth />
          画像を削除する
        </Button>
      </>
    )
  } else {
    const matchingHolderFigure = holderArticle?.figures?.find(f => f.filename === tempArticle.filename)
    if (!matchingHolderFigure) {
      tempArticleFigRowControls = (
        <Alert variant='danger'>「{tempArticle.filename}」を「複数記事追加」からアップロードしてください。</Alert>
      )
    } else {
      const handleClick = (_e) => {
        const delFigure = {
          id: matchingHolderFigure.id,
          keepFile: true
        }
        const newTemplateArticle = {
          ...tempArticle,
          location: matchingHolderFigure.location
        }
        console.log('assign template figure', { delFigure, newTemplateArticle })
        dispatch(modifyTemplateArticle({ layoutableType, layoutableId, lockVersion: layoutableLockVersion, templateArticle: newTemplateArticle }))
        dispatch(deleteFigure({ layoutableType, layoutableId, figure: delFigure }))
      }
      tempArticleFigRowControls = (
        <>
          <br />
          <Button
            size='sm'
            disabled={!isSelectedLayoutableWritable}
            onClick={handleClick}
          >
            割り当てる
          </Button>
        </>
      )
    }
  }
  return (
    <Row>
      <Col sm='auto'>{idx + 1}</Col>
      <Col>ファイル名: {tempArticle.filename}
        {tempArticleFigRowControls}
      </Col>
    </Row>
  )
}

export const ArticleTemplateArticlesForm = ({
  // values,
  // errors,
  // touched,
  // isValid,
  // handleChange,
  // handleBlur,
  handleSubmit,
  isSelectedLayoutableWritable,
  // setFieldValue,
  article,
  layoutable,
  layoutableType,
  layoutableId,
  holderArticle
  // ...props
}) => {
  if (!article?.templateArticles?.length) {
    return (<>在版記事なし</>)
  }
  const dispatch = useDispatch()
  const F = React.Fragment
  const editable = shouldShowExperimentalFeatures()
  const layoutableLockVersion = layoutable?.lockVersion
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <div>フレーム数: {article.templateArticles.length}</div>
      {article.templateArticles.map((tempArticle, idx) => {
        const onTemplateArticleContentSubmit = (taggedContent) => {
          const newTemplateArticle = {
            ...tempArticle,
            taggedContent
          }
          dispatch(modifyTemplateArticle({ layoutableType, layoutableId, lockVersion: layoutableLockVersion, templateArticle: newTemplateArticle }))
          dispatch(selectCell(null))
        }
        if (tempArticle.templateArticleType === 'figure') {
          const tempArticleFigureRows = getTempArticleFigureRows({
            layoutableType, layoutableId, layoutableLockVersion, holderArticle, isSelectedLayoutableWritable, dispatch, tempArticle, idx
          })
          return (
            <F key={`tempArticle-${idx}`}>
              <hr />
              {tempArticleFigureRows}
            </F>
          )
        }
        return (
          <F key={`tempArticle-${idx}`}>
            <hr />
            <Row>
              <Col sm='auto'>{idx + 1}</Col>
              <Col>
                <TagEditor
                  key={`tempArticle-tag-editor-${idx}`}
                  initialTaggedContent={tempArticle.taggedContent}
                  setting={layoutable.setting}
                  styleTagType='sentence'
                  onSubmit={onTemplateArticleContentSubmit}
                  disabled={!isSelectedLayoutableWritable}
                  editable={editable}
                  allowLineBreak
                  allowMultiParagraph
                />
              </Col>
            </Row>
          </F>
        )
      })}
    </Form>
  )
}

ArticleTemplateArticlesForm.schema = Yup.object().shape({
  layoutableType: Yup.string().required(),
  layoutableId: Yup.number().required()
})
