import React from 'react'
import {
  Form,
  Row,
  Col,
  InputGroup,
  Modal,
  Button,
  Alert
} from 'react-bootstrap'
import { handleBinaryFile } from '../article'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

export const SettingUpsertForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isValid,
  fetching,
  onClickCancel,
  title,
  submitButtonText,
  mediaList,
  alert,
  ...props
}) => {
  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (!file) return

    const loadedBinaryAsync = handleBinaryFile(file)

    loadedBinaryAsync
      .then(value => {
        console.log('loadedBinaryFile', value)
        setFieldValue('loadedBinaryFile', value)
      })

    handleChange(e)
  }
  return (
    <>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {alert?.show && alert?.alertBody &&
        <Alert
          className='upsert-setting-errors-alert'
          variant='danger'
        >
          {alert.alertBody}
        </Alert>}
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column className='col-3'>媒体名</Form.Label>
            <Col>
              <Form.Control
                as='select'
                type='text'
                name='mediaId'
                value={values.mediaId}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.mediaId && errors.mediaId}
                disabled={mediaList?.length <= 1}
              >
                {mediaList?.map(({ id, name }) => (
                  <option key={id} value={id}>{name}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type='invalid'>
                {errors.mediaId}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column className='col-3'>名称</Form.Label>
            <Col>
              <Form.Control
                type='text'
                name='name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.name && errors.name}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3'>
            <Form.Label column className='col-3'>紙面ベースファイル</Form.Label>
            <Col>
              <InputGroup hasValidation>
                <Form.Control
                  id='settingFileInput'
                  name='settingFile'
                  type='file'
                  accept='.zip'
                  values={values.settingFile ?? ''}
                  onChange={handleFileChange}
                  onBlur={handleBlur}
                />
              </InputGroup>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={!isValid || fetching}>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}

SettingUpsertForm.schema = Yup.object().shape({
  name: Yup.string().required(),
  mediaId: Yup.number().required(),
  settingFilename: Yup.string().nullable(true)
})
