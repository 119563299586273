import { library, dom, icon } from '@fortawesome/fontawesome-svg-core'
import { fas, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas, far)
dom.watch()

const togglePasswordHidden = () => {
  const passwordInput = document.getElementById('user_password')
  const hidden = passwordInput.getAttribute('type') === 'password'

  const newInputType = hidden ? 'text' : 'password'
  passwordInput.setAttribute('type', newInputType)

  const currentIcon = document.querySelector('#user_password_toggle > svg')
  const newIcon = icon(hidden ? faEyeSlash : faEye).node[0]
  currentIcon.parentNode.replaceChild(newIcon, currentIcon)
}

window.togglePasswordHidden = togglePasswordHidden

export * from './authAPI'
export * from './authSlice'
export * from './AuthDropdown'
export * from './helpers'
