import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Table
} from 'react-bootstrap'
import {
  ErrorModal
} from '../common'
import {
  selectMedia,
  dismissError
} from './mediaSlice'
import {
  fetchMediaEngineStats
} from './mediaAPI'
import moment from 'moment'
moment.locale('ja')

const EngineStatsContainer = ({
  engineStats
}) => {
  if (Object.keys(engineStats?.pageEngineCountStats).length === 0) {
    return (
      <div className='fs-4'>紙面割り付け実行履歴無し</div>
    )
  }
  return (
    <Row sm='auto'>
      <Col sm='auto'>
        <div className='fs-4'>紙面割り付け実行回数</div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>年月</th>
              <th>実行回数</th>
              <th>成功回数</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(engineStats?.pageEngineCountStats).map(month =>
              <tr key={`stats_${month}`}>
                <td>{moment(month).format('YYYY年M月')}</td>
                <td>{engineStats?.pageEngineCountStats?.[month]}</td>
                <td>{engineStats?.pageEngineSuccessesStats?.[month]}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export const MediaEngineStats = () => {
  const { mediaId: mediaIdString } = useParams()
  const mediaId = parseInt(mediaIdString)
  // console.log(mediaId)
  const {
    errors,
    engineStats: engineStatsObj,
    fetching
  } = useSelector(selectMedia)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchMediaEngineStats({ mediaId }))
  }, [])
  console.log('engineStats', { engineStatsObj })
  if (fetching || !engineStatsObj) {
    return
  }
  const { engineStats, media } = engineStatsObj
  console.log('engineStats', { engineStats })
  return (
    <Container fluid>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <Row className='align-items-center'>
        <Col className='fs-3 mr-auto'>
          媒体名称: {media.name}
        </Col>
      </Row>
      <EngineStatsContainer engineStats={engineStats} />
    </Container>
  )
}
