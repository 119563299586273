import * as svgjs from '@svgdotjs/svg.js'

/*
  interfaces
 */
interface ArticleColor {
  heading: string
  figure: string
  writing: string
}

interface EffectAttributes {
  fill?: string | null
  'fill-opacity'?: number | null
  stroke?: string | null
  'stroke-width'?: number | null
  'stroke-opacity'?: number | null
}

type LayoutInfoType = 'left-page-spread' | 'sub-layout'

interface LayoutInfo {
  type: LayoutInfoType
  columnSetting: string
  numberOfCharactersPerRow: number
  numberOfRows: number
  numberOfColumns: number
  numberOfFolds: number
  columnSpace: number // mm
  articles: any // TODO: add type
  dividerLines: any // TODO: add type
  columnRuleLines: AreaInfo[]
}

interface LayoutableInfo {
  name: string
}

interface AreaInfo {
  top: number
  topSubUnit: number
  left: number
  leftSubUnit: number
  bottom: number
  bottomSubUnit: number
  right: number
  rightSubUnit: number
}

interface SizeInfo {
  w: number
  h: number
}

interface SegmentDrawResult {
  group: svgjs.G
  rowsUsed: number
}

interface SegmentEventHandlers {
  onClickSegment: (e: any) => void
  onMouseOverSegment: (e: any) => void
  onMouseOutSegment: (e: any) => void
}

interface FigureInfo {
  id: string
  area: AreaInfo
}

interface FigureDetailInfo {
  id: string
  filename: string
}

interface ArticleInfo {
  id: string
  headingArea: AreaInfo
  figures: FigureInfo[]
  areas: AreaInfo[]
}

interface ArticleDetailInfo {
  id: string
}

const FontAwesomeLockPath: string =
  'M144 144l0 48 160 0 0-48c0-44.2-35.8-80-80-80s-80 35.8-80 ' +
  '80zM80 192l0-48C80 64.5 144.5 0 224 0s144 64.5 144 144l0 48 16 ' +
  '0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64L64 512c-35.3 ' +
  '0-64-28.7-64-64L0 256c0-35.3 28.7-64 64-64l16 0z'

/*
  constants
 *

/*
  coordinate system (in japanese news paper)
  - column is horizontal split unit, increase from top to bottom
  - row is vertical split unit, increase from right to left
*/

const mm: number = 1.0
const uQ: number = 0.25 * mm // Q(級) unit mm
const uH: number = 0.25 * mm // H(歯) unit mm

const SVG_WIDTH: number = 1200
const SVG_HEIGHT: number = 1200
const SVG_BG_COLOR: string = 'ghostwhite'
const SVG_BG_LINE_COLOR: string = 'lightgray'
const SVG_BG_LINE_WIDTH: number = 0.7

/* eslint-disable @typescript-eslint/no-extraneous-class */
// This section is planned to be separated into a different module in the future.
class Ruler {
  static readonly LINE_COLOR: string = 'black'
  static readonly LINE_WIDTH: number = 0.5
  static readonly FONT_SIZE: number = 7.0 * mm

  static readonly HORIZONTAL_MARK_HEIGHT: number = 8.0 * mm
  static readonly HORIZONTAL_MARK_INTERVAL: number = 10
  static readonly HORIZONTAL_SUB_MARK_HEIGHT: number = 4.0 * mm
  static readonly HORIZONTAL_SUB_MARK_INTERVAL: number = 10
  static readonly HORIZONTAL_SUB_MARK_OFFSET: number = 5

  static readonly VERTICAL_MARK_WIDTH: number = 8.0 * mm
  static readonly VERTICAL_MARK_INTERVAL: number = 1
  static readonly VERTICAL_SUB_MARK_WIDTH: number = 4.0 * mm
}

class Indicator {
  static readonly FONT_SIZE: number = 8.0 * mm
}
/* eslint-enable @typescript-eslint/no-extraneous-class */

const CHARACTER_SIZE_MAX: number = 18 // display character size max(mm)
const VERTICAL_TEXT_ASPECT_RATIO = 1.33 // ratio for determining whether vertical or horizontal
const TITLE_CHARACTER_SIZE: number = 9

const HATCHING_SCALE = 8
const HATCHING_RATIO = 1.5
const HATCHING_LINE_WIDTH = 0.15
const DEFAULT_HATCHING_COLOR = '#000'
const DEFAULT_HATCHING_ID = 'layout-default-hatching'

const ArticleColors: ArticleColor[] = [
  { heading: '#c88c50', figure: '#d2965a', writing: '#aa6e32' },
  { heading: '#508c8c', figure: '#5a9696', writing: '#326e6e' },
  { heading: '#c85050', figure: '#d25a5a', writing: '#aa3232' },
  { heading: '#8c5050', figure: '#965a5a', writing: '#6e3232' },
  { heading: '#50c8c8', figure: '#5ad2d2', writing: '#32aaaa' },
  { heading: '#c8508c', figure: '#d25a96', writing: '#aa326e' },
  { heading: '#508cc8', figure: '#5a96d2', writing: '#326eaa' },
  { heading: '#8c50c8', figure: '#965ad2', writing: '#6e32aa' },
  { heading: '#c88c8c', figure: '#d29696', writing: '#aa6e6e' },
  { heading: '#50c88c', figure: '#5ad296', writing: '#32aa6e' },
  { heading: '#8cc850', figure: '#96d25a', writing: '#6eaa32' },
  { heading: '#8cc8c8', figure: '#96d2d2', writing: '#6eaaaa' }
]

const LockedArticleColor: ArticleColor =
  { heading: '#cccccc', figure: '#dddddd', writing: '#aaaaaa' }

const ARTICLE_OPACITY: number = 0.8
const DIVIDER_LINE_COLOR: string = '#000000'
const DIVIDER_LINE_SEGMENT_COLOR: string = '#bbbbbb'
const DIVIDER_LINE_OPACITY: number = 0.8
const COLUMN_LINE_COLOR: string = '#330000'
const COLUMN_LINE_WIDTH: number = 0.7
const EFFECT_ELEMENT_CLASS_NAME: string = 'effect'

const SelectedAttributes: EffectAttributes = {
  fill: '#f99',
  'fill-opacity': 0.5
}

const UnSelectedAttributes: EffectAttributes = {
  fill: undefined,
  'fill-opacity': 0.0
}

const HoveredAttributes: EffectAttributes = {
  stroke: '#000',
  'stroke-opacity': 0.5,
  'stroke-width': 1.2
}

const UnHoveredAttributes: EffectAttributes = {
  stroke: undefined,
  'stroke-width': 0.0
}

const NormalAttributes: EffectAttributes = {
  fill: undefined,
  'fill-opacity': 0.0,
  stroke: undefined,
  'stroke-width': 0.0
}

const BaseFont = {
  family: 'sans-serif'
}

/*
  utility functions
 */

function truthy (str: string): boolean {
  return str !== undefined && str !== '' && str !== null
}

function sizeWithSpacing (objSize: number, space: number, numObjs: number): number {
  return objSize * numObjs + space * (Math.floor(numObjs) - 1)
}

function fitTo (parent: svgjs.Svg, child: svgjs.G): void {
  const childIsPortrait = child.width() < child.height()
  child.size(
    childIsPortrait ? undefined : parent.width(),
    childIsPortrait ? parent.height() : undefined
  )
}

function createHatchingPattern (svg: svgjs.Svg, color: string, scale: number, ratio: number): svgjs.Pattern {
  const w = scale * ratio
  const h = scale / ratio
  const attributes = {
    stroke: color,
    'stroke-width': HATCHING_LINE_WIDTH
  }

  return svg.pattern(w, h, add => {
    add.line(0, h / 2, w / 2, h).attr(attributes)
    add.line(w / 2, 0, w, h / 2).attr(attributes)
  })
}

function isAreaInfo (arg: object): arg is AreaInfo {
  return arg === undefined
    ? false
    : ['top', 'left', 'bottom', 'right', 'topSubUnit', 'leftSubUnit', 'rightSubUnit', 'bottomSubUnit']
        .every(function (key: string): boolean {
          return Object.keys(arg).includes(key)
        })
}

function oldAreaTonNew (area: any): AreaInfo {
  return {
    ...area,
    topSubUnit: area?.topSubUnit !== undefined ? area.topSubUnit : 0,
    leftSubUnit: area?.leftSubUnit !== undefined ? area.leftSubUnit : 0,
    bottomSubUnit: area?.bottomSubUnit !== undefined ? area.bottomSubUnit : 0,
    rightSubUnit: area?.rightSubUnit !== undefined ? area.rightSubUnit : 0
  }
}

function layoutObjToLayoutInfo (layoutObj: any): LayoutInfo {
  const layoutInfo: LayoutInfo = {
    ...layoutObj,
    articles: layoutObj.articles.map((article) => ({
      ...article,
      areas: article.areas.map(oldAreaTonNew),
      figures: article.figures.map((figure) => ({
        ...figure,
        area: {
          ...oldAreaTonNew(figure.area)
        }
      })),
      headingAreas: article.headingAreas?.map(oldAreaTonNew)
    })),
    columnRuleLines: layoutObj.columnRuleLines?.map(oldAreaTonNew),
    dividerLines: layoutObj.dividerLines?.map(oldAreaTonNew),
    numberOfFolds: layoutObj?.numberOfFolds !== undefined ? layoutObj.numberOfFolds : layoutObj.numberOfColumns
  }
  return layoutInfo
}

/*
  Layoutable classes
 */
class Layoutable {
  // Layoutable Info
  title: string

  // Layout Info
  type: string
  columnSetting: string
  numberOfCharactersPerRow: number
  numberOfRows: number
  numberOfColumns: number
  numberOfFolds: number
  columnSpace: number // in mm value
  columnRuleLines: AreaInfo[]

  rowSpace: number = 3 * uH // in mm value
  characterSize: [number, number] = [15 * uQ, 15 * uQ] // in mm value
  characterRatio: [number, number] = [1.0, 0.8]
  characterSpacing: number = 0.0 // in mm value
  characterSizeOnScreen: [number, number]

  articles: Article[] = []
  dividerLines: DividerLine[] = []

  onRowColIndicatorUpdate: (row: number, col: number) => void

  constructor (
    layoutableInfo: LayoutableInfo, layoutInfo: LayoutInfo, onRowColIndicatorUpdate: (row: number, col: number) => void) {
    this.title = layoutableInfo.name

    this.type = layoutInfo.type
    this.columnSetting = layoutInfo.columnSetting
    this.numberOfCharactersPerRow = layoutInfo.numberOfCharactersPerRow
    this.numberOfRows = layoutInfo.numberOfRows
    this.numberOfColumns = layoutInfo.numberOfColumns
    this.numberOfFolds = layoutInfo.numberOfFolds
    this.columnSpace = layoutInfo.columnSpace

    this.characterSizeOnScreen = [
      this.characterSize[0] * this.characterRatio[0],
      this.characterSize[1] * this.characterRatio[1]
    ]

    this.columnSpace = layoutInfo.columnSpace * this.characterSizeOnScreen[1]

    this.columnRuleLines = layoutInfo.columnRuleLines
    this.onRowColIndicatorUpdate = onRowColIndicatorUpdate
  }

  colHeight (): number {
    return sizeWithSpacing(
      this.characterSizeOnScreen[1], this.characterSpacing, this.numberOfCharactersPerRow)
  }

  rowsWidth (rows: number): number {
    return sizeWithSpacing(
      this.characterSizeOnScreen[0], this.rowSpace, rows)
  }

  colsHeight (cols: number): number {
    return sizeWithSpacing(
      this.colHeight(), this.columnSpace, cols)
  }

  layoutableWidth (): number {
    return this.rowsWidth(this.numberOfRows)
  }

  layoutableHeight (): number {
    return this.colsHeight(this.numberOfFolds)
  }

  createSegmentRect (area: AreaInfo, svg: svgjs.Svg): svgjs.Rect {
    const y1 = (this.colHeight() + this.columnSpace) * area.top + area.topSubUnit * this.characterSizeOnScreen[1]
    const y2 = area.bottomSubUnit === 0
      ? this.colsHeight(area.bottom + 1)
      : (this.colHeight() + this.columnSpace) * area.bottom + area.bottomSubUnit * this.characterSizeOnScreen[1]
    const rows = area.left - area.right + 1
    const rect = svg.rect(
      this.rowsWidth(rows), y2 - y1)

    rect.move(
      this.layoutableWidth() - this.rowsWidth(area.left + 1),
      y1
    )
    return rect
  }

  minimumHeadingSize (): SizeInfo {
    return {
      w: this.rowsWidth(1),
      h: this.colsHeight(1) - this.characterSizeOnScreen[1] * 2
    }
  }

  drawHorizontalRuler (svg: svgjs.Svg): svgjs.G {
    const rulerGroup = svg.group()
    const rulerLine = svg.line(0, 0, this.layoutableWidth(), 0)
      .stroke({ width: Ruler.LINE_WIDTH, color: Ruler.LINE_COLOR })
    rulerGroup.add(rulerLine)

    // marks
    const numMarks: number = Math.ceil(this.numberOfRows / Ruler.HORIZONTAL_MARK_INTERVAL)
    const markIndices: number[] = [...Array.from(
      { length: numMarks }, (_, i) => i * Ruler.HORIZONTAL_MARK_INTERVAL), this.numberOfRows]

    markIndices.forEach((i) => {
      const x = Math.min(this.layoutableWidth() - this.rowsWidth(i), this.layoutableWidth())
      const markLine = svg.line(x, 0, x, -Ruler.HORIZONTAL_MARK_HEIGHT)
        .stroke({ width: Ruler.LINE_WIDTH, color: Ruler.LINE_COLOR })
      rulerGroup.add(markLine)

      if (i === 0) {
        return
      }

      const markText = svg.text(add => {
        add.tspan(Math.trunc(i).toString()).font({
          size: `${Ruler.FONT_SIZE}px`
        })
      })
      markText.x(x + 1)
      markText.dy(-2)
      rulerGroup.add(markText)
    })

    // sub marks
    const numSubMarks: number = Math.ceil(
      (this.numberOfRows - Ruler.HORIZONTAL_SUB_MARK_OFFSET) /
        Ruler.HORIZONTAL_SUB_MARK_INTERVAL)
    const subMarkIndices: number[] = [...Array.from(
      { length: numSubMarks }, (_, i) => i * Ruler.HORIZONTAL_SUB_MARK_INTERVAL + Ruler.HORIZONTAL_SUB_MARK_OFFSET)]

    subMarkIndices.forEach((i) => {
      const x = Math.min(this.layoutableWidth() - this.rowsWidth(i), this.layoutableWidth())
      const markLine = svg.line(x, 0, x, -Ruler.HORIZONTAL_SUB_MARK_HEIGHT)
        .stroke({ width: Ruler.LINE_WIDTH, color: Ruler.LINE_COLOR })
      rulerGroup.add(markLine)
    })

    return rulerGroup
  }

  drawHorizontalUpperRulerMarker (svg: svgjs.Svg): svgjs.G {
    const markerGroup = svg.group()
    const marker = svg.polygon(
      `0,-${Ruler.HORIZONTAL_SUB_MARK_HEIGHT} ` +
      `${this.rowsWidth(2) / 2},0 ` +
      `${this.rowsWidth(2)},-${Ruler.HORIZONTAL_SUB_MARK_HEIGHT} `
    ).fill('gray').stroke({ width: 0.5, color: 'black' })
    markerGroup.add(marker)
    return markerGroup
  }

  drawVerticalRightRuler (svg: svgjs.Svg): svgjs.G {
    const rulerGroup = svg.group()
    const rulerLine = svg.line(0, 0, 0, this.layoutableHeight())
      .stroke({ width: Ruler.LINE_WIDTH, color: Ruler.LINE_COLOR })
    rulerGroup.add(rulerLine)

    const numMarks: number = Math.ceil(this.numberOfColumns / Ruler.VERTICAL_MARK_INTERVAL)
    const markIndices: number[] = [...Array.from(
      { length: numMarks }, (_, i) => i * Ruler.VERTICAL_MARK_INTERVAL), this.numberOfColumns]

    markIndices.forEach((i) => {
      const y = Math.min(this.colsHeight(i) + this.columnSpace, this.layoutableHeight())
      const markLine = svg.line(0, y, Ruler.VERTICAL_MARK_WIDTH, y)
        .stroke({ width: Ruler.LINE_WIDTH, color: Ruler.LINE_COLOR })
      rulerGroup.add(markLine)

      if (i === 0) {
        return
      }

      const markText = svg.text(add => {
        add.tspan(Math.trunc(i).toString()).font({
          size: `${Ruler.FONT_SIZE}px`
        })
      })
      markText.y(y - Ruler.FONT_SIZE - 1)
      rulerGroup.add(markText)
    })

    return rulerGroup
  }

  drawVerticalRightRulerMarker (svg: svgjs.Svg): svgjs.G {
    const markerGroup = svg.group()
    const marker = svg.polygon(
      `${Ruler.VERTICAL_SUB_MARK_WIDTH},0 ` +
      `0,${this.rowsWidth(2) / 2} ` +
      `${Ruler.VERTICAL_SUB_MARK_WIDTH},${this.rowsWidth(2)} `
    ).fill('gray').stroke({ width: 0.5, color: 'black' })
    markerGroup.add(marker)
    return markerGroup
  }

  drawColRowIndicator (svg: svgjs.Svg): svgjs.G {
    const indicatorText = svg.plain('段, 行')
      .attr({ x: 27, y: 10 }).addClass('indicator-text')
      .font({ size: `${Indicator.FONT_SIZE}px`, anchor: 'middle' })
    const indicatorRect = svg.rect(54, 15)
      .radius(3).fill('white').stroke({ width: 1.0, color: 'black' })
    const indicatorGroup = svg.group()
    indicatorGroup.add(indicatorRect)
    indicatorGroup.add(indicatorText)

    return indicatorGroup
  }

  draw (svg: svgjs.Svg, fit: boolean = true): void {
    const layoutableGroup = svg.group()
    const layoutableBack = svg.rect(
      this.layoutableWidth(), this.layoutableHeight())
    layoutableBack.fill(SVG_BG_COLOR)
    layoutableGroup.add(layoutableBack)

    // coordinate indicator
    const colRowIndicator = this.drawColRowIndicator(svg)
    svg.add(colRowIndicator)
    colRowIndicator.hide()

    // draw col space line
    for (let colIdx = 1; colIdx < this.numberOfFolds; colIdx++) {
      const y = (this.colHeight() + this.columnSpace) * colIdx - this.columnSpace / 2
      const x1 = 0
      const x2 = Number(layoutableBack.width())
      const line = svg.line(x1, y, x2, y)
        .stroke({ width: SVG_BG_LINE_WIDTH, color: SVG_BG_LINE_COLOR })
        .attr({ 'stroke-dasharray': '1' })
      layoutableGroup.add(line)
    }

    // create default hatching pattern
    const defaultHatching = createHatchingPattern(svg, DEFAULT_HATCHING_COLOR, HATCHING_SCALE, HATCHING_RATIO)
    defaultHatching.id(DEFAULT_HATCHING_ID)

    // draw articles
    this.articles?.forEach((article) => {
      const articleDrawing = article.draw(this, svg)
      layoutableGroup.add(articleDrawing)
    })

    // draw dividerLines
    this.dividerLines?.forEach((dividerLine) => {
      const dividerLineDrawing = dividerLine.draw(this, undefined, svg, 0).group
      layoutableGroup.add(dividerLineDrawing)
    })

    // draw columnRuleLines
    this.columnRuleLines?.forEach(columnRuleLine => {
      const x1 = this.layoutableWidth() - this.rowsWidth(columnRuleLine.left + 1)
      const rows = columnRuleLine.left - columnRuleLine.right + 1
      const x2 = x1 + this.rowsWidth(rows)
      const y = (this.colHeight() + this.columnSpace) * columnRuleLine.bottom - this.columnSpace / 2
      const line = svg.line(x1, y, x2, y).stroke({ width: COLUMN_LINE_WIDTH, color: COLUMN_LINE_COLOR })
      layoutableGroup.add(line)
    })

    // draw layoutable title
    const titleText = svg.text(add => {
      add.tspan(this.title).font({
        size: `${TITLE_CHARACTER_SIZE}px`
      })
    })
    titleText.y(-titleText.bbox().h - Ruler.HORIZONTAL_MARK_HEIGHT)
    layoutableGroup.add(titleText)

    // draw horizontal ruler
    const horizontalUpperRuler = this.drawHorizontalRuler(svg)
    const horizontalUpperRulerMarker = this.drawHorizontalUpperRulerMarker(svg)
    horizontalUpperRuler.add(horizontalUpperRulerMarker)
    horizontalUpperRulerMarker.back()
    layoutableGroup.add(horizontalUpperRuler)

    // draw vertical ruler
    const verticalRightRuler = this.drawVerticalRightRuler(svg)
    const verticalRightRulerMarker = this.drawVerticalRightRulerMarker(svg)
    verticalRightRuler.add(verticalRightRulerMarker)
    verticalRightRulerMarker.back()
    verticalRightRuler.x(this.layoutableWidth())
    layoutableGroup.add(verticalRightRuler)

    // mouse events
    svg.mousemove((e) => {
      const point = layoutableBack.point(e.clientX, e.clientY)
      const bbox = layoutableBack.bbox()
      const backX = (point.x - bbox.x) / bbox.w
      const backY = (point.y - bbox.y) / bbox.h

      if ((backX < 0 || backX > 1) || (backY < 0 || backY > 1)) {
        this.onRowColIndicatorUpdate(-1, -1)
        colRowIndicator.hide()
        return
      }

      colRowIndicator.show()
      const heightWithSpace = (this.colHeight() + this.columnSpace) * this.numberOfColumns
      const backYWithSpace = (point.y - bbox.y) / heightWithSpace

      const row = Math.ceil(this.numberOfRows * (1.0 - backX))
      const col = Math.ceil(this.numberOfColumns * backYWithSpace)
      this.onRowColIndicatorUpdate(row, col)

      const indicatorPoint: svgjs.Point = svg.point(e.clientX, e.clientY)
      colRowIndicator.move(indicatorPoint.x as number + 10, indicatorPoint.y as number + 10)
      const indicatorText: svgjs.Text = colRowIndicator.find('.indicator-text')[0] as svgjs.Text
      indicatorText.text(`${col}段, ${row}行`)

      const horizontalRulerMarkerPoint = horizontalUpperRuler.point(e.clientX, e.clientY)
      horizontalUpperRulerMarker.x(horizontalRulerMarkerPoint.x - this.rowsWidth(2) / 2)

      const verticalRulerMarkerPoint = verticalRightRuler.point(e.clientX, e.clientY)
      verticalRightRulerMarker.y(verticalRulerMarkerPoint.y - this.rowsWidth(2) / 2)
    })

    // append to parent
    svg.add(layoutableGroup)
    if (fit) {
      fitTo(svg, layoutableGroup)
    }

    // make indicator front
    colRowIndicator.front()

    layoutableGroup.center(
      Number(svg.width()).valueOf() / 2,
      Number(svg.height()).valueOf() / 2)
  }

  addArticle (article: Article): Layoutable {
    this.articles.push(article)
    return this
  }

  addDividerLine (areaObj: AreaInfo, index: number, segmentEventHandlers: SegmentEventHandlers): Layoutable {
    this.dividerLines.push(
      new DividerLine(areaObj, 'dividerLine', index, segmentEventHandlers))
    return this
  }
}

class Segment {
  area: AreaInfo

  displayText: string
  color: string

  category: string
  index: number

  onClickSegment: (e: any) => void
  onMouseOverSegment: (e: any) => void
  onMouseOutSegment: (e: any) => void

  constructor (
    category: string, area: AreaInfo, displayText: string, color: string, index: number,
    segmentEventHandlers: SegmentEventHandlers) {
    this.area = area

    this.displayText = displayText
    this.color = color

    this.category = category
    this.index = index

    this.onClickSegment = segmentEventHandlers.onClickSegment
    this.onMouseOverSegment = segmentEventHandlers.onMouseOverSegment
    this.onMouseOutSegment = segmentEventHandlers.onMouseOutSegment
  }

  createSelector (article: Article): string {
    return `.${article.id}.${this.category}.index${this.index}`
  }

  createEffectRect (article: Article, baseRect: svgjs.Rect, selector: string): svgjs.Rect {
    const effectRect = baseRect.clone().attr(NormalAttributes)
    effectRect.addClass(article.id)
    effectRect.addClass(`${this.category}`)
    effectRect.addClass(`index${this.index}`)
    effectRect.addClass(EFFECT_ELEMENT_CLASS_NAME)
    effectRect.attr({ selector })

    effectRect.click(this.onClickSegment)
    effectRect.mouseover(this.onMouseOverSegment)
    effectRect.mouseout(this.onMouseOutSegment)
    return effectRect
  }

  capacityAreaSize (layoutable: Layoutable): SizeInfo {
    return { w: layoutable.rowsWidth(2.5), h: layoutable.rowsWidth(2.5) }
  }

  attachCapacityIndicator (
    layoutable: Layoutable, article: Article, svg: svgjs.Svg,
    target: svgjs.G, rowsStart: number, rowsUsed: number): svgjs.G {
    const rectBox: { x: number, y: number, w: number, h: number } = (target.findOne('rect.base-rect') as svgjs.Rect).bbox()
    const { w: areaWidth, h: areaHeight } = this.capacityAreaSize(layoutable)

    const capacityRect = svg.rect(areaWidth, areaHeight).attr({
      fill: '#fff',
      stroke: '#000',
      'stroke-width': 0.15
    })
    capacityRect.move(rectBox.x, rectBox.y + rectBox.h - areaHeight)
    target.add(capacityRect)

    const [labelWidth, labelHeight] = [areaWidth * 0.8, areaHeight * 0.8]
    const labelOffestX = (areaWidth - labelWidth) / 2
    const labelOffestY = (areaHeight - labelHeight) / 2

    const segmentsCapacity = rowsStart + rowsUsed - 1
    const overCapa = article.detail.bodyNumberOfRows - segmentsCapacity
    const overCapaLabel = (overCapa > 0 ? '+' : '') + overCapa.toString()

    const text = svg.text(overCapaLabel)
      .font({
        ...BaseFont,
        size: labelHeight
      })
      .fill('#000')
      .attr({
        textLength: labelWidth,
        lengthAdjust: 'spacingAndGlyphs'
      })
    text.x(rectBox.x + labelOffestX)
    text.y(rectBox.y + rectBox.h - areaHeight + labelOffestY)
    target.add(text)

    return target
  }

  draw (
    layoutable: Layoutable, article: Article, svg: svgjs.Svg,
    rowsStart: number, isLastSegment: boolean = false): SegmentDrawResult {
    const segmentGroup = svg.group()
    const rect = layoutable.createSegmentRect(this.area, svg)

    rect.addClass('base-rect')
    rect.fill(
      { color: this.color, opacity: article.opacity })

    segmentGroup.add(rect)

    const rectAspectRatio = rect.bbox().width / rect.bbox().height
    const isVertical = rectAspectRatio < VERTICAL_TEXT_ASPECT_RATIO
    const textBaseSize = (isVertical ? rect.bbox().h : rect.bbox().w) - 10

    const text = svg.text(add => {})
    if (isVertical) {
      text.attr({ 'writing-mode': 'vertical-rl' })
    }

    text.build(true)
    const initialFontSize = 10
    const span = text.tspan(this.displayText).font({
      ...BaseFont,
      size: `${initialFontSize}px`
    })
    const spanSize = isVertical ? span.bbox().h : span.bbox().w
    const spanFontSize = Math.min(initialFontSize * textBaseSize / spanSize, CHARACTER_SIZE_MAX)
    span.font({ size: `${spanFontSize}px` })
    text.build(false)
    text.center(rect.cx(), rect.cy())

    segmentGroup.add(text)

    const selector = this.createSelector(article)
    const effectRect = this.createEffectRect(article, rect, selector)
    segmentGroup.add(effectRect)
    effectRect.front()

    return { group: segmentGroup, rowsUsed: 0 }
  }

  drawBase (
    layoutable: Layoutable, article: Article, svg: svgjs.Svg,
    rowsStart: number, isLastSegment: boolean = false): SegmentDrawResult {
    const { group, rowsUsed } = this.draw(layoutable, article, svg, rowsStart, isLastSegment)

    if (article.detail.layoutLocked === true) {
      const lockPath = svg.path(FontAwesomeLockPath)
      const bbox = lockPath.bbox()
      const drawWidth = layoutable.rowsWidth(2)
      const width = drawWidth * 0.75
      const margin = drawWidth * 0.25
      const height = bbox.h * width / bbox.w
      lockPath
        .stroke({ width: 0.3, color: '#666' })
        .fill({ color: '#666' })
        .size(width, height)
        .move(Number(group.x()) + Number(group.width()) - drawWidth, Number(group.y()) + margin)
      group.add(lockPath)
    }

    return { group, rowsUsed }
  }
}

class DividerLine extends Segment {
  id: string

  constructor (area: AreaInfo, id: string, index: number, segmentEventHandlers: SegmentEventHandlers) {
    super('dividerLine', area, id, DIVIDER_LINE_SEGMENT_COLOR, index, segmentEventHandlers)
    this.id = id
  }

  createEffectRect (article: Article | undefined, baseRect: svgjs.Rect): svgjs.Rect {
    const effectRect = baseRect.clone().attr(NormalAttributes)
    effectRect.addClass(this.id)
    effectRect.addClass(`${this.category}`)
    effectRect.addClass(`index${this.index}`)
    effectRect.addClass(EFFECT_ELEMENT_CLASS_NAME)
    return effectRect
  }

  /**
   * @param {Layoutable} layoutable object.
   * @param {Article} article object. In DividerLine, always undefined.
   * @param {svgjs.Svg} svg object.
   * @param {number} rowsStart, for flow and preamble. In DividerLine, always 0.
   * @param {boolean} isLastSegment, for flow. In DividerLine, always false.
   */
  draw (
    layoutable: Layoutable, article: Article | undefined, svg: svgjs.Svg,
    rowsStart: number, isLastSegment: boolean = false): SegmentDrawResult {
    const segmentGroup = svg.group()

    const rect = layoutable.createSegmentRect(this.area, svg)
    rect.addClass('base-rect')

    rect.fill(
      { color: this.color, opacity: DIVIDER_LINE_OPACITY })
    segmentGroup.add(rect)

    const rectBox: { x: number, y: number, w: number, h: number } = rect.bbox()
    const lineOffsetUnit = rectBox.w / (this.area.left - this.area.right + 1)
    const lineX = rectBox.x + rectBox.w / 2
    const lineY1 = rectBox.y + lineOffsetUnit * 2
    const lineY2 = rectBox.y + rectBox.h - lineOffsetUnit * 2

    const line = svg.line(lineX, lineY1, lineX, lineY2).stroke({ width: 1, color: DIVIDER_LINE_COLOR })
    segmentGroup.add(line)

    const effectRect = this.createEffectRect(article, rect)
    segmentGroup.add(effectRect)
    effectRect.front()

    return { group: segmentGroup, rowsUsed: 0 }
  }
}

class HeadingSegment extends Segment {
  headings: any
  index: number

  readonly pads = {
    top: 2.5,
    left: 2.5,
    bottom: 2.5,
    right: 2.5
  }

  readonly fontWeights = {
    primary: 36,
    secondary: 16,
    pillar: 12,
    designed: 12,
    preamble: 12
  }

  ignoreHeadingTypes: string[] = [] // headingType strings to avoid drawing

  readonly coveringAttrNames: string[] = ['top_covering', 'bottom_covering']

  constructor (
    area: AreaInfo, id: string, color: string, index: number, headings: any,
    segmentEventHandlers: SegmentEventHandlers, ignoreHeadingTypes: string[]) {
    super('heading', area, id, color, index, segmentEventHandlers)
    this.headings = headings
    this.ignoreHeadingTypes = ignoreHeadingTypes
  }

  texts_for_vertical_layout (
    headings: any, rect: svgjs.Rect, layoutable: Layoutable, svg: svgjs.Svg, orientation: 'vertical' | 'horizontal'): svgjs.G {
    const isVertical = orientation === 'vertical'

    const layoutableUnitRowSize = layoutable.minimumHeadingSize()
    const rectBBox = rect.bbox()
    const paddableWidth = rectBBox.w - layoutableUnitRowSize.w
    const settingPadsWidth = this.pads.left + this.pads.right
    const { padsLeft, padsRight } = paddableWidth > settingPadsWidth
      ? { padsLeft: this.pads.left, padsRight: this.pads.right }
      : {
          padsLeft: Math.min(this.pads.left, paddableWidth / 2),
          padsRight: Math.min(this.pads.right, paddableWidth / 2)
        }
    const paddableHeight = rectBBox.h - layoutableUnitRowSize.h
    const settingPadsHeight = this.pads.top + this.pads.bottom
    const { padsTop, padsBottom } = paddableHeight > settingPadsHeight
      ? { padsTop: this.pads.top, padsBottom: this.pads.bottom }
      : {
          padsTop: Math.min(this.pads.top, paddableHeight / 2),
          padsBottom: Math.min(this.pads.top, paddableHeight / 2)
        }

    let boxW: number = rectBBox.w - (padsLeft + padsRight)
    let boxH: number = rectBBox.h - (padsTop + padsBottom)
    let boxX: number = Number(rectBBox.x) + padsLeft
    let boxY: number = Number(rectBBox.y) + padsTop

    const [headingAttributes, coveringAttributes] = orientation === 'vertical'
      ? [{ 'writing-mode': 'vertical-rl' }, { 'writing-mode': 'horizontal-tb' }]
      : [{ 'writing-mode': 'horizontal-tb' }, { 'writing-mode': 'vertical-lr' }]

    const g = svg.group()

    const coverings = headings.filter(
      h => this.coveringAttrNames.includes(h.headingType))
    const notCoverings = headings.filter(h => {
      const check: boolean = [
        ...this.coveringAttrNames,
        ...this.ignoreHeadingTypes].includes(h.headingType)
      return !check
    })

    const maxCoverLength = Math.max(
      ...coverings.map(h => h?.content?.length).filter(l => Number.isFinite(l)))
    const coverSize = Number.isFinite(maxCoverLength)
      ? isVertical
        ? [boxW, Math.min(boxW / maxCoverLength, boxH * 0.1)]
        : [Math.min(boxH / maxCoverLength, boxW * 0.1), boxH]
      : [0, 0]

    coverings.forEach(h => {
      const text = svg.text(add => {}).attr(coveringAttributes)
      text.build(true)
      text.tspan(h.content).font({
        ...BaseFont,
        size: coverSize[isVertical ? 1 : 0]
      })
      text.build(false)

      const isTop = h.headingType === 'top_covering'
      const cx = isVertical
        ? boxX + boxW / 2
        : boxX + boxW / 2 + (boxW - coverSize[0]) / 2 * (isTop ? -1 : 1)
      const cy = isVertical
        ? boxY + boxH / 2 + (boxH - coverSize[1]) / 2 * (isTop ? -1 : 1)
        : boxY + boxH / 2

      text.center(cx, cy)

      boxY += coverSize[1] * Number(isVertical && isTop)
      boxH -= coverSize[1] * Number(isVertical)
      boxX += coverSize[0] * Number(!isVertical && isTop)
      boxW -= coverSize[0] * Number(!isVertical)

      g.add(text)
    })

    const totalWeights = notCoverings
      .map(h => this.fontWeights[h.headingType])
      .reduce((p: number, c: number) => p + c, 0)
    const unitWeights = (isVertical ? boxW : boxH) / totalWeights

    const realSize = notCoverings.map(h => {
      const lineSize = unitWeights * this.fontWeights[h.headingType]
      const fontSize = isVertical
        ? Math.min(boxH / h.content.length, lineSize)
        : Math.min(boxW / h.content.length, lineSize)
      return fontSize
    }).reduce((p: number, c: number) => p + c, 0)

    boxX += Number(isVertical) * (boxW - realSize) / 2
    boxY += Number(!isVertical) * (boxH - realSize) / 2
    boxW = isVertical ? realSize : boxW
    boxH = !isVertical ? realSize : boxH

    notCoverings.forEach(h => {
      const text = svg.text(add => {}).attr(headingAttributes)
      const lineSize = unitWeights * this.fontWeights[h.headingType]
      const fontSize = isVertical
        ? Math.min(boxH / h.content.length, lineSize)
        : Math.min(boxW / h.content.length, lineSize)

      text.build(true)
      text.tspan(h.content).font({
        ...BaseFont,
        size: fontSize
      })
      text.build(false)

      if (isVertical) {
        text.x(boxX + boxW - text.bbox().w)
        text.y(boxY + (boxH - text.bbox().h) * Number(h.headingType === 'secondary'))
        boxW -= fontSize
      } else {
        text.x(boxX + (boxW - text.bbox().w) / 2)
        text.y(boxY)
        boxY += fontSize
      }

      g.add(text)
    })

    return g
  }

  draw (
    layoutable: Layoutable, article: Article, svg: svgjs.Svg,
    rowsStart: number, isLastSegment: boolean = false): SegmentDrawResult {
    const segmentGroup = svg.group()
    const rect = layoutable.createSegmentRect(this.area, svg)
    rect.addClass('base-rect')

    rect.fill(
      { color: this.color, opacity: article.opacity })
    rect.addClass(article.id)
    segmentGroup.add(rect)

    const linedHeadings = this.headings.map(
      h => h.content.split('\n').map(s => { return { ...h, content: s } })).flat()

    const orientation = rect.bbox().h > rect.bbox().w ? 'vertical' : 'horizontal'
    const text = this.texts_for_vertical_layout(linedHeadings, rect, layoutable, svg, orientation)

    segmentGroup.add(text)

    const selector = this.createSelector(article)
    const effectRect = this.createEffectRect(article, rect, selector)
    segmentGroup.add(effectRect)
    effectRect.front()

    return { group: segmentGroup, rowsUsed: 0 }
  }
}

class FigureSegment extends Segment {
  detail: any

  constructor (area: AreaInfo, detail: FigureDetailInfo, color: string, index: number, segmentEventHandlers: SegmentEventHandlers) {
    super('figure', area, `図表 ${detail.filename}`, color, index, segmentEventHandlers)
    this.detail = detail
  }
}

class AreaSegment extends Segment {
  id: string

  constructor (area: AreaInfo, id: string, color: string, index: number, segmentEventHandlers: SegmentEventHandlers) {
    super('area', area, `領域 ${id}`, color, index, segmentEventHandlers)
    this.id = id
  }

  createSelector (article: Article): string {
    return `.${article.id}.${this.category}`
  }
}

class TemplateAreaSegment extends Segment {
  id: string

  constructor (area: AreaInfo, id: string, color: string, index: number, segmentEventHandlers: SegmentEventHandlers) {
    super('template', area, `在版 ${id}`, color, index, segmentEventHandlers)
    this.id = id
  }

  createSelector (article: Article): string {
    return `.${article.id}.${this.category}`
  }
}

class FlowSegment extends Segment {
  isPreamble: boolean

  constructor (
    area: AreaInfo, id: string, color: string, index: number, isPreamble: boolean,
    segmentEventHandlers: SegmentEventHandlers) {
    const category = isPreamble ? 'preamble' : 'flow'
    super(category, area, id, color, index, segmentEventHandlers)
    this.isPreamble = isPreamble
  }

  createSelector (article: Article): string {
    return `.${article.id}.${this.category}`
  }

  draw (
    layoutable: Layoutable, article: Article, svg: svgjs.Svg,
    rowsStart: number, isLastSegment: boolean): SegmentDrawResult {
    const {
      rowsStartReal,
      rows,
      rowsUsed,
      rectFillStyle,
      rowIndicatorFillStyle,
      rowLineRectHatching
    } = this.isPreamble
      ? {
          rowsStartReal: 1,
          rows: article.detail.preambleNumberOfRows,
          rowsUsed: 0,
          rectFillStyle: { color: this.color, opacity: article.opacity },
          rowIndicatorFillStyle: { color: '#000' },
          rowLineRectHatching: svg.findOne(`#${DEFAULT_HATCHING_ID}`)
        }
      : {
          rowsStartReal: rowsStart,
          rows: this.area.left - this.area.right + 1,
          rowsUsed: this.area.left - this.area.right + 1,
          rectFillStyle: { color: this.color, opacity: 0 },
          rowIndicatorFillStyle: { color: this.color },
          rowLineRectHatching: svg.findOne(`#${article.id}-hatching`)
        }

    const segmentGroup = svg.group()

    const rect = layoutable.createSegmentRect(this.area, svg)
    rect.addClass('base-rect')
    rect.fill(rectFillStyle)
    rect.addClass(article.id)
    segmentGroup.add(rect)

    const rectBox: { x: number, y: number, w: number, h: number } = rect.bbox()
    const rowsAsFlow = this.area.left - this.area.right + 1

    const rowNumAreaSize = rectBox.w / rowsAsFlow
    const rowsXOffset = (rectBox.w - rowNumAreaSize * rows) / 2
    const rowNumRectSize = rowNumAreaSize * 0.85
    const rowNumTextSize = rowNumAreaSize * 0.75

    const rowNumRectOffset = (rowNumAreaSize - rowNumRectSize) / 2
    const rowNumTextOffset = (rowNumAreaSize - rowNumTextSize) / 2

    const rowNumRectY = rectBox.y + rowNumRectOffset
    const rowLineW = rowNumAreaSize - rowNumRectOffset * 2
    const rowLineH = rectBox.h - rowNumAreaSize - rowNumRectOffset * 2

    for (let row = 0; row < rows; row++) {
      const rowNum = row + rowsStartReal
      const text = svg.text(rowNum.toString().padStart(2, '0'))
        .font({
          ...BaseFont,
          size: rowNumTextSize
        })
        .fill(rowIndicatorFillStyle)
      text.attr({
        textLength: rowNumTextSize,
        lengthAdjust: 'spacingAndGlyphs'
      })

      const rowNumBaseX = rectBox.x + rectBox.w - rowNumAreaSize * (row + 1) - rowsXOffset

      text.x(rowNumBaseX + rowNumTextOffset)
      text.y(rectBox.y + rowNumTextOffset)
      segmentGroup.add(text)

      const rowNumRect = svg.rect(rowNumRectSize, rowNumRectSize).attr({
        fill: 'none',
        stroke: rowIndicatorFillStyle.color,
        'stroke-width': 0.15
      })
      const rowNumRectX = rowNumBaseX + rowNumRectOffset
      rowNumRect.move(rowNumRectX, rowNumRectY)
      segmentGroup.add(rowNumRect)

      const rowLineRect = svg.rect(rowLineW, rowLineH).attr({
        fill: rowLineRectHatching,
        stroke: rowIndicatorFillStyle.color,
        'stroke-width': 0.15
      })
      rowLineRect.move(rowNumRectX, rowNumRectY + rowNumAreaSize)

      segmentGroup.add(rowLineRect)
    }

    const selector = this.createSelector(article)
    const effectRect = this.createEffectRect(article, rect, selector)
    segmentGroup.add(effectRect)
    effectRect.front()

    return { group: segmentGroup, rowsUsed }
  }
}

class Article {
  id: string
  detailId: string
  detail: any
  headingSegments: Segment[] = []
  figureSegments: Segment[] = []
  areaSegments: Segment[] = []
  flowSegments: Segment[] = []

  col: number
  row: number

  color: string
  opacity: number = ARTICLE_OPACITY

  articleNumber: number
  articleColor: ArticleColor

  static articles_count: number = 0

  constructor (articleObj: ArticleInfo, detailObj: ArticleDetailInfo) {
    this.id = articleObj.id
    this.detailId = `${detailObj.id}`
    this.detail = detailObj
    this.color = 'blue'
    this.articleNumber = Article.articles_count
    this.articleColor = this.detail.layoutLocked !== true
      ? ArticleColors[this.colorNumber()]
      : LockedArticleColor
    Article.articles_count++
  }

  colorNumber (): number {
    return this.articleNumber % ArticleColors.length
  }

  addHeadingArea (
    area: AreaInfo, headings: any, index: number,
    segmentEventHandlers: SegmentEventHandlers, ignoreTypes: string[] = []): Article {
    this.headingSegments.push(
      new HeadingSegment(
        area,
        this.id,
        this.articleColor.heading,
        index,
        headings,
        segmentEventHandlers,
        ignoreTypes))
    return this
  }

  addFigure (figureLayout: FigureInfo, figureDetail: any, index: number, segmentEventHandlers: SegmentEventHandlers): Article {
    this.figureSegments.push(
      new FigureSegment(
        figureLayout.area,
        figureDetail,
        this.articleColor.figure,
        index,
        segmentEventHandlers))
    return this
  }

  addAreaSegment (areaObj: AreaInfo, index: number, segmentEventHandlers: SegmentEventHandlers): Article {
    this.areaSegments.push(
      new AreaSegment(
        areaObj,
        [this.detail.filename, this.detail.name, this.detailId].find(truthy),
        this.articleColor.writing,
        index,
        segmentEventHandlers))
    return this
  }

  addTemplateArticleSegment (areaObj: AreaInfo, index: number, segmentEventHandlers: SegmentEventHandlers): Article {
    this.areaSegments.push(
      new TemplateAreaSegment(
        areaObj,
        [this.detail.filename, this.detail.name, this.detailId].find(truthy),
        this.articleColor.writing,
        index,
        segmentEventHandlers))
    return this
  }

  addFlowSegment (areaObj: AreaInfo, index: number, isPreamble: boolean, segmentEventHandlers: SegmentEventHandlers): Article {
    this.flowSegments.push(
      new FlowSegment(
        areaObj,
        this.id,
        this.articleColor.writing,
        index,
        isPreamble,
        segmentEventHandlers))
    return this
  }

  draw (layoutable: Layoutable, svg: svgjs.Svg): svgjs.G {
    const articleGroup = svg.group()

    const articleHatching = createHatchingPattern(
      svg, this.articleColor.writing, HATCHING_SCALE, HATCHING_RATIO)
    articleHatching.id(`${this.id}-hatching`)

    let rowsStart = 1

    this.headingSegments.forEach((segment) => {
      const isLastSegment = segment === this.headingSegments.at(-1)
      const segmentDrawing = segment.drawBase(layoutable, this, svg, rowsStart)
      if (isLastSegment && this.flowSegments.length === 0) {
        segmentDrawing.group = segment.attachCapacityIndicator(
          layoutable, this, svg, segmentDrawing.group, rowsStart, segmentDrawing.rowsUsed)
      }
      articleGroup.add(segmentDrawing.group)
      rowsStart += segmentDrawing.rowsUsed
    })

    this.figureSegments.forEach((segment) => {
      const segmentDrawing = segment.drawBase(layoutable, this, svg, rowsStart)
      articleGroup.add(segmentDrawing.group)
      rowsStart += segmentDrawing.rowsUsed
    })

    this.areaSegments.forEach((segment) => {
      const segmentDrawing = segment.drawBase(layoutable, this, svg, rowsStart)
      articleGroup.add(segmentDrawing.group)
      rowsStart += segmentDrawing.rowsUsed
    })

    this.flowSegments.forEach((segment) => {
      const isLastSegment = segment === this.flowSegments.at(-1)
      const segmentDrawing = segment.drawBase(layoutable, this, svg, rowsStart, isLastSegment)
      if (isLastSegment) {
        segmentDrawing.group = segment.attachCapacityIndicator(
          layoutable, this, svg, segmentDrawing.group, rowsStart, segmentDrawing.rowsUsed)
      }
      articleGroup.add(segmentDrawing.group)
      rowsStart += segmentDrawing.rowsUsed
    })

    return articleGroup
  }
}

/*
  draw function
 */
function drawLayoutable (svg: svgjs.Svg, layoutable: Layoutable): void {
  layoutable.draw(svg, true)
}

/*
  export functions
 */
export function drawPreview (
  layoutObj: any, articles: ArticleDetailInfo[], layoutableObj: any,
  svgSelector: string, segmentEventHandlers: SegmentEventHandlers,
  onRowColIndicatorUpdate: (row: number, col: number) => void): void {
  const container = document.getElementById(svgSelector)

  if (container == null) {
    return
  }

  if (container.firstChild != null) {
    return
  }

  const svg = svgjs.SVG().addTo('#' + svgSelector)
  svg.size(SVG_WIDTH, SVG_HEIGHT)
  svg.viewbox(0, 0, SVG_WIDTH, SVG_HEIGHT)
  svg.css({ backgroundColor: 'white' })
  svg.attr({ 'xmlns:encoding': 'utf-8' })
  svg.css({ width: container.clientWidth.toString() + 'px' })
  svg.css({ height: container.clientHeight.toString() + 'px' })

  const layoutableData: LayoutableInfo = layoutableObj
  const layoutData: LayoutInfo = layoutObjToLayoutInfo(layoutObj)

  const layoutable = new Layoutable(layoutableData, layoutData, onRowColIndicatorUpdate)
  Article.articles_count = 0

  const articleLayoutDetailPairs = layoutData.articles.map((aL) => {
    const articleFound = articles.find(aD => `article-${aD.id.toString()}` === aL.id)
    return [aL, articleFound]
  }).filter(pair => pair[1] !== undefined)

  articleLayoutDetailPairs.forEach(function (pair) {
    const [articleLayout, articleDetail] = pair

    const article = new Article(articleLayout, articleDetail)

    /*
     * 従来の articleLayout.headingArea schemaでも無理矢理描画できるようにする措置
     */
    const [headingIds, headingAreas, ignoreTypes] = isAreaInfo(articleLayout.headingArea)
      ? [[undefined], [articleLayout.headingArea], ['preamble', 'designed']]
      : articleLayout.headingAreas !== undefined
        ? [articleLayout.headingAreas.map(h => h.id), articleLayout.headingAreas, []]
        : [[], [], []]

    headingAreas.forEach(function (headingArea, idx) {
      const headings = articleDetail.headings.filter(h => {
        return 'id' in headingArea
          ? `heading-${h.id as string}` === headingArea.id
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          : !headingIds.includes(`heading-${h.id as string}`)
      })
      article.addHeadingArea(
        headingArea, headings, idx, segmentEventHandlers, ignoreTypes)
    })

    articleLayout.figures.forEach(function (figureLayout, idx) {
      const figureDetail = articleDetail.figures[idx]
      article.addFigure(figureLayout, figureDetail, idx, segmentEventHandlers)
    })

    articleLayout.areas.forEach(function (areaData, idx) {
      const isPreamble = articleDetail.preambleContent !== null && idx === 0

      switch (articleDetail.articleType) {
        case 'fold':
        case 'anchor':
        case 'flow':
          article.addFlowSegment(areaData, idx, isPreamble, segmentEventHandlers)
          break
        case 'area':
          article.addAreaSegment(areaData, idx, segmentEventHandlers)
          break
        case 'template':
          article.addTemplateArticleSegment(areaData, idx, segmentEventHandlers)
          break
        default:
          break
      }
    })

    layoutable.addArticle(article)
  })

  layoutData.dividerLines?.forEach(function (dividerLine, idx) {
    layoutable.addDividerLine(dividerLine, idx, segmentEventHandlers)
  })

  drawLayoutable(svg, layoutable)
}

export function clearPreview (svgSelector: string): void {
  const container = document.getElementById(svgSelector)
  if (container != null) {
    container.replaceChildren()
  }
}

function setAttributesForElements (
  svgSelector: string, elementsSelector: string, attributes: EffectAttributes): void {
  const container = document.getElementById(svgSelector)
  if (container == null) {
    return
  }

  const svg = svgjs.SVG(container.children[0])
  svg.find(`${elementsSelector}.${EFFECT_ELEMENT_CLASS_NAME}`).forEach((elem) => {
    elem.attr(attributes)
  })
}

export function setHoverStateForElements (svgSelector: string, elementsSelector: string): void {
  setAttributesForElements(svgSelector, elementsSelector, HoveredAttributes)
}

export function clearHoverStateFromAllElements (svgSelector: string): void {
  setAttributesForElements(svgSelector, '', UnHoveredAttributes)
}

export function setSelectStateForElements (svgSelector: string, elementsSelector: string): void {
  setAttributesForElements(svgSelector, elementsSelector, SelectedAttributes)
}

export function clearSelectStateFromAllElements (svgSelector: string): void {
  setAttributesForElements(svgSelector, '', UnSelectedAttributes)
}

export function setSvgScale (svgSelector: string, scaleFactor: number): void {
  const container = document.getElementById(svgSelector)
  if (container == null) {
    return
  }

  const svg = svgjs.SVG(container.children[0])
  svg.css({ width: (container.clientWidth * scaleFactor).toString() })
  svg.css({ height: (container.clientHeight * scaleFactor).toString() })

  container.scrollTop = 0
  container.scrollLeft = container.clientWidth * (scaleFactor - 1.0) / 2
}

export function getSvgObject (svgSelector: string): svgjs.Svg | undefined {
  const container = document.getElementById(svgSelector)
  if (container === null) {
    return undefined
  }

  return svgjs.SVG(container.children[0]) as svgjs.Svg
}
