/* eslint-env browser */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  Stack
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ErrorModal
} from '../common'
import {
  shouldShowExperimentalFeatures,
  selectAuth
} from '../auth'
import {
  fetchLayoutResult as PageFetchLayoutResult,
  exportLayout as PageExportLayout
} from '../page/pageAPI'
import {
  fetchLayoutResult as SubLayoutFetchLayoutResult,
  exportLayout as SubLayoutExportLayout
} from '../subLayout/subLayoutAPI'
import {
  selectPageItem,
  dismissError as PageDismissError
} from '../page/pageSlice'
import {
  dismissError as SubLayoutDismissError,
  selectSubLayoutItem
} from '../subLayout/subLayoutSlice'
import {
  fetchArticles,
  getLayoutableBaseUrl
} from '../article/articleAPI'
import {
  selectArticle
} from '../article'
import moment from 'moment'

import {
  LayoutPreview
} from './LayoutPreview'
import {
  ObjectTree
} from './ObjectTree'
import {
  ObjectProperty
} from './ObjectProperty'
import {
  BASE_HEIGHT,
  HEADER_HEIGHT
} from './common'

const LayoutViewer = ({
  layoutable,
  layoutResult,
  ...props
}) => {
  // オブジェクトツリー、プレビュー描画、オブジェクトツリーの3点セット
  if (layoutable === undefined || layoutResult === undefined) {
    return
  }
  const [layoutIndex, setLayoutIndex] = useState(layoutResult?.layoutIndex || 0)

  const selectedLayoutResult = layoutResult?.layoutResults?.[layoutIndex]
  const layoutResultSize = layoutResult?.layoutResults?.length || 0

  const [selectedElementsSelector, setSelectedElementsSelector] = useState(undefined)
  const [hoveredElementsSelector, setHoveredElementsSelector] = useState(undefined)

  const handleChangeOfSelected = (e) => {
    setSelectedElementsSelector(e.target.getAttribute('selector'))
  }

  const handleChangeOfHovered = (e) => {
    setHoveredElementsSelector(e.target.getAttribute('selector'))
  }

  const handleClearOfHovered = (e) => {
    setHoveredElementsSelector(undefined)
  }

  const articles = layoutable.articles

  const articleLayoutPairs = articles?.map((article, i) => {
    return {
      article,
      layout: selectedLayoutResult?.articles.find(elem => `article-${article.id}` === elem.id)
    }
  }).filter(pair => pair.layout !== undefined)

  const layoutPreview = (
    <LayoutPreview
      articles={articles}
      layout={selectedLayoutResult}
      layoutIndex={layoutIndex}
      setLayoutIndex={setLayoutIndex}
      layoutResultSize={layoutResultSize}
      layoutable={layoutable}
      selectedElementsSelector={selectedElementsSelector}
      hoveredElementsSelector={hoveredElementsSelector}
      onClickOfSegments={handleChangeOfSelected}
      onMouseEnterOfSegments={handleChangeOfHovered}
      onMouseLeaveOfSegments={handleClearOfHovered}
      {...props}
    />
  )

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={2} style={{ overflowY: 'auto', maxHeight: `calc(${BASE_HEIGHT} - ${HEADER_HEIGHT})` }}>
            <ObjectTree
              articles={articleLayoutPairs}
              layout={selectedLayoutResult}
              selectedElementsSelector={selectedElementsSelector}
              hoveredElementsSelector={hoveredElementsSelector}
              onClickOfItems={handleChangeOfSelected}
              onMouseEnterOfItems={handleChangeOfHovered}
              onMouseLeaveOfItems={handleClearOfHovered}
            />
          </Col>
          <Col sm={8} style={{ overflowY: 'auto', maxHeight: `calc(${BASE_HEIGHT} - ${HEADER_HEIGHT})` }}>
            {layoutPreview}
          </Col>
          <Col sm={2} style={{ overflowY: 'auto', maxHeight: `calc(${BASE_HEIGHT} - ${HEADER_HEIGHT})` }}>
            <ObjectProperty
              articleLayoutPairs={articleLayoutPairs}
              contentSelector={selectedElementsSelector}
              layoutable={layoutable}
              {...props}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export const LayoutPreviewScreen = ({ layoutableType = 'Page' }) => {
  // プレビューページの全画面
  let layoutableId
  let fetchLayoutResult
  let selector
  let exportLayout
  switch (layoutableType) {
    case 'Page': {
      const { pageId: pageIdString } = useParams()
      layoutableId = parseInt(pageIdString)
      fetchLayoutResult = PageFetchLayoutResult
      selector = selectPageItem
      exportLayout = PageExportLayout
      break
    }
    case 'SubLayout': {
      const { subLayoutId: subLayoutIdString } = useParams()
      layoutableId = parseInt(subLayoutIdString)
      fetchLayoutResult = SubLayoutFetchLayoutResult
      selector = selectSubLayoutItem
      exportLayout = SubLayoutExportLayout
      break
    }
  }
  const { currentUser } = useSelector(selectAuth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    layoutResult,
    fetching: layoutResultFetching,
    errors
  } = useSelector(selector)

  const {
    layoutable: fetchedLayoutable,
    fetching: articlesFetching,
    data: articles,
    openedModal
  } = useSelector(selectArticle)
  const showExperimentalFeatures = shouldShowExperimentalFeatures()

  let dismissError
  const layoutable = { articles, ...fetchedLayoutable }
  switch (layoutableType) {
    case 'Page':
      dismissError = PageDismissError
      break
    case 'SubLayout':
      dismissError = SubLayoutDismissError
      break
  }
  const [fetching, setFetching] = useState(true)
  // const [reloadRequired, setReloadRequired] = useState(true)
  useEffect(() => {
    if (articlesFetching || layoutResultFetching) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }, [articlesFetching, layoutResultFetching])
  useEffect(() => {
    dispatch(fetchLayoutResult({ id: layoutableId }))
    dispatch(fetchArticles({ layoutableType, layoutableId }))
    // setReloadRequired(false)
  }, [])

  const exportClickHandler = () => {
    const issueDate = moment(layoutable.issueDate).format('YYYYMMDD')
    let fileName
    switch (layoutableType) {
      case 'Page': {
        const page = layoutable
        fileName = `${page.media.name}_${issueDate}_${page.pageNumber}_${page.draftNumber}.zip`
        break
      }
      case 'SubLayout': {
        const subLayout = layoutable
        fileName = `${subLayout.media.name}_${issueDate}_${subLayout.name}_${subLayout.draftNumber}.zip`
        break
      }
    }
    dispatch(exportLayout({ id: layoutableId, fileName }))
  }

  if (fetching || [layoutResult, articles, layoutable].some(e => e === null)) {
    return <></>
  }

  const layoutableBaseUrl = getLayoutableBaseUrl({ layoutableType, layoutableId })
  return (
    <>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <Stack direction='horizontal' gap={3}>
        <div className='ms-auto' />
        <ButtonGroup size='sm' className='m-1'>
          <Button
            className='list-button'
            variant='outline-primary'
            onClick={() => navigate(`/${layoutableBaseUrl}/articles`)}
          >
            <FontAwesomeIcon icon='list' fixedWidth />出稿表
          </Button>
          <Button
            className='export-button'
            variant='outline-primary'
            disabled={!layoutResult || !layoutable || !articles}
            onClick={exportClickHandler}
          >
            <FontAwesomeIcon icon='file-export' fixedWidth />エクスポート
          </Button>
        </ButtonGroup>
      </Stack>
      <LayoutViewer
        articles={articles}
        layoutResult={layoutResult}
        layoutable={layoutable}
        openedModal={openedModal}
        {...{ layoutableType, layoutableId, currentUser, showExperimentalFeatures }}
      />
    </>
  )
}
