import React from 'react'
import {
  Button,
  Modal,
  Accordion
} from 'react-bootstrap'

export const ErrorModal = ({
  errors,
  onClose
}) => {
  const errorKeys = Object.keys(errors)
  const errorKey = errorKeys.length > 0 ? errorKeys[0] : null
  const error = (errorKey && errors[errorKey])
  return (
    <Modal
      key='error'
      size='lg'
      centered
      show={errorKey}
    >
      <Modal.Header>
        <Modal.Title>エラー</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>エラーが発生しました。</p>
        <Accordion defaultActiveKey='0'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>詳細</Accordion.Header>
            <Accordion.Body>
              <p id='error_status'>ステータスコード: {error?.status}</p>
              <p id='error_status_text'>ステータステキスト: {error?.statusText}</p>
              {error?.message &&
                <p id='error_message'>エラーメッセージ: {error?.message}</p>}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => onClose(errorKey)}
        >閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
